import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ConditionNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:conditionNode');

    return (
        <Body>
            <Title>{t('hlp:conditionNode')}</Title>
            <Header id="conditionNode1">{t('hlp:helpPages.conditionNode.conditionNode1')}</Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode2">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    </Trans>
                </ListItem>
                <ListItem>{t('hlp:helpPages.conditionNode.conditionNode3')}</ListItem>
            </List>
            <Header id="conditionNode4">{t('hlp:helpPages.conditionNode.conditionNode4')}</Header>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.conditionNode.conditionNode27')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.conditionNode.conditionNode29')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode30">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <Header id="conditionNode5">{t('hlp:helpPages.conditionNode.conditionNode5')}</Header>
            <Header2 id="conditionNode6">{t('hlp:helpPages.conditionNode.conditionNode6')}</Header2>
            <Paragraph>{t('hlp:helpPages.conditionNode.conditionNode7')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode8">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header2 id="conditionNode9">{t('hlp:helpPages.conditionNode.conditionNode9')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode10">
                    0
                    <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Header2 id="conditionNode11">{t('hlp:helpPages.conditionNode.conditionNode11')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode12">
                    0<span className={css('bold')}>1</span>2
                    <Link to={routes[ROUTE.HOW_TO_USE_VARIABLES]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Header2 id="conditionNode13">{t('hlp:helpPages.conditionNode.conditionNode13')}</Header2>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.conditionNode.conditionNode15')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode16">
                        <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode17">
                        <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode18">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode19">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode20">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode21">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode22">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode23">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode24">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode25">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.conditionNode.conditionNode26">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
            </List>
        </Body>
    );
};

ConditionNode.propTypes = {};

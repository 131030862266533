import { useTranslation } from 'react-i18next';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video43 from './assets/video43.mov';
import video44 from './assets/video44.mov';
import video45 from './assets/video45.mov';
import video46 from './assets/video46.mov';
import video47 from './assets/video47.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SessionsTopics = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:sessionsTopics');

    return (
        <Body>
            <Title>{t('hlp:sessionsTopics')}</Title>
            <Header id="sessionsTopics1">{t('hlp:helpPages.sessionsTopics.sessionsTopics1')}</Header>
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics2')}</Paragraph>
            <PageVideo video={video43} />
            <Header id="sessionsTopics3">{t('hlp:helpPages.sessionsTopics.sessionsTopics3')}</Header>
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics4')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics5')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics8')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video44} />
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics9')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics10')}</Paragraph>
            <PageVideo video={video45} />
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics11')}</Paragraph>
            <Header id="sessionsTopics12">{t('hlp:helpPages.sessionsTopics.sessionsTopics12')}</Header>
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics13')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics14')}</Paragraph>
            <PageVideo video={video46} />
            <Paragraph>{t('hlp:helpPages.sessionsTopics.sessionsTopics15')}</Paragraph>
            <PageVideo video={video47} />
        </Body>
    );
};

SessionsTopics.propTypes = {};

const reportsMegabrainItems = [
    {
        id: 'reportsMegabrain1',
        title: 'helpPages.reportsMegabrain.reportsMegabrain1'
    },
    {
        id: 'reportsMegabrain3',
        title: 'helpPages.reportsMegabrain.reportsMegabrain3'
    }
];

export default reportsMegabrainItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TimeoutNode = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:timeoutNode');

    return (
        <Body>
            <Title>{t('hlp:timeoutNode')}</Title>
            <Header id="timeoutNode1">{t('hlp:helpPages.timeoutNode.timeoutNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.timeoutNode.timeoutNode2')}</ListItem>
                <ListItem>{t('hlp:helpPages.timeoutNode.timeoutNode3')}</ListItem>
                <ListItem>{t('hlp:helpPages.timeoutNode.timeoutNode4')}</ListItem>
            </List>
            <Header id="timeoutNode5">{t('hlp:helpPages.timeoutNode.timeoutNode5')}</Header>
            <PageVideo video={video1} />
            <Header id="timeoutNode6">{t('hlp:helpPages.timeoutNode.timeoutNode6')}</Header>
            <Header2 id="timeoutNode7">{t('hlp:helpPages.timeoutNode.timeoutNode7')}</Header2>
            <Paragraph>{t('hlp:helpPages.timeoutNode.timeoutNode8')}</Paragraph>
            <PageVideo video={video2} />
        </Body>
    );
};

TimeoutNode.propTypes = {};

const reportConfiguratorItems = [
    {
        id: 'reportConfigurator1',
        title: 'helpPages.reportConfigurator.reportConfigurator1'
    },
    {
        id: 'reportConfigurator3',
        title: 'helpPages.reportConfigurator.reportConfigurator3'
    },
    {
        id: 'reportConfigurator22',
        title: 'helpPages.reportConfigurator.reportConfigurator22'
    }
];

export default reportConfiguratorItems;

const distributionTagsItems = [
    {
        id: 'distributionTags1',
        title: 'helpPages.distributionTags.distributionTags1'
    },
    {
        id: 'distributionTags3',
        title: 'helpPages.distributionTags.distributionTags3'
    },
    {
        id: 'distributionTags11',
        title: 'helpPages.distributionTags.distributionTags11'
    },
    {
        id: 'distributionTags20',
        title: 'helpPages.distributionTags.distributionTags20'
    },
    {
        id: 'distributionTags25',
        title: 'helpPages.distributionTags.distributionTags25'
    },
];

export default distributionTagsItems;

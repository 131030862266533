import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import video31 from './assets/video31.mov';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ReportSchedule = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:reportSchedule');

    return (
        <Body>
            <Title>{t('hlp:reportSchedule')}</Title>
            <Header id="reportSchedule1">{t('hlp:helpPages.reportSchedule.reportSchedule1')}</Header>
            <Paragraph>{t('hlp:helpPages.reportSchedule.reportSchedule2')}</Paragraph>
            <PageVideo video={video29} />
            <Header id="reportSchedule3">{t('hlp:helpPages.reportSchedule.reportSchedule3')}</Header>
            <Paragraph>{t('hlp:helpPages.reportSchedule.reportSchedule4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule5')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule6')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule7')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule8')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule9')}</ListItem>
                <PageVideo video={video30} />
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule10')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportSchedule.reportSchedule11')}</ListItem>
                <PageVideo video={video31} />
            </List>
            <Header id="reportSchedule12">{t('hlp:helpPages.reportSchedule.reportSchedule12')}</Header>
            <Paragraph>{t('hlp:helpPages.reportSchedule.reportSchedule13')}</Paragraph>
            <PageVideo video={video32} />
            <Paragraph>{t('hlp:helpPages.reportSchedule.reportSchedule14')}</Paragraph>
            <PageVideo video={video33} />
        </Body>
    );
};

ReportSchedule.propTypes = {};

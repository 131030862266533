const howToMakeRequestToExternalSystemsUsingApiItems = [
    {
        id: 'howToMakeRequestToExternalSystemsUsingApi1',
        title:
            'helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi1'
    },
    {
        id: 'howToMakeRequestToExternalSystemsUsingApi3',
        title:
            'helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi3'
    }
];

export default howToMakeRequestToExternalSystemsUsingApiItems;

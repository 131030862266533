import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TagNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:tagNode');

    return (
        <Body>
            <Title>{t('hlp:tagNode')}</Title>
            <Header id="tagNode1">{t('hlp:helpPages.tagNode.tagNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.tagNode.tagNode2')}</ListItem>
                <ListItem>{t('hlp:helpPages.tagNode.tagNode3')}</ListItem>
            </List>
            <Header id="tagNode4">{t('hlp:helpPages.tagNode.tagNode4')}</Header>
            <PageVideo video={video1} />
            <Header id="tagNode5">{t('hlp:helpPages.tagNode.tagNode5')}</Header>
            <PageVideo video={video2} />
            <Header2 id="tagNode6">{t('hlp:helpPages.tagNode.tagNode6')}</Header2>
            <Paragraph>{t('hlp:helpPages.tagNode.tagNode7')}</Paragraph>
            <List>
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.tagNode.tagNode8">
                            0<span className={css('bold')}>1</span>2
                        </Trans>
                    </Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.tagNode.tagNode9">
                            0<span className={css('bold')}>1</span>2
                            <Link to={routes[ROUTE.DISTRIBUTION_TAGS]} className={css('link')}>
                                3
                            </Link>
                            4
                        </Trans>
                    </Paragraph>
                </ListItem>
            </List>
            <Header2 id="tagNode10">{t('hlp:helpPages.tagNode.tagNode10')}</Header2>
            <Paragraph>{t('hlp:helpPages.tagNode.tagNode11')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.tagNode.tagNode12">
                    0
                    <Link to={routes[ROUTE.METADATA_OVERVIEW]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.tagNode.tagNode13')}</Paragraph>
            <PageVideo video={video4} />
        </Body>
    );
};

TagNode.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToStoreUserPhoneNumberIntoUserProfile = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToStoreUserPhoneNumberIntoUserProfile');

    return (
        <Body>
            <Title>{t('hlp:howToStoreUserPhoneNumberIntoUserProfile')}</Title>
            <Header id="howToStoreUserPhoneNumberIntoUserProfile1">
                {t(
                    'hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile1'
                )}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile2'
                    )}
                </ListItem>
            </List>
            <Header id="howToStoreUserPhoneNumberIntoUserProfile3">
                {t(
                    'hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile3'
                )}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile5">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4<span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile6">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                    <Link to={routes[ROUTE.METADATA_OVERVIEW]} className={css('link')}>
                        5
                    </Link>
                    6<span className={css('bold')}>7</span>8<span className={css('bold')}>9</span>
                    10
                    <span className={css('bold')}>11</span>
                    12
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile7">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                    4<span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
        </Body>
    );
};

HowToStoreUserPhoneNumberIntoUserProfile.propTypes = {};

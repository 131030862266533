const serverUrlConfigAndWebhookEventsItems = [
    {
        id: 'serverUrlConfigAndWebhookEvents1',
        title: 'helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents1'
    },
    {
        id: 'serverUrlConfigAndWebhookEvents4',
        title: 'helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents4'
    }
];

export default serverUrlConfigAndWebhookEventsItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatDistribution = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatDistribution');

    return (
        <Body>
            <Title>{t('hlp:chatDistribution')}</Title>
            <Header id="chatDistribution1">{t('hlp:helpPages.chatDistribution.chatDistribution1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution2')}</Paragraph>
            <PageVideo video={video22} />
            <Header id="chatDistribution3">{t('hlp:helpPages.chatDistribution.chatDistribution3')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution4')}</Paragraph>
            <PageVideo video={video23} />
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution5')}</Paragraph>
            <PageVideo video={video24} />
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution6')}</Paragraph>
            <PageVideo video={video25} />
            <Header id="chatDistribution7">{t('hlp:helpPages.chatDistribution.chatDistribution7')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution8')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution9')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution10')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution11')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video26} />
            <Header id="chatDistribution12">{t('hlp:helpPages.chatDistribution.chatDistribution12')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution13')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution14')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution15')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video27} />
            <Header id="chatDistribution16">{t('hlp:helpPages.chatDistribution.chatDistribution16')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution17')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution18')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution19')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video28} />
            <Header id="chatDistribution20">{t('hlp:helpPages.chatDistribution.chatDistribution20')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution21')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution22')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution23')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution24')}</Paragraph>
            <Header id="chatDistribution25">{t('hlp:helpPages.chatDistribution.chatDistribution25')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution26')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution27')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution28')}</Paragraph>
            <PageVideo video={video29} />
            <Header id="chatDistribution29">{t('hlp:helpPages.chatDistribution.chatDistribution29')}</Header>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution30')}</Paragraph>
            <PageVideo video={video30} />
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution31')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution32')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatDistribution.chatDistribution33')}</Paragraph>
        </Body>
    );
};

ChatDistribution.propTypes = {};

const eventManagementItems = [
    {
        id: 'eventManagement1',
        title: 'helpPages.eventManagement.eventManagement1'
    },
    {
        id: 'eventManagement3',
        title: 'helpPages.eventManagement.eventManagement3'
    },
    {
        id: 'eventManagement12',
        title: 'helpPages.eventManagement.eventManagement12'
    }
];

export default eventManagementItems;

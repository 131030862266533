import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';

import usePageAccess from '../../../../hooks/usePageAccess';

export const Dashboard = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:dashboard');

    return (
        <Body>
            <Title>{t('hlp:dashboard')}</Title>
            <Header id="dashboard1">{t('hlp:helpPages.dashboard.dashboard1')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.dashboard.dashboard2">
                    0
                    <Link to={routes[ROUTE.ANALYTICS]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video13} />
            <Header id="dashboard3">{t('hlp:helpPages.dashboard.dashboard3')}</Header>
            <Paragraph>{t('hlp:helpPages.dashboard.dashboard4')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.dashboard.dashboard5">
                            <span className={css(['italic'])}>0</span>
                        </Trans>
                    </Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard6')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard7')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard8')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard9')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard10')}</Paragraph>
                </ListItem>
                <PageVideo video={video14} />
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.dashboard.dashboard11">
                            <span className={css(['italic'])}>0</span>
                        </Trans>
                    </Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard12')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard13')}</Paragraph>
                    <Paragraph>{t('hlp:helpPages.dashboard.dashboard14')}</Paragraph>
                </ListItem>
                <PageVideo video={video15} />
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.dashboard.dashboard15">
                            <span className={css(['italic'])}>0</span>1
                            <Link to={routes[ROUTE.TARGET_MONITORING]} className={css('link')}>
                                2
                            </Link>
                        </Trans>
                    </Paragraph>
                </ListItem>
                <PageVideo video={video16} />
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.dashboard.dashboard16">
                            <span className={css(['italic'])}>0</span>1
                        </Trans>
                    </Paragraph>
                </ListItem>
                <PageVideo video={video17} />
                <ListItem>
                    <Paragraph>
                        <Trans i18nKey="hlp:helpPages.dashboard.dashboard17">
                            <span className={css(['italic'])}>0</span>1
                        </Trans>
                    </Paragraph>
                </ListItem>
                <PageVideo video={video18} />
            </List>
            <Paragraph>{t('hlp:helpPages.dashboard.dashboard18')}</Paragraph>
            <List>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard19')}</ListItem>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard20')}</ListItem>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard21')}</ListItem>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard22')}</ListItem>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard23')}</ListItem>
                <ListItem>{t('hlp:helpPages.dashboard.dashboard24')}</ListItem>
            </List>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.dashboard.dashboard25">
                    0<Link to={routes[ROUTE.ANALYTICS]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
        </Body>
    );
};

Dashboard.propTypes = {};

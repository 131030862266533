export const ROUTE = {
    GET_STARTED: 'getStarted',
    MEGABRAIN: 'megabrainAi',
    INTRO: 'introToFlomni',
    CHATBOT_BUILDER: 'chatbotBuilder',
    WHAT_IS_FLOMNI: 'whatIsFlomni',
    TOPICS: 'topics',
    PAGES: 'pages',
    EDITOR: 'editor',
    LOGIN: 'login',
    NOT_FOUND: 'notFound',
    SEARCH: 'search',
    HELP: 'help',
    HELP_OVERVIEW: 'helpOverview',
    GETTING_STARTED_FOR_NEW_USER: 'gettingStartedForNewUsers',
    BUILD_YOUR_FIRST_CHATBOT: 'buildYourFirstChatbot',
    CHATBOT_BUILDER_OVERVIEW: 'chatbotBuilderOverview',
    CHATBOT_RELEASES: 'chatbotReleases',
    INSTALL_CHATBOT_ON_YOUR_WEBSITE: 'installChatbotOnYourWebsite',
    CHATBOT_NODE_ELEMENTS: 'chatbotNodeElements',
    FIRST_STEPS_WITH_NODES: 'firstStepsWithNodes',
    ACTION_NODES: 'actionNodes',
    CHAT_MANAGEMENT_NODE: 'chatManagementNode',
    JS_NODE: 'jsNode',
    WEBHOOK_NODE: 'webhookNode',
    MESSAGE_NODE: 'messageNode',
    TRANSFER_NODES: 'transferNodes',
    ENTER_POINT_NODE: 'enterPointNode',
    EXIT_POINT_NODE: 'exitPointNode',
    STORAGE_NODES: 'storageNodes',
    STORAGE_NODE: 'storageNode',
    TAG_NODE: 'tagNode',
    JSON_NODE: 'jsonNode',
    NOTE_NODE: 'noteNode',
    LOGIC_NODES: 'logicNodes',
    TEXT_ANALYSIS_NODE: 'textAnalysisNode',
    RECOGNITION_NODE: 'recognitionNode',
    RANDOMIZER_NODE: 'randomizerNode',
    TIMEOUT_NODE: 'timeoutNode',
    CONDITION_NODE: 'conditionNode',
    ACTION_CHAIN: 'actionChainNode',
    MEGABRAIN_OVERVIEW: 'megabrainAiOverview',
    TRAINING_SETS: 'trainingSets',
    MESSAGE_MARKUP: 'messageMarkup',
    ADDING_MESSAGES: 'addingMessages',
    MESSAGE_MARKUP_SUB: 'messageMarkupSub',
    INCOMING_MESSAGES_SET: 'incomingMessagesSet',
    TRAINING_SET_CREATION: 'trainingSetCreation',
    TRAINING_AND_PERFORMANCE: 'trainingAndPerformance',
    PERFORMANCE: 'performance',
    STORY_TRAINING: 'storyTraining',
    STORIES: 'stories',
    STOP_WORDS: 'stopWords',
    ENTITY_RECOGNITION: 'entityRecognition',
    CONTEXTS: 'contexts',
    TOPIC_RECOGNITION: 'topicRecognition',
    EMOTION_RECOGNITION: 'emotionRecognition',
    STORIES_SUB: 'storiesSub',
    DIALOGS: 'dialogs',
    ANALYTICS: 'analytics',
    REPORTS: 'reports',
    REPORT_VALUES_DETAILIZATION: 'reportValuesDetailization',
    ANALYTICS_CONFIGURATION: 'analyticsConfiguration',
    EVENT_MANAGEMENT: 'eventManagement',
    CHART_CONFIGURATION: 'chartConfiguration',
    REPORT_CONFIGURATOR: 'reportConfigurator',
    REPORT_SCHEDULE: 'reportSchedule',
    TARGET_MONITORING: 'targetMonitoring',
    RULE_CREATION: 'ruleCreation',
    INTEGRATIONS: 'integrations',
    CUSTOMER_SERVICE_ASSESSMENT: 'customerServiceAssessment',
    MY_REPORTS: 'myReports',
    TAG_CHANGES: 'tagChanges',
    REPORTS_MEGABRAIN: 'reportsMegabrain',
    SESSIONS_OVERVIEW: 'sessionsOverview',
    OTHER_REPORTS_OPTIONS: 'otherReportsOptions',
    OPERATOR_WORK: 'operatorWork',
    MESSAGE_TRANSPORT_REPORT: 'messageTransportReport',
    MESSAGE_TRANSPORT: 'messageTransport',
    ACCOUNT: 'account',
    TIPS: 'tipsTricksMore',
    DIALOGS_OPERATORS: 'dialogsOperators',
    DIALOGS_SUPERVISORS: 'dialogsSupervisors',
    DIALOGS_ADMINISTRATORS: 'dialogsAdministrators',
    DIALOGS_OVERVIEW: 'dialogsOverview',
    DIALOGS_STATISTICS: 'dialogsStatistics',
    GROUP_CONFIGURATION: 'groupConfiguration',
    CHAT_ARCHIVE: 'chatArchive',
    CHAT_VISIBILITY: 'chatVisibility',
    AGENT_GROUPS: 'agentGroups',
    CHANNEL_PRIORITIZATION: 'channelPrioritization',
    CHAT_DISTRIBUTION: 'chatDistribution',
    AGENT_STATUSES: 'agentStatuses',
    DISTRIBUTION_TAGS: 'distributionTags',
    CHATBOT_ACTIONS: 'chatbotActions',
    SESSIONS_TOPICS: 'sessionsTopics',
    DIALOGS_SEARCH: 'dialogsSearch',
    VARIOUS_FILTERS_SEARCH: 'variousFiltersSearch',
    WORD_OR_PHRASE_SEARCH: 'wordOrPhraseSearch',
    EVENTS: 'events',
    AUTO_ARCHIVED_CHATS: 'autoArchivedChats',
    REMINDERS: 'reminders',
    CHATS: 'chats',
    CHAT_ATTACHMENTS: 'chatAttachments',
    CHAT_ACTIONS: 'chatActions',
    CHAT_ADD_ACTION: 'chatAddAction',
    CHAT_ADD_BUTTON: 'chatAddButton',
    CHAT_ADD_TEMPLATE: 'chatAddTemplate',
    CHAT_ADD_EMOJI: 'chatAddEmoji',
    CHAT_NOTE: 'chatNote',
    CHAT_EDIT_CLIENT_INFO: 'chatEditClientInfo',
    CHAT_DIALOG_SORT_AND_FILTER: 'chatDialogSortAndFilter',
    CHAT_MESSAGE_EDIT_AND_DELETE: 'chatMessageEditAndDelete',
    CHAT_HINTS: 'chatHints',
    TEAM_ANALYTICS: 'teamAnalytics',
    GENERAL_STATISTICS: 'generalStatistics',
    ANALYTICS_OVERVIEW: 'analyticsOverview',
    ACCOUNT_MANAGEMENT: 'accountManagement',
    MAIN_DEFINITIONS: 'mainDefinitions',
    DEFINITIONS: 'definitions',
    PROFILE_DATA: 'profileData',
    NOTIFICATIONS: 'notifications',
    DIAGNOSTICS: 'diagnostics',
    MESSAGES_BUTTONS: 'messagesButtons',
    ACCOUNT_CONFIGURATION: 'accountConfiguration',
    MESSAGES_DRAFTS: 'messagesDrafts',
    OPERATORS_TEAM: 'operatorsTeam',
    MESSAGE_TRANSPORT_OVERVIEW: 'messageTransportOverview',
    ACCOUNT_OVERVIEW: 'accountOverview',
    DASHBOARD: 'dashboard',
    TIPS_OVERVIEW: 'tipsTricksMoreOverview',
    ADVANCED_FEATURES: 'advancedFeatures',
    CHATBOT_VARIABLES: 'chatbotVariables',
    USER_CASES: 'userCases',
    HOW_TO_USE_MULTI_LANGUAGES_IN_BOT: 'howToUseMultiLanguagesInBot',
    CHATBOT_DRAFT: 'chatbotDrafts',
    ARTIFICIAL_INTELLIGENCE: 'artificialIntelligence',
    CHATBOT_BRANCH_VERSION_HISTORY: 'chatbotBranchVersionHistory',
    CHATBOT_LOGS: 'chatbotLogs',
    MIDDLEWARE_BRANCHES: 'middlewareBranches',
    MULTIBRANCH_CHATBOT: 'multibranchChatbot',
    CHATBOT_THREADS: 'chatbotThreads',
    CHATBOT_PRESETS: 'chatbotPresets',
    HOW_TO_CREATE_VARIABLES: 'howToCreateVariables',
    HOW_TO_USE_VARIABLES: 'howToUseVariables',
    SYSTEM_VARIABLES: 'systemVariables',
    TEST_TOOLS: 'testTools',
    TEST_TOOLS_OVERVIEW: 'testToolsOverview',
    CHATBOT_TEMPLATES: 'chatbotTemplates',
    CHATBOT_TEMPLATES_OVERVIEW: 'chatbotTemplatesOverview',
    CHATBOT_DRAFT_OVERVIEW: 'chatbotDraftOverview',
    CHATBOT_DRAFT_DEBUG_MODE: 'chatbotDraftDebugMode',
    CHATBOT_ERRORS: 'chatbotErrors',
    HOW_TO_CONNECT_AGENT_TO_CHAT_AND_DISABLE_CHATBOT: 'howToConnectAgentToChatAndDisableChatbot',
    HOW_TO_MAKE_REQUEST_TO_EXTERNAL_SYSTEMS_USING_API: 'howToMakeRequestToExternalSystemsUsingApi',
    HOW_TO_STORE_USERS_PHONE_NUMBER_INTO_USERS_PROFILE: 'howToStoreUserPhoneNumberIntoUserProfile',
    HOW_TO_VALIDATE_USERS_TYPED_TEXT: 'howToValidateUserTypedText',
    HOW_TO_PREVENT_TEXT_ENTRY: 'howToPreventTextEntry',
    HOW_TO_ADD_SEARCHABLE_DATA_TO_USERS_PROFILE: 'howToAddSearchableDataToUsersPofile',
    HOW_TO_CREATE_DIFFERENT_CHATBOT_WELCOME_MESSAGES_DEPENDS_ON_WORK_TIME_HOURS:
        'howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours',
    HOW_TO_CHOOSE_VARIABLE_FROM_ARRAY: 'howToChooseVariableFromArray',
    HOW_TO_FIND_WORD_OR_PHRASE_IN_USERS_MESSAGE: 'howToFindWordOrPhraseInUsersMessage',
    HOW_TO_USE_JS_WITH_BOTS: 'howToUseJsWithBots',
    HOW_TO_INSERT_AND_APPLY_DATA_FROM_CLIENT_NOTIFICATION: 'howToInsertAndApplyDataFromClientNotification',
    HOW_TO_PERSONALIZE_BOT_MENU_IN_VIBER: 'howToPersonalizeBotMenuInViber',
    HOW_TO_SET_UP_BOT_STATISTICS_COLLECTION: 'howToSetUpBotStatisticsCollection',
    HOW_TO_USE_BUTTON_TEXTS_BY_BUTTONS_ARRAY: 'howToUseButtonTextsByButtonsArray',
    HOW_TO_MAKE_STUBS_FOR_NON_WORKING_HOURS: 'howToMakeStubsForNonWorkingHours',
    SEARCH_SERVICE: 'searchService',
    SEARCH_SERVICE_OVERVIEW: 'searchServiceOverview',
    SEARCH_BY_USER_ID: 'searchByUserId',
    SEARCH_BY_PHONE_NUMBER: 'searchByPhoneNumber',
    CONFIGURATION: 'configuration',
    CHANNELS: 'channels',
    AVAILABLE_CHANNELS_LIST: 'availableChannelsList',
    WIDGET_APPEARANCE: 'widgetAppearance',
    WHAT_IS_WIDGET: 'whatIsWidget',
    SINGLE_CHAT: 'singleChat',
    BUILT_IN_LINKER: 'builtInLinker',
    BUILT_IN_CHAT: 'builtInChat',
    CORNER_LINKER: 'cornerLinker',
    METADATA: 'metadata',
    METADATA_OVERVIEW: 'metadataOverview',
    INTEGRATIONS_CONFIG: 'integrationsConfig',
    SERVER_URL_CONFIG_AND_WEBHOOK_EVENTS: 'serverUrlConfigAndWebhookEvents'
};

export const routes = {
    [ROUTE.EDITOR]: '/editor',
    [ROUTE.LOGIN]: '/login',
    [ROUTE.SEARCH]: '/search',
    [ROUTE.NOT_FOUND]: '/not-found',
    [ROUTE.TOPICS]: '/topics',
    [ROUTE.PAGES]: '/pages',
    [ROUTE.GET_STARTED]: '/topics/get-started',
    [ROUTE.INTRO]: '/topics/get-started/intro',
    [ROUTE.WHAT_IS_FLOMNI]: '/topics/get-started/intro/what-is-flomni',
    [ROUTE.GETTING_STARTED_FOR_NEW_USER]: '/topics/get-started/intro/start',
    [ROUTE.CHATBOT_BUILDER]: '/topics/chatbot-builder',
    [ROUTE.BUILD_YOUR_FIRST_CHATBOT]: '/topics/chatbot-builder/first-chatbot',
    [ROUTE.CHATBOT_BUILDER_OVERVIEW]: '/topics/chatbot-builder/chatbot-builder-overview',
    [ROUTE.CHATBOT_RELEASES]: '/topics/chatbot-builder/chatbot-releases',
    [ROUTE.INSTALL_CHATBOT_ON_YOUR_WEBSITE]: '/topics/chatbot-builder/install-chatbot-on-your-website',
    [ROUTE.CHATBOT_NODE_ELEMENTS]: '/topics/chatbot-builder/chatbot-node-elements',
    [ROUTE.FIRST_STEPS_WITH_NODES]: '/topics/chatbot-builder/chatbot-node-elements/first-steps-with-nodes',
    [ROUTE.ACTION_NODES]: '/topics/chatbot-builder/chatbot-node-elements/actions',
    [ROUTE.CHAT_MANAGEMENT_NODE]:
        '/topics/chatbot-builder/chatbot-node-elements/actions/chat-management-node',
    [ROUTE.JS_NODE]: '/topics/chatbot-builder/chatbot-node-elements/actions/js-node',
    [ROUTE.WEBHOOK_NODE]: '/topics/chatbot-builder/chatbot-node-elements/actions/webhook-node',
    [ROUTE.MESSAGE_NODE]: '/topics/chatbot-builder/chatbot-node-elements/actions/message-node',
    [ROUTE.ENTER_POINT_NODE]: '/topics/chatbot-builder/chatbot-node-elements/transfers/enter-point-node',
    [ROUTE.EXIT_POINT_NODE]: '/topics/chatbot-builder/chatbot-node-elements/transfers/exit-point-node',
    [ROUTE.TRANSFER_NODES]: '/topics/chatbot-builder/chatbot-node-elements/transfers',
    [ROUTE.STORAGE_NODES]: '/topics/chatbot-builder/chatbot-node-elements/storages',
    [ROUTE.STORAGE_NODE]: '/topics/chatbot-builder/chatbot-node-elements/storages/storage-node',
    [ROUTE.TAG_NODE]: '/topics/chatbot-builder/chatbot-node-elements/storages/tag-node',
    [ROUTE.JSON_NODE]: '/topics/chatbot-builder/chatbot-node-elements/storages/json-node',
    [ROUTE.NOTE_NODE]: '/topics/chatbot-builder/chatbot-node-elements/storages/note-node',
    [ROUTE.LOGIC_NODES]: '/topics/chatbot-builder/chatbot-node-elements/logics',
    [ROUTE.TEXT_ANALYSIS_NODE]: '/topics/chatbot-builder/chatbot-node-elements/logics/text-analysis-node',
    [ROUTE.RECOGNITION_NODE]: '/topics/chatbot-builder/chatbot-node-elements/logics/recognition-node',
    [ROUTE.RANDOMIZER_NODE]: '/topics/chatbot-builder/chatbot-node-elements/logics/randomizer-node',
    [ROUTE.TIMEOUT_NODE]: '/topics/chatbot-builder/chatbot-node-elements/logics/timeout-node',
    [ROUTE.CONDITION_NODE]: '/topics/chatbot-builder/chatbot-node-elements/logics/condition-node',
    [ROUTE.ACTION_CHAIN]: '/topics/chatbot-builder/chatbot-node-elements/logics/action-chain-node',
    [ROUTE.MEGABRAIN]: '/topics/megabrain-ai',
    [ROUTE.MEGABRAIN_OVERVIEW]: '/topics/megabrain-ai/megabrain-ai-overview',
    [ROUTE.MESSAGE_MARKUP]: '/topics/megabrain-ai/message-markup',
    [ROUTE.ADDING_MESSAGES]: '/topics/megabrain-ai/message-markup/adding-messages',
    [ROUTE.MESSAGE_MARKUP_SUB]: '/topics/megabrain-ai/message-markup/message-markup-sub',
    [ROUTE.TRAINING_SETS]: '/topics/megabrain-ai/training-sets',
    [ROUTE.INCOMING_MESSAGES_SET]: '/topics/megabrain-ai/training-sets/incoming-messages-set',
    [ROUTE.TRAINING_SET_CREATION]: '/topics/megabrain-ai/training-sets/training-set-creation',
    [ROUTE.TRAINING_AND_PERFORMANCE]: '/topics/megabrain-ai/training-and-performance',
    [ROUTE.PERFORMANCE]: '/topics/megabrain-ai/training-and-performance/performance',
    [ROUTE.STORY_TRAINING]: '/topics/megabrain-ai/training-and-performance/story-training',
    [ROUTE.STORIES]: '/topics/megabrain-ai/stories',
    [ROUTE.STOP_WORDS]: '/topics/megabrain-ai/stories/stopWords',
    [ROUTE.ENTITY_RECOGNITION]: '/topics/megabrain-ai/stories/entityRecognition',
    [ROUTE.CONTEXTS]: '/topics/megabrain-ai/stories/contexts',
    [ROUTE.TOPIC_RECOGNITION]: '/topics/megabrain-ai/stories/topicRecognition',
    [ROUTE.EMOTION_RECOGNITION]: '/topics/megabrain-ai/stories/emotionRecognition',
    [ROUTE.STORIES_SUB]: '/topics/megabrain-ai/stories/storiesSub',
    [ROUTE.DIALOGS]: '/topics/dialogs',
    [ROUTE.DIALOGS_OPERATORS]: '/topics/dialogs/dialogs-operators',
    [ROUTE.DIALOGS_STATISTICS]: '/topics/dialogs/dialogs-operators/dialogs-statistics',
    [ROUTE.TEAM_ANALYTICS]: '/topics/dialogs/dialogs-operators/dialogs-statistics/team-analytics',
    [ROUTE.GENERAL_STATISTICS]: '/topics/dialogs/dialogs-operators/dialogs-statistics/general-statistics',

    [ROUTE.ACCOUNT_MANAGEMENT]: '/topics/dialogs/dialogs-operators/account-management',
    [ROUTE.PROFILE_DATA]: '/topics/dialogs/dialogs-operators/account-management/profile-data',
    [ROUTE.NOTIFICATIONS]: '/topics/dialogs/dialogs-operators/account-management/notifications',
    [ROUTE.DIAGNOSTICS]: '/topics/dialogs/dialogs-operators/account-management/diagnostics',
    [ROUTE.MESSAGES_BUTTONS]: '/topics/dialogs/dialogs-operators/account-management/messages-buttons',
    [ROUTE.ACCOUNT_CONFIGURATION]:
        '/topics/dialogs/dialogs-operators/account-management/account-configuration',
    [ROUTE.MESSAGES_DRAFTS]: '/topics/dialogs/dialogs-operators/account-management/messages-drafts',

    [ROUTE.EVENTS]: '/topics/dialogs/dialogs-operators/events',
    [ROUTE.AUTO_ARCHIVED_CHATS]: '/topics/dialogs/dialogs-operators/events/auto-archived-chats',
    [ROUTE.REMINDERS]: '/topics/dialogs/dialogs-operators/events/reminders',

    [ROUTE.CHATS]: '/topics/dialogs/dialogs-operators/chats',
    [ROUTE.CHAT_ATTACHMENTS]: '/topics/dialogs/dialogs-operators/chats/chat-attachments',
    [ROUTE.CHAT_ACTIONS]: '/topics/dialogs/dialogs-operators/chats/chat-actions',
    [ROUTE.CHAT_ADD_ACTION]: '/topics/dialogs/dialogs-operators/chats/chat-add-action',
    [ROUTE.CHAT_ADD_BUTTON]: '/topics/dialogs/dialogs-operators/chats/chat-add-button',
    [ROUTE.CHAT_ADD_TEMPLATE]: '/topics/dialogs/dialogs-operators/chats/chat-add-template',
    [ROUTE.CHAT_ADD_EMOJI]: '/topics/dialogs/dialogs-operators/chats/chat-add-emoji',
    [ROUTE.CHAT_NOTE]: '/topics/dialogs/dialogs-operators/chats/chat-note',
    [ROUTE.CHAT_EDIT_CLIENT_INFO]: '/topics/dialogs/dialogs-operators/chats/chat-edit-client-info',
    [ROUTE.CHAT_DIALOG_SORT_AND_FILTER]:
        '/topics/dialogs/dialogs-operators/chats/chat-dialog-sort-and-filter',
    [ROUTE.CHAT_MESSAGE_EDIT_AND_DELETE]:
        '/topics/dialogs/dialogs-operators/chats/chat-message-edit-and-delete',
    [ROUTE.CHAT_HINTS]: '/topics/dialogs/dialogs-operators/chats/chat-hints',

    [ROUTE.DIALOGS_SUPERVISORS]: '/topics/dialogs/dialogs-supervisors',
    [ROUTE.DIALOGS_OVERVIEW]: '/topics/dialogs/dialogs-supervisors/dialogs-overview',

    [ROUTE.MAIN_DEFINITIONS]: '/topics/dialogs/dialogs-supervisors/main-definitions',
    [ROUTE.DEFINITIONS]: '/topics/dialogs/dialogs-supervisors/main-definitions/definitions',

    [ROUTE.DIALOGS_SEARCH]: '/topics/dialogs/dialogs-supervisors/dialogs-search',
    [ROUTE.VARIOUS_FILTERS_SEARCH]:
        '/topics/dialogs/dialogs-supervisors/dialogs-search/various-filters-search',
    [ROUTE.WORD_OR_PHRASE_SEARCH]: '/topics/dialogs/dialogs-supervisors/dialogs-search/word-or-phrase-search',

    [ROUTE.OPERATORS_TEAM]: '/topics/dialogs/dialogs-supervisors/account-management/operators-team',

    [ROUTE.DIALOGS_ADMINISTRATORS]: '/topics/dialogs/dialogs-administrators',

    [ROUTE.GROUP_CONFIGURATION]: '/topics/dialogs/dialogs-administrators/group-configuration',
    [ROUTE.CHAT_ARCHIVE]: '/topics/dialogs/dialogs-administrators/group-configuration/chat-archive',
    [ROUTE.CHAT_VISIBILITY]: '/topics/dialogs/dialogs-administrators/group-configuration/chat-visibility',
    [ROUTE.AGENT_GROUPS]: '/topics/dialogs/dialogs-administrators/group-configuration/agent-groups',
    [ROUTE.CHANNEL_PRIORITIZATION]:
        '/topics/dialogs/dialogs-administrators/group-configuration/channel-prioritization',
    [ROUTE.CHAT_DISTRIBUTION]: '/topics/dialogs/dialogs-administrators/group-configuration/chat-distribution',
    [ROUTE.AGENT_STATUSES]: '/topics/dialogs/dialogs-administrators/group-configuration/agent-statuses',
    [ROUTE.DISTRIBUTION_TAGS]: '/topics/dialogs/dialogs-administrators/group-configuration/distribution-tags',
    [ROUTE.CHATBOT_ACTIONS]: '/topics/dialogs/dialogs-administrators/group-configuration/chatbot-actions',
    [ROUTE.SESSIONS_TOPICS]: '/topics/dialogs/dialogs-administrators/group-configuration/sessions-topics',
    [ROUTE.ANALYTICS]: '/topics/analytics',
    [ROUTE.ANALYTICS_OVERVIEW]: '/topics/analytics/analytics-overview',
    [ROUTE.REPORTS]: '/topics/analytics/reports',
    [ROUTE.REPORT_VALUES_DETAILIZATION]: '/topics/analytics/reports/report-values-detailization',
    [ROUTE.INTEGRATIONS]: '/topics/analytics/reports/integrations',
    [ROUTE.MY_REPORTS]: '/topics/analytics/reports/my-reports',
    [ROUTE.TAG_CHANGES]: '/topics/analytics/reports/tag-changes',
    [ROUTE.REPORTS_MEGABRAIN]: '/topics/analytics/reports/reports-megabrain',
    [ROUTE.SESSIONS_OVERVIEW]: '/topics/analytics/reports/sessions-overview',
    [ROUTE.OTHER_REPORTS_OPTIONS]: '/topics/analytics/reports/other-reports-options',
    [ROUTE.OPERATOR_WORK]: '/topics/analytics/reports/operator-work',
    [ROUTE.MESSAGE_TRANSPORT_REPORT]: '/topics/analytics/reports/message-transport-report',
    [ROUTE.CUSTOMER_SERVICE_ASSESSMENT]: '/topics/analytics/reports/customer-service-assessment',
    [ROUTE.ANALYTICS_CONFIGURATION]: '/topics/analytics/analytics-configuration',
    [ROUTE.EVENT_MANAGEMENT]: '/topics/analytics/analytics-configuration/event-management',
    [ROUTE.CHART_CONFIGURATION]: '/topics/analytics/analytics-configuration/chart-configuration',
    [ROUTE.REPORT_CONFIGURATOR]: '/topics/analytics/analytics-configuration/report-configurator',
    [ROUTE.REPORT_SCHEDULE]: '/topics/analytics/analytics-configuration/report-schedule',
    [ROUTE.TARGET_MONITORING]: '/topics/analytics/target-monitoring',
    [ROUTE.RULE_CREATION]: '/topics/analytics/target-monitoring/rule-creation',
    [ROUTE.MESSAGE_TRANSPORT]: '/topics/message-transport',
    [ROUTE.MESSAGE_TRANSPORT_OVERVIEW]: '/topics/message-transport/message-transport-overview',
    [ROUTE.ACCOUNT]: '/topics/account',
    [ROUTE.ACCOUNT_OVERVIEW]: '/topics/account/account-overview',
    [ROUTE.DASHBOARD]: '/topics/account/dashboard',
    [ROUTE.TIPS]: '/topics/tips',
    [ROUTE.TIPS_OVERVIEW]: '/topics/tips/tips-overview',
    [ROUTE.ADVANCED_FEATURES]: '/topics/chatbot-builder/advanced-features',
    [ROUTE.CHATBOT_VARIABLES]: '/topics/chatbot-builder/chatbot-variables',
    [ROUTE.USER_CASES]: '/topics/chatbot-builder/user-cases',
    [ROUTE.HOW_TO_USE_MULTI_LANGUAGES_IN_BOT]:
        '/topics/chatbot-builder/user-cases/how-to-use-multi-languages-in-bot',
    [ROUTE.CHATBOT_DRAFT]: '/topics/chatbot-builder/chatbot-drafts',
    [ROUTE.ARTIFICIAL_INTELLIGENCE]: '/topics/chatbot-builder/advanced-features/artificial-intelligence',
    [ROUTE.CHATBOT_BRANCH_VERSION_HISTORY]:
        '/topics/chatbot-builder/advanced-features/chatbot-branch-version-history',
    [ROUTE.CHATBOT_LOGS]: '/topics/chatbot-builder/advanced-features/chatbot-logs',
    [ROUTE.MIDDLEWARE_BRANCHES]: '/topics/chatbot-builder/advanced-features/middleware-branches',
    [ROUTE.MULTIBRANCH_CHATBOT]: '/topics/chatbot-builder/advanced-features/multibranch-chatbot',
    [ROUTE.CHATBOT_THREADS]: '/topics/chatbot-builder/advanced-features/chatbot-threads',
    [ROUTE.CHATBOT_PRESETS]: '/topics/chatbot-builder/advanced-features/chatbot-presets',
    [ROUTE.HOW_TO_CREATE_VARIABLES]: '/topics/chatbot-builder/chatbot-variables/how-to-create-variables',
    [ROUTE.HOW_TO_USE_VARIABLES]: '/topics/chatbot-builder/chatbot-variables/how-to-use-variables',
    [ROUTE.SYSTEM_VARIABLES]: '/topics/chatbot-builder/chatbot-variables/system-variables',
    [ROUTE.TEST_TOOLS]: '/topics/chatbot-builder/test-tools',
    [ROUTE.TEST_TOOLS_OVERVIEW]: '/topics/chatbot-builder/test-tools/test-tools-overview',
    [ROUTE.CHATBOT_TEMPLATES]: '/topics/chatbot-builder/chatbot-templates',
    [ROUTE.CHATBOT_TEMPLATES_OVERVIEW]:
        '/topics/chatbot-builder/chatbot-templates/chatbot-templates-overview',
    [ROUTE.CHATBOT_DRAFT_OVERVIEW]: '/topics/chatbot-builder/chatbot-drafts/chatbot-draft-overview',
    [ROUTE.CHATBOT_DRAFT_DEBUG_MODE]: '/topics/chatbot-builder/chatbot-drafts/chatbot-draft-debug-mode',
    [ROUTE.CHATBOT_ERRORS]: '/topics/chatbot-builder/chatbot-drafts/chatbot-errors',
    [ROUTE.HOW_TO_CONNECT_AGENT_TO_CHAT_AND_DISABLE_CHATBOT]:
        '/topics/chatbot-builder/user-cases/how-to-connect-agent-to-chat-and-disable-chatbot',
    [ROUTE.HOW_TO_MAKE_REQUEST_TO_EXTERNAL_SYSTEMS_USING_API]:
        '/topics/chatbot-builder/user-cases/how-to-make-request-to-external-systems-using-api',
    [ROUTE.HOW_TO_STORE_USERS_PHONE_NUMBER_INTO_USERS_PROFILE]:
        '/topics/chatbot-builder/user-cases/how-to-store-user-phone-number-into-user-profile',
    [ROUTE.HOW_TO_VALIDATE_USERS_TYPED_TEXT]:
        '/topics/chatbot-builder/user-cases/how-to-validate-user-typed-text',
    [ROUTE.HOW_TO_PREVENT_TEXT_ENTRY]: '/topics/chatbot-builder/user-cases/how-to-prevent-text-entry',
    [ROUTE.HOW_TO_ADD_SEARCHABLE_DATA_TO_USERS_PROFILE]:
        '/topics/chatbot-builder/user-cases/how-to-add-searchable-data-to-users-pofile',
    [ROUTE.HOW_TO_CREATE_DIFFERENT_CHATBOT_WELCOME_MESSAGES_DEPENDS_ON_WORK_TIME_HOURS]:
        '/topics/chatbot-builder/user-cases/how-to-create-different-chatbot-welcome-messages-depends-on-work-time-hours',
    [ROUTE.HOW_TO_CHOOSE_VARIABLE_FROM_ARRAY]:
        '/topics/chatbot-builder/user-cases/how-to-choose-variable-from-array',
    [ROUTE.HOW_TO_FIND_WORD_OR_PHRASE_IN_USERS_MESSAGE]:
        '/topics/chatbot-builder/user-cases/how-to-find-word-or-phrase-in-users-message',
    [ROUTE.HOW_TO_USE_JS_WITH_BOTS]: '/topics/chatbot-builder/user-cases/how-to-use-js-with-bots',
    [ROUTE.HOW_TO_INSERT_AND_APPLY_DATA_FROM_CLIENT_NOTIFICATION]:
        '/topics/chatbot-builder/user-cases/how-to-insert-and-apply-data-from-client-notification',
    [ROUTE.HOW_TO_PERSONALIZE_BOT_MENU_IN_VIBER]:
        '/topics/chatbot-builder/user-cases/how-to-personalize-bot-menu-in-viber',
    [ROUTE.HOW_TO_SET_UP_BOT_STATISTICS_COLLECTION]:
        '/topics/chatbot-builder/user-cases/how-to-set-up-bot-statistics-collection',
    [ROUTE.HOW_TO_USE_BUTTON_TEXTS_BY_BUTTONS_ARRAY]:
        '/topics/chatbot-builder/user-cases/how-to-use-button-texts-by-buttons-array',
    [ROUTE.HOW_TO_MAKE_STUBS_FOR_NON_WORKING_HOURS]:
        '/topics/chatbot-builder/user-cases/how-to-make-stubs-for-non-working-hours',
    [ROUTE.SEARCH_SERVICE]: '/topics/search-service',
    [ROUTE.SEARCH_SERVICE_OVERVIEW]: '/topics/search-service/search-service-overview',
    [ROUTE.SEARCH_BY_USER_ID]: '/topics/search-service/search-by-user-id',
    [ROUTE.SEARCH_BY_PHONE_NUMBER]: '/topics/search-service/search-by-phone-number',
    [ROUTE.HELP]: '/topics/help',
    [ROUTE.HELP_OVERVIEW]: '/topics/help/help-overview',
    [ROUTE.CONFIGURATION]: '/topics/help/configuration',
    [ROUTE.CHANNELS]: '/topics/help/configuration/channels',
    [ROUTE.AVAILABLE_CHANNELS_LIST]: '/topics/help/configuration/channels/available-channels-list',
    [ROUTE.WIDGET_APPEARANCE]: '/topics/help/configuration/widget-appearance',
    [ROUTE.SINGLE_CHAT]: '/topics/help/configuration/widget-appearance/single-chat',
    [ROUTE.WHAT_IS_WIDGET]: '/topics/help/configuration/widget-appearance/what-is-widget',
    [ROUTE.BUILT_IN_LINKER]: '/topics/help/configuration/widget-appearance/built-in-linker',
    [ROUTE.BUILT_IN_CHAT]: '/topics/help/configuration/widget-appearance/built-in-chat',
    [ROUTE.CORNER_LINKER]: '/topics/help/configuration/widget-appearance/corner-linker',
    [ROUTE.METADATA]: '/topics/help/configuration/metadata',
    [ROUTE.METADATA_OVERVIEW]: '/topics/help/configuration/metadata/metadata-overview',
    [ROUTE.INTEGRATIONS_CONFIG]: '/topics/help/configuration/integrations-config',
    [ROUTE.SERVER_URL_CONFIG_AND_WEBHOOK_EVENTS]:
        '/topics/help/configuration/server-url-config-and-webhook-events'
};

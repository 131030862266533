import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useLocation } from 'react-router';
import {useEffect, useState} from 'react';
import FormMessage from '../shared/form-message';
import { object } from 'prop-types';
import { StickyMenu } from '../sticky-menu';
import { useTranslation } from 'react-i18next';
import { Title } from '../shared/title';
import { Paragraph } from '../shared/paragraph';
import { Header } from '../shared/header';
import { Header2 } from '../shared/header2';
import { Body } from '../shared/body';
import { PageVideo } from '../shared/video';
import { PageImage } from '../shared/image';
import { PageTip } from '../shared/tip';
import axios from 'axios'
import { URL } from '../../editor/constants'
import ReactMarkdown from 'react-markdown'
import { Tree } from '../../tree'

export const Page = ({ container }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const path = location.pathname.replace('/pages/', '');
    const [page, setPage] = useState(null)
    const [isOpenedMessageForm, setIsOpenedMessageForm] = useState(false);

    useEffect(() => {
        axios.get(URL.PAGE_BY_URL(encodeURIComponent(btoa(path)) , i18n.language)).then((response) => {
            setPage(response.data)
        }).catch((error) => {
          setPage(null)
        })
    }, [location, i18n.language])

    return (
        <div className={css('page')}>
            <Tree mainClass={css('menu')} container={container} />
            <div className={css('separator')} />
            {isOpenedMessageForm && (
                <FormMessage
                    onCancel={() => setIsOpenedMessageForm(false)}
                    onSend={() => setIsOpenedMessageForm(false)}
                />
            )}
            {!!page && <Body>
                <Title>{page.title}</Title>
                {page.content.children.map((item, index) => (
                    <div key={`${item.kind}-${index}`}>
                        {item.kind === 'Header' && <Header id={`${item.kind}-${index}`}>{item.content}</Header>}
                        {item.kind === 'Header2' && <Header2 id={`${item.kind}-${index}`}>{item.content}</Header2>}
                        {item.kind === 'Paragraph' && <Paragraph>
                            <ReactMarkdown>{item.content}</ReactMarkdown>
                        </Paragraph>}
                        {item.kind === 'PageTip' && <PageTip>
                            <ReactMarkdown>{item.content}</ReactMarkdown>
                        </PageTip>}
                        {item.kind === 'PageVideo' && <PageVideo video={item.content} />}
                        {item.kind === 'PageImage' && <PageImage image={item.content} />}
                    </div>
                ))}
            </Body>}
            <div className={css('separator')} />
            <StickyMenu
                mainClass={css('sticky-menu')}
                items={page?.content?.children.map((item, index) => (
                  {id: `${item.kind}-${index}`, title: item.content, kind: item.kind}
                )).filter((item) => item.kind === 'Header') || []}
                container={container}
            />
        </div>
    );
};

Page.propTypes = {
    container: object.isRequired
};

const whatIsWidgetItems = [
    {
        id: 'whatIsWidget1',
        title: 'helpPages.whatIsWidget.whatIsWidget1'
    },
    {
        id: 'whatIsWidget8',
        title: 'helpPages.whatIsWidget.whatIsWidget8'
    },
    {
        id: 'whatIsWidget16',
        title: 'helpPages.whatIsWidget.whatIsWidget16'
    },
    {
        id: 'whatIsWidget24',
        title: 'helpPages.whatIsWidget.whatIsWidget24'
    }
];

export default whatIsWidgetItems;

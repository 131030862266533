import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotErrors = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotErrors');

    return (
        <Body>
            <Title>{t('hlp:chatbotErrors')}</Title>
            <Header id="chatbotErrors1">{t('hlp:helpPages.chatbotErrors.chatbotErrors1')}</Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotErrors.chatbotErrors2')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph id="chatbotErrors3">
                        <Trans i18nKey="hlp:helpPages.chatbotErrors.chatbotErrors3">
                            0<span className={css('bold')} />
                        </Trans>
                    </Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotErrors.chatbotErrors4')}</Paragraph>
                </ListItem>
            </List>
            <Header id="chatbotErrors5">{t('hlp:helpPages.chatbotErrors.chatbotErrors5')}</Header>
            <Paragraph id="chatbotErrors6">
                <Trans i18nKey="hlp:helpPages.chatbotErrors.chatbotErrors6">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.chatbotErrors.chatbotErrors7')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.chatbotErrors.chatbotErrors8')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.chatbotErrors.chatbotErrors9')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph id="chatbotErrors10">
                <Trans i18nKey="hlp:helpPages.chatbotErrors.chatbotErrors10">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
        </Body>
    );
};

ChatbotErrors.propTypes = {};

const chatbotLogsItems = [
    {
        id: 'chatbotLogs1',
        title: 'helpPages.chatbotLogs.chatbotLogs1'
    },
    {
        id: 'chatbotLogs3',
        title: 'helpPages.chatbotLogs.chatbotLogs3'
    }
];
 
export default chatbotLogsItems;

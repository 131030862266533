import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video21 from './assets/video21.mov';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import video31 from './assets/video31.mov';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import video34 from './assets/video34.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotThreads = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotThreads');

    return (
        <Body>
            <Title>{t('hlp:chatbotThreads')}</Title>
            <Header id="chatbotThreads1">{t('hlp:helpPages.chatbotThreads.chatbotThreads1')}</Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads2')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads3')}</Paragraph>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads4')}</Paragraph>
            <Header id="chatbotThreads5">{t('hlp:helpPages.chatbotThreads.chatbotThreads5')}</Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph id="chatbotThreads8">
                        <Trans i18nKey="hlp:helpPages.chatbotThreads.chatbotThreads8">
                            0<Link to={routes[ROUTE.WHAT_IS_WIDGET]} className={css('link')}></Link>
                        </Trans>
                    </Paragraph>
                </ListItem>
            </List>
            <Header id="chatbotThreads9">{t('hlp:helpPages.chatbotThreads.chatbotThreads9')}</Header>
            <Header2 id="chatbotThreads10">{t('hlp:helpPages.chatbotThreads.chatbotThreads10')}</Header2>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads12')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads13')}</Paragraph>
            <PageVideo video={video21} />
            <Paragraph id="chatbotThreads14">
                <Trans i18nKey="hlp:helpPages.chatbotThreads.chatbotThreads14">
                    0<span className={css('bold')}></span>
                </Trans>
            </Paragraph>
            <PageVideo video={video22} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads15')}</Paragraph>
            <Header2 id="chatbotThreads10">{t('hlp:helpPages.chatbotThreads.chatbotThreads16')}</Header2>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads17')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads19')}</Paragraph>
            <Paragraph id="chatbotThreads20">
                <Trans i18nKey="hlp:helpPages.chatbotThreads.chatbotThreads20">
                    <Link to={routes[ROUTE.MULTIBRANCH_CHATBOT]} className={css('link')}>
                        0
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video23} />
            <Paragraph id="chatbotThreads21">
                <Trans i18nKey="hlp:helpPages.chatbotThreads.chatbotThreads21">
                    0<span className={css('bold')}></span>
                </Trans>
            </Paragraph>
            <PageVideo video={video24} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads22')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads23')}</Paragraph>
            <PageVideo video={video25} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads24')}</Paragraph>
            <PageVideo video={video26} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads25')}</Paragraph>
            <PageVideo video={video27} />
            <Header2 id="chatbotThreads10">{t('hlp:helpPages.chatbotThreads.chatbotThreads26')}</Header2>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads27')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads28')}</Paragraph>
            <PageVideo video={video28} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads29')}</Paragraph>
            <PageVideo video={video29} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads30')}</Paragraph>
            <Header2 id="chatbotThreads31">{t('hlp:helpPages.chatbotThreads.chatbotThreads31')}</Header2>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads32')}</Paragraph>
            <PageVideo video={video30} />
            <Header2 id="chatbotThreads33">{t('hlp:helpPages.chatbotThreads.chatbotThreads33')}</Header2>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads34')}</Paragraph>
            <PageVideo video={video31} />
            <Header id="chatbotThreads35">{t('hlp:helpPages.chatbotThreads.chatbotThreads35')}</Header>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads36')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads37')}</Paragraph>
            <PageVideo video={video32} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads38')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads39')}</Paragraph>
            <PageVideo video={video33} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads40')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads41')}</Paragraph>
            <PageVideo video={video34} />
            <Paragraph>{t('hlp:helpPages.chatbotThreads.chatbotThreads42')}</Paragraph>
        </Body>
    );
};

ChatbotThreads.propTypes = {};

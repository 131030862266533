import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const RandomizerNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:randomizerNode');

    return (
        <Body>
            <Title>{t('hlp:randomizerNode')}</Title>
            <Header id="randomizerNode1">{t('hlp:helpPages.randomizerNode.randomizerNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.randomizerNode.randomizerNode3')}</ListItem>
            </List>
            <PageTip>
                <div>
                    <Trans i18nKey="hlp:helpPages.randomizerNode.randomizerNode4">
                        0
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </div>
                <div>
                    <Paragraph>{t('hlp:helpPages.randomizerNode.randomizerNode5')}</Paragraph>
                </div>
            </PageTip>
            <Header id="randomizerNode6">{t('hlp:helpPages.randomizerNode.randomizerNode6')}</Header>
            <PageVideo video={video1} />
            <Header id="randomizerNode7">{t('hlp:helpPages.randomizerNode.randomizerNode7')}</Header>
            <Header2 id="randomizerNode8">{t('hlp:helpPages.randomizerNode.randomizerNode8')}</Header2>
            <Paragraph>{t('hlp:helpPages.randomizerNode.randomizerNode9')}</Paragraph>
            <PageVideo video={video2} />
            <PageTip view="info">
                <div>
                    <Paragraph>{t('hlp:helpPages.randomizerNode.randomizerNode10')}</Paragraph>
                </div>
                <div>
                    <Paragraph>{t('hlp:helpPages.randomizerNode.randomizerNode11')}</Paragraph>
                </div>
            </PageTip>
            <Header id="randomizerNode12">{t('hlp:helpPages.randomizerNode.randomizerNode12')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.randomizerNode.randomizerNode13">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <List>
                <ListItem>{t('hlp:helpPages.randomizerNode.randomizerNode14')}</ListItem>
                <ListItem>{t('hlp:helpPages.randomizerNode.randomizerNode15')}</ListItem>
                <ListItem>{t('hlp:helpPages.randomizerNode.randomizerNode16')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.randomizerNode.randomizerNode17')}</Paragraph>
            <PageVideo video={video3} />
        </Body>
    );
};

RandomizerNode.propTypes = {};

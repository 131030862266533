import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatManagementNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatManagementNode');

    return (
        <Body>
            <Title>{t('hlp:chatManagementNode')}</Title>
            <Header id="chatManagementNode1">
                {t('hlp:helpPages.chatManagementNode.chatManagementNode1')}
            </Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode2">
                        0 <span className={css('bold')}>1</span>
                    </Trans>
                </ListItem>
            </List>
            <Header id="chatManagementNode3">
                {t('hlp:helpPages.chatManagementNode.chatManagementNode3')}
            </Header>
            <PageVideo video={video1} />
            <Header id="chatManagementNode4">
                {t('hlp:helpPages.chatManagementNode.chatManagementNode4')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatManagementNode.chatManagementNode6')}</Paragraph>
            <PageVideo video={video2} />
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode7">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode8">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode9">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode10">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode11">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode12">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode13">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode14">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode15">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatManagementNode.chatManagementNode16">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
            </List>
        </Body>
    );
};

ChatManagementNode.propTypes = {};

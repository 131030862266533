const otherReportsOptionsItems = [
    {
        id: 'otherReportsOptions1',
        title: 'helpPages.otherReportsOptions.otherReportsOptions1'
    },
    {
        id: 'otherReportsOptions4',
        title: 'helpPages.otherReportsOptions.otherReportsOptions4'
    }
];

export default otherReportsOptionsItems;

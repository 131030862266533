import { ROUTE } from '../routes';

export const menu = [
    // HIDE GET_STARTED MENU ITEMS (FL-4285)
    // {
    //     name: ROUTE.GET_STARTED,
    //     items: [
    //         {
    //             name: ROUTE.INTRO,
    //             items: [
    //                 {
    //                     name: ROUTE.WHAT_IS_FLOMNI
    //                 }
    //                 // {
    //                 //     name: ROUTE.GETTING_STARTED_FOR_NEW_USER
    //                 // }
    //             ]
    //         }
    //     ]
    // },
    {
        name: ROUTE.CHATBOT_BUILDER,
        items: [
            {
                name: ROUTE.CHATBOT_BUILDER_OVERVIEW
            },
            {
                name: ROUTE.BUILD_YOUR_FIRST_CHATBOT
            },
            {
                name: ROUTE.CHATBOT_RELEASES
            },
            {
                name: ROUTE.INSTALL_CHATBOT_ON_YOUR_WEBSITE
            },
            {
                name: ROUTE.CHATBOT_NODE_ELEMENTS,
                items: [
                    {
                        name: ROUTE.FIRST_STEPS_WITH_NODES
                    },
                    {
                        name: ROUTE.TRANSFER_NODES,
                        items: [
                            {
                                name: ROUTE.ENTER_POINT_NODE
                            },
                            {
                                name: ROUTE.EXIT_POINT_NODE
                            }
                        ]
                    },
                    {
                        name: ROUTE.ACTION_NODES,
                        items: [
                            {
                                name: ROUTE.MESSAGE_NODE
                            },
                            {
                                name: ROUTE.CHAT_MANAGEMENT_NODE
                            },
                            {
                                name: ROUTE.WEBHOOK_NODE
                            },
                            {
                                name: ROUTE.JS_NODE
                            }
                        ]
                    },
                    {
                        name: ROUTE.LOGIC_NODES,
                        items: [
                            {
                                name: ROUTE.TEXT_ANALYSIS_NODE
                            },
                            {
                                name: ROUTE.RECOGNITION_NODE
                            },
                            {
                                name: ROUTE.RANDOMIZER_NODE
                            },
                            {
                                name: ROUTE.TIMEOUT_NODE
                            },
                            {
                                name: ROUTE.CONDITION_NODE
                            },
                            {
                                name: ROUTE.ACTION_CHAIN
                            }
                        ]
                    },
                    {
                        name: ROUTE.STORAGE_NODES,
                        items: [
                            {
                                name: ROUTE.STORAGE_NODE
                            },
                            {
                                name: ROUTE.TAG_NODE
                            },
                            {
                                name: ROUTE.JSON_NODE
                            },
                            {
                                name: ROUTE.NOTE_NODE
                            }
                        ]
                    }
                ]
            },
            {
                name: ROUTE.CHATBOT_VARIABLES,
                items: [
                    {
                        name: ROUTE.HOW_TO_CREATE_VARIABLES
                    },
                    {
                        name: ROUTE.HOW_TO_USE_VARIABLES
                    },
                    {
                        name: ROUTE.SYSTEM_VARIABLES
                    }
                ]
            },
            {
                name: ROUTE.CHATBOT_DRAFT,
                items: [
                    {
                        name: ROUTE.CHATBOT_DRAFT_OVERVIEW
                    },
                    {
                        name: ROUTE.CHATBOT_DRAFT_DEBUG_MODE
                    },
                    {
                        name: ROUTE.CHATBOT_ERRORS
                    }
                ]
            },
            {
                name: ROUTE.ADVANCED_FEATURES,
                items: [
                    {
                        name: ROUTE.ARTIFICIAL_INTELLIGENCE
                    },
                    {
                        name: ROUTE.CHATBOT_BRANCH_VERSION_HISTORY
                    },
                    {
                        name: ROUTE.CHATBOT_LOGS
                    },
                    {
                        name: ROUTE.MIDDLEWARE_BRANCHES
                    },
                    {
                        name: ROUTE.MULTIBRANCH_CHATBOT
                    },
                    {
                        name: ROUTE.CHATBOT_THREADS
                    },
                    {
                        name: ROUTE.CHATBOT_PRESETS
                    }
                ]
            },
            // {
            //     name: ROUTE.CHATBOT_TEMPLATES,
            //     items: [
            //         {
            //             name: ROUTE.CHATBOT_TEMPLATES_OVERVIEW
            //         }
            //     ]
            // },
            // {
            //     name: ROUTE.TEST_TOOLS,
            //     items: [
            //         {
            //             name: ROUTE.TEST_TOOLS_OVERVIEW
            //         }
            //     ]
            // },
            {
                name: ROUTE.USER_CASES,
                items: [
                    {
                        name: ROUTE.HOW_TO_CONNECT_AGENT_TO_CHAT_AND_DISABLE_CHATBOT
                    },
                    {
                        name: ROUTE.HOW_TO_MAKE_REQUEST_TO_EXTERNAL_SYSTEMS_USING_API
                    },
                    {
                        name: ROUTE.HOW_TO_STORE_USERS_PHONE_NUMBER_INTO_USERS_PROFILE
                    },
                    {
                        name: ROUTE.HOW_TO_VALIDATE_USERS_TYPED_TEXT
                    },
                    {
                        name: ROUTE.HOW_TO_PREVENT_TEXT_ENTRY
                    },
                    {
                        name: ROUTE.HOW_TO_ADD_SEARCHABLE_DATA_TO_USERS_PROFILE
                    },
                    {
                        name: ROUTE.HOW_TO_CREATE_DIFFERENT_CHATBOT_WELCOME_MESSAGES_DEPENDS_ON_WORK_TIME_HOURS
                    },
                    {
                        name: ROUTE.HOW_TO_CHOOSE_VARIABLE_FROM_ARRAY
                    },
                    {
                        name: ROUTE.HOW_TO_FIND_WORD_OR_PHRASE_IN_USERS_MESSAGE
                    },
                    {
                        name: ROUTE.HOW_TO_USE_JS_WITH_BOTS
                    },
                    {
                        name: ROUTE.HOW_TO_INSERT_AND_APPLY_DATA_FROM_CLIENT_NOTIFICATION
                    },
                    {
                        name: ROUTE.HOW_TO_PERSONALIZE_BOT_MENU_IN_VIBER
                    },
                    {
                        name: ROUTE.HOW_TO_SET_UP_BOT_STATISTICS_COLLECTION
                    },
                    {
                        name: ROUTE.HOW_TO_USE_BUTTON_TEXTS_BY_BUTTONS_ARRAY
                    },
                    {
                        name: ROUTE.HOW_TO_MAKE_STUBS_FOR_NON_WORKING_HOURS
                    },
                    {
                        name: ROUTE.HOW_TO_USE_MULTI_LANGUAGES_IN_BOT
                    }
                ]
            }
        ]
    },
    {
        name: ROUTE.MEGABRAIN,
        items: [
            {
                name: ROUTE.MEGABRAIN_OVERVIEW
            },
            {
                name: ROUTE.MESSAGE_MARKUP,
                items: [
                    {
                        name: ROUTE.ADDING_MESSAGES
                    },
                    {
                        name: ROUTE.MESSAGE_MARKUP_SUB
                    }
                ]
            },
            {
                name: ROUTE.STORIES,
                items: [
                    {
                        name: ROUTE.CONTEXTS
                    },
                    {
                        name: ROUTE.STOP_WORDS
                    },
                    {
                        name: ROUTE.ENTITY_RECOGNITION
                    },
                    {
                        name: ROUTE.TOPIC_RECOGNITION
                    },
                    {
                        name: ROUTE.EMOTION_RECOGNITION
                    },
                    {
                        name: ROUTE.STORIES_SUB
                    }
                ]
            },
            {
                name: ROUTE.TRAINING_SETS,
                items: [
                    {
                        name: ROUTE.INCOMING_MESSAGES_SET
                    },
                    {
                        name: ROUTE.TRAINING_SET_CREATION
                    }
                ]
            },
            {
                name: ROUTE.TRAINING_AND_PERFORMANCE,
                items: [
                    {
                        name: ROUTE.PERFORMANCE
                    },
                    {
                        name: ROUTE.STORY_TRAINING
                    }
                ]
            }
        ]
    },
    {
        name: ROUTE.MESSAGE_TRANSPORT,
        items: [
            {
                name: ROUTE.MESSAGE_TRANSPORT_OVERVIEW
            }
        ]
    },
    {
        name: ROUTE.DIALOGS,
        items: [
            {
                name: ROUTE.DIALOGS_OVERVIEW
            },
            {
                name: ROUTE.DIALOGS_OPERATORS,
                items: [
                    {
                        name: ROUTE.DIALOGS_STATISTICS,
                        items: [
                            {
                                name: ROUTE.TEAM_ANALYTICS
                            },
                            {
                                name: ROUTE.GENERAL_STATISTICS
                            }
                        ]
                    },
                    {
                        name: ROUTE.ACCOUNT_MANAGEMENT,
                        items: [
                            {
                                name: ROUTE.PROFILE_DATA
                            },
                            {
                                name: ROUTE.NOTIFICATIONS
                            },
                            {
                                name: ROUTE.DIAGNOSTICS
                            },
                            {
                                name: ROUTE.ACCOUNT_CONFIGURATION
                            },
                            {
                                name: ROUTE.MESSAGES_BUTTONS
                            },
                            {
                                name: ROUTE.MESSAGES_DRAFTS
                            }
                        ]
                    },
                    {
                        name: ROUTE.EVENTS,
                        items: [
                            {
                                name: ROUTE.AUTO_ARCHIVED_CHATS
                            },
                            {
                                name: ROUTE.REMINDERS
                            }
                        ]
                    },
                    {
                        name: ROUTE.CHATS,
                        items: [
                            {
                                name: ROUTE.CHAT_ATTACHMENTS
                            },
                            {
                                name: ROUTE.CHAT_ACTIONS
                            },
                            {
                                name: ROUTE.CHAT_ADD_ACTION
                            },
                            {
                                name: ROUTE.CHAT_ADD_BUTTON
                            },
                            {
                                name: ROUTE.CHAT_ADD_TEMPLATE
                            },
                            {
                                name: ROUTE.CHAT_ADD_EMOJI
                            },
                            {
                                name: ROUTE.CHAT_NOTE
                            },
                            {
                                name: ROUTE.CHAT_EDIT_CLIENT_INFO
                            },
                            {
                                name: ROUTE.CHAT_HINTS
                            },
                            {
                                name: ROUTE.CHAT_DIALOG_SORT_AND_FILTER
                            },
                            {
                                name: ROUTE.CHAT_MESSAGE_EDIT_AND_DELETE
                            }
                        ]
                    }
                ]
            },
            {
                name: ROUTE.DIALOGS_SUPERVISORS,
                items: [
                    {
                        name: ROUTE.MAIN_DEFINITIONS,
                        items: [
                            {
                                name: ROUTE.DEFINITIONS
                            }
                        ]
                    },
                    {
                        name: ROUTE.DIALOGS_SEARCH,
                        items: [
                            {
                                name: ROUTE.VARIOUS_FILTERS_SEARCH
                            },
                            {
                                name: ROUTE.WORD_OR_PHRASE_SEARCH
                            }
                        ]
                    },
                    {
                        name: ROUTE.ACCOUNT_MANAGEMENT,
                        items: [
                            {
                                name: ROUTE.OPERATORS_TEAM
                            }
                        ]
                    }
                ]
            },
            {
                name: ROUTE.DIALOGS_ADMINISTRATORS,
                items: [
                    {
                        name: ROUTE.GROUP_CONFIGURATION,
                        items: [
                            {
                                name: ROUTE.CHAT_ARCHIVE
                            },
                            {
                                name: ROUTE.CHAT_VISIBILITY
                            },
                            {
                                name: ROUTE.AGENT_GROUPS
                            },
                            {
                                name: ROUTE.CHANNEL_PRIORITIZATION
                            },
                            {
                                name: ROUTE.CHAT_DISTRIBUTION
                            },
                            {
                                name: ROUTE.AGENT_STATUSES
                            },
                            {
                                name: ROUTE.DISTRIBUTION_TAGS
                            },
                            {
                                name: ROUTE.CHATBOT_ACTIONS
                            },
                            {
                                name: ROUTE.SESSIONS_TOPICS
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: ROUTE.ANALYTICS,
        items: [
            {
                name: ROUTE.ANALYTICS_OVERVIEW
            },
            {
                name: ROUTE.REPORTS,
                items: [
                    {
                        name: ROUTE.SESSIONS_OVERVIEW
                    },
                    {
                        name: ROUTE.MESSAGE_TRANSPORT_REPORT
                    },
                    {
                        name: ROUTE.OPERATOR_WORK
                    },
                    {
                        name: ROUTE.REPORTS_MEGABRAIN
                    },
                    {
                        name: ROUTE.INTEGRATIONS
                    },
                    {
                        name: ROUTE.TAG_CHANGES
                    },
                    {
                        name: ROUTE.CUSTOMER_SERVICE_ASSESSMENT
                    },
                    {
                        name: ROUTE.MY_REPORTS
                    },
                    {
                        name: ROUTE.REPORT_VALUES_DETAILIZATION
                    },
                    {
                        name: ROUTE.OTHER_REPORTS_OPTIONS
                    }
                ]
            },
            {
                name: ROUTE.TARGET_MONITORING,
                items: [
                    {
                        name: ROUTE.RULE_CREATION
                    }
                ]
            },
            {
                name: ROUTE.ANALYTICS_CONFIGURATION,
                items: [
                    {
                        name: ROUTE.REPORT_CONFIGURATOR
                    },
                    {
                        name: ROUTE.CHART_CONFIGURATION
                    },
                    {
                        name: ROUTE.REPORT_SCHEDULE
                    },
                    {
                        name: ROUTE.EVENT_MANAGEMENT
                    }
                ]
            }
        ]
    },
    {
        name: ROUTE.ACCOUNT,
        items: [
            {
                name: ROUTE.ACCOUNT_OVERVIEW
            },
            {
                name: ROUTE.DASHBOARD
            }
        ]
    },
    {
        name: ROUTE.SEARCH_SERVICE,
        items: [
            {
                name: ROUTE.SEARCH_SERVICE_OVERVIEW
            },
            {
                name: ROUTE.SEARCH_BY_USER_ID
            },
            {
                name: ROUTE.SEARCH_BY_PHONE_NUMBER
            }
        ]
    },
    {
        name: ROUTE.HELP,
        items: [
            {
                name: ROUTE.HELP_OVERVIEW
            }
        ]
    },
    {
        name: ROUTE.CONFIGURATION,
        items: [
            {
                name: ROUTE.CHANNELS,
                items: [
                    {
                        name: ROUTE.AVAILABLE_CHANNELS_LIST
                    }
                ]
            },
            {
                name: ROUTE.WIDGET_APPEARANCE,
                items: [
                    {
                        name: ROUTE.WHAT_IS_WIDGET
                    },
                    {
                        name: ROUTE.SINGLE_CHAT
                    },
                    {
                        name: ROUTE.BUILT_IN_CHAT
                    },
                    {
                        name: ROUTE.BUILT_IN_LINKER
                    },
                    {
                        name: ROUTE.CORNER_LINKER
                    }
                ]
            },
            {
                name: ROUTE.INTEGRATIONS_CONFIG,
                items: [
                    {
                        name: ROUTE.SERVER_URL_CONFIG_AND_WEBHOOK_EVENTS
                    }
                ]
            },
            {
                name: ROUTE.METADATA,
                items: [
                    {
                        name: ROUTE.METADATA_OVERVIEW
                    }
                ]
            }
        ]
    }
];

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SingleChat = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:singleChat');

    return (
        <Body>
            <Title>{t('hlp:singleChat')}</Title>
            <Header id="singleChat1">{t('hlp:helpPages.singleChat.singleChat1')}</Header>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat3')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat4')}</Paragraph>
            <PageVideo video={video8} />
            <Header id="singleChat5">{t('hlp:helpPages.singleChat.singleChat5')}</Header>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat6')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat7')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat8')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat9')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat10')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat11')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat12')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat13')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat14')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat15')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat16')}</ListItem>
            </List>
            <PageVideo video={video9} />
            <Header id="singleChat17">{t('hlp:helpPages.singleChat.singleChat17')}</Header>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat19')}</Paragraph>
            <PageVideo video={video10} />
            <Header id="singleChat20">{t('hlp:helpPages.singleChat.singleChat20')}</Header>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat21')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat22')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat23')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat24')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat25')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat26')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat27')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat28')}</ListItem>
            </List>
            <PageVideo video={video12} />
            <Header id="singleChat29">{t('hlp:helpPages.singleChat.singleChat29')}</Header>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat30')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat31')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat32')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat33')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat34')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat35')}</ListItem>
                <ListItem>{t('hlp:helpPages.singleChat.singleChat36')}</ListItem>
                <Paragraph>{t('hlp:helpPages.singleChat.singleChat37')}</Paragraph>
                <PageVideo video={video13} />
                <ListItem>{t('hlp:helpPages.singleChat.singleChat38')}</ListItem>
                <PageVideo video={video14} />
            </List>
            <Paragraph>{t('hlp:helpPages.singleChat.singleChat39')}</Paragraph>
            <PageVideo video={video15} />
        </Body>
    );
};

SingleChat.propTypes = {};

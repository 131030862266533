import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node } from 'prop-types';

export const ListItem = ({ children }) => {
    const css = useStyles(styles);

    return <li className={css('item')}>{children}</li>;
};

ListItem.propTypes = {
    children: node.isRequired
};

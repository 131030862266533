const addingMessagesItems = [
    {
        id: 'addingMessages1',
        title: 'helpPages.addingMessages.addingMessages1'
    },
    {
        id: 'addingMessages12',
        title: 'helpPages.addingMessages.addingMessages12'
    }
];

export default addingMessagesItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { PageTip } from '../../shared/tip';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MiddlewareBranches = ({ openMessageForm }) => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:middlewareBranches');

    return (
        <Body>
            <Title>{t('hlp:middlewareBranches')}</Title>
            <Header id="middlewareBranches1">
                {t('hlp:helpPages.middlewareBranches.middlewareBranches1')}
            </Header>
            <List>
                <ListItem>{t('hlp:helpPages.middlewareBranches.middlewareBranches2')}</ListItem>
                <ListItem>{t('hlp:helpPages.middlewareBranches.middlewareBranches3')}</ListItem>
                <ListItem>{t('hlp:helpPages.middlewareBranches.middlewareBranches4')}</ListItem>
            </List>
            <Header id="middlewareBranches5">
                {t('hlp:helpPages.middlewareBranches.middlewareBranches5')}
            </Header>
            <Paragraph id="middlewareBranches6">
                <Trans i18nKey="hlp:helpPages.middlewareBranches.middlewareBranches6">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video14} />
            <Paragraph id="middlewareBranches7">
                <Trans i18nKey="hlp:helpPages.middlewareBranches.middlewareBranches7">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video15} />
            <Paragraph id="middlewareBranches8">
                <Trans i18nKey="hlp:helpPages.middlewareBranches.middlewareBranches8">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <Paragraph id="middlewareBranches9">
                <Trans i18nKey="hlp:helpPages.middlewareBranches.middlewareBranches9">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video16} />
            <PageTip>
                <Paragraph id="middlewareBranches10">
                    <Trans i18nKey="hlp:helpPages.middlewareBranches.middlewareBranches10">
                        0<span className={css(['bold', 'form-link'])} onClick={openMessageForm} />
                    </Trans>
                </Paragraph>
            </PageTip>
        </Body>
    );
};

MiddlewareBranches.propTypes = { openMessageForm: func };

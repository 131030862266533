const chatNoteItems = [
    {
        id: 'chatNote1',
        title: 'helpPages.chatNote.chatNote1'
    },
    {
        id: 'chatNote5',
        title: 'helpPages.chatNote.chatNote5'
    }
];

export default chatNoteItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video9 from './assets/video9.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatVisibility = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatVisibility');

    return (
        <Body>
            <Title>{t('hlp:chatVisibility')}</Title>
            <Header id="chatVisibility1">{t('hlp:helpPages.chatVisibility.chatVisibility1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatVisibility.chatVisibility2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatVisibility.chatVisibility3')}</Paragraph>
            <PageVideo video={video9} />
            <Header id="chatVisibility4">{t('hlp:helpPages.chatVisibility.chatVisibility4')}</Header>
        </Body>
    );
};

ChatVisibility.propTypes = {};

const autoArchivedChatsItems = [
    {
        id: 'autoArchivedChats1',
        title: 'helpPages.autoArchivedChats.autoArchivedChats1'
    },
    {
        id: 'autoArchivedChats3',
        title: 'helpPages.autoArchivedChats.autoArchivedChats3'
    }
];

export default autoArchivedChatsItems;

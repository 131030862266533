import React, { useCallback, useState, useMemo, useRef, useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { func, number, object } from 'prop-types'
import {
  IconButton,
  Input,
  SvgBtDelete,
  SvgBtDrug,
  Dropdown,
  DropdownItem,
  ExpandButton,
  Select,
  FileItem
} from '@flomni/components'
import { SortableHandle } from 'react-sortable-hoc'
import { BLOCKS, ATTACHMENTS, URL, AUTH_KEY } from '../constants'
import axios from 'axios'
import { store } from '../../../state/state'
import { getLocalState, LOCAL_STORAGE } from '../localState'

const ContentBlock = ({
  block,
  index,
  onDelete,
  onUpdate
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showAttachmentList, setShowAttachmentList] = useState(false)
  const inputEl = useRef(null)
  const { dispatch } = useContext(store)
  const HEADERS = { headers: { Authorization: getLocalState(LOCAL_STORAGE, AUTH_KEY)?.token }}

  const DragHandle = SortableHandle(() => (
    <IconButton>
      <SvgBtDrug />
    </IconButton>
  ))

  const handlerOverlayClick = useCallback(() => {
    setShowAttachmentList(false)
  }, [])

  const blocksOptions = useMemo(() => {
    return BLOCKS.map((item) => ({label: t(`hlp:blocks.${item.kind}`), value: item.kind}))
  }, [])

  const typeBlock = useMemo(() => {
    return BLOCKS.find((item) => item.kind === block.kind) || {}
  }, [block])

  const onFileChange = useCallback(async (event) => {
    if (event.target.files.length) {
      dispatch({ type: 'setIsShowGlobalLoader', show: true })
      try {
        const uploadFile = await axios.post(URL.ATTACHMENTS, {}, HEADERS)
        await axios.put(uploadFile.data.uploadUrl, event.target.files[0], {headers: {
          'Content-Type': 'multipart/form-data'
        }})
        await axios.patch(`${URL.ATTACHMENTS}/${uploadFile.data.id}`, {state: 'uploaded'}, HEADERS)
        onUpdate(index, block.kind, uploadFile.data.url)
      } catch(e) {
          console.log('Upload file error: ', e)
      } finally {
          dispatch({ type: 'setIsShowGlobalLoader', show: false })
      }
    }
  }, [])

  return (
    <div className={css('item')}>
      <div className={css('item-header')}>
        <div className={css('title')}>
          <DragHandle />
          <div className={css('options')}>
            <Select
              value={blocksOptions.find((option) => option.value === block.kind) || null}
              onChange={(option) => onUpdate(index, option.value, block.content)}
              options={blocksOptions}
              bg='transparent'
            />
          </div>
        </div>
        <IconButton onClick={(e) => {
          e.preventDefault()
          onDelete(index)
        }}>
          <SvgBtDelete />
        </IconButton>
      </div>
      {typeBlock.content && <div className={css('content')}>
        <div>
          <div className={css('label')}>{t('hlp:content')}</div>
          <Input
            textArea
            placeholder={t('hlp:typeText')}
            classes={{ input: css('text') }}
            value={block.content}
            onChange={(e) => onUpdate(index, block.kind, e.target.value)}
          />
        </div>
      </div>}
      {typeBlock.attach &&
        <>
          <div className={css('attachments')}>
            {block.content && <FileItem
              name={block.content.split('/').reverse()[0]}
              onDelete={() => onUpdate(index, block.kind, '')}
              status='loaded'
            />}
          </div>
          {!block.content && <div className={css('add')}>
            <ExpandButton
              onClick={(e) => {
                e.preventDefault();
                inputEl.current.accept = block.kind === 'PageImage' ? 'image/*' : 'video/*'
                inputEl.current.click()
              }}
              size='small'
              view='plus'
            >
              {t('hlp:addMedia')}
            </ExpandButton>
            {showAttachmentList && <Dropdown classes={{ root: css('drop-down') }} active={true} onOverlayClick={handlerOverlayClick}>
              {ATTACHMENTS.map((item) =>
                <DropdownItem
                  key={item.kind}
                  classes={{ root: css('drop-down-item'), item: css('drop-down-content') }}
                  view='pure'
                  onClick={() => {
                    inputEl.current.accept = item.accept
                    inputEl.current.click()
                  }}
                >
                  {t(`hlp:attachment.${item.kind}`)}
                </DropdownItem>
              )}
            </Dropdown>}
          </div>}
        </>
      }
      <input ref={inputEl} type='file' multiple className={css('input')} onChange={onFileChange} />
    </div>
  )
}

ContentBlock.propTypes = {
  block: object,
  index: number,
  onDelete: func.isRequired,
  onUpdate: func.isRequired
}

export default ContentBlock

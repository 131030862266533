import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@flomni/components';
import { useHistory } from 'react-router';
import notFound from '../../assets/404.png';

export const NotFound = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={css('main')}>
            <div>
                <div className={css('header')}>{t('hlp:oops')}</div>
                <div className={css('description')}>{t('hlp:weCantFindPage')}</div>
                <Button view="success" classes={{ root: css('btn') }} onClick={() => history.push('/')}>
                    {t('hlp:goToHomepage')}
                </Button>
            </div>
            <img alt="" src={notFound} className={css('img')} />
        </div>
    );
};

const howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHoursItems = [
    {
        id: 'howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours1',
        title:
            'helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours1'
    },
    {
        id: 'howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours3',
        title:
            'helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours3'
    }
];

export default howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHoursItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TextAnalysisNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:textAnalysisNode');

    return (
        <Body>
            <Title>{t('hlp:textAnalysisNode')}</Title>
            <Header id="textAnalysisNode1">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.textAnalysisNode.textAnalysisNode2')}</ListItem>
            </List>
            <Header id="textAnalysisNode3">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode3')}</Header>
            <PageVideo video={video1} />
            <Header id="textAnalysisNode4">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode4')}</Header>
            <Header2 id="textAnalysisNode5">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode5')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode6">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header2 id="textAnalysisNode7">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode7')}</Header2>
            <Paragraph>{t('hlp:helpPages.textAnalysisNode.textAnalysisNode8')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.textAnalysisNode.textAnalysisNode9')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode10">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <Header id="textAnalysisNode11">{t('hlp:helpPages.textAnalysisNode.textAnalysisNode11')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode12">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode13">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode14">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.textAnalysisNode.textAnalysisNode15">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

TextAnalysisNode.propTypes = {};

const cornerLinkerItems = [
    {
        id: 'cornerLinker1',
        title: 'helpPages.cornerLinker.cornerLinker1'
    },
    {
        id: 'cornerLinker3',
        title: 'helpPages.cornerLinker.cornerLinker3'
    }
];

export default cornerLinkerItems;

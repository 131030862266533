import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { PageVideo } from '../../shared/video';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ReportsMegabrain = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:reportsMegabrain');

    return (
        <Body>
            <Title>{t('hlp:reportsMegabrain')}</Title>
            <Header id="reportsMegabrain1">{t('hlp:helpPages.reportsMegabrain.reportsMegabrain1')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.reportsMegabrain.reportsMegabrain2">
                    1<Link to={routes[ROUTE.MEGABRAIN]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
            <Header id="reportsMegabrain3">{t('hlp:helpPages.reportsMegabrain.reportsMegabrain3')}</Header>
            <Paragraph>{t('hlp:helpPages.reportsMegabrain.reportsMegabrain4')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.reportsMegabrain.reportsMegabrain5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.reportsMegabrain.reportsMegabrain6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.reportsMegabrain.reportsMegabrain7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.reportsMegabrain.reportsMegabrain8')}</Paragraph>
            <PageVideo video={video11} />
        </Body>
    );
};

ReportsMegabrain.propTypes = {};

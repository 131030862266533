const messagesDraftsItems = [
    {
        id: 'messagesDrafts1',
        title: 'helpPages.messagesDrafts.messagesDrafts1'
    },
    {
        id: 'messagesDrafts6',
        title: 'helpPages.messagesDrafts.messagesDrafts6'
    },
    {
        id: 'messagesDrafts9',
        title: 'helpPages.messagesDrafts.messagesDrafts9'
    },
    {
        id: 'messagesDrafts18',
        title: 'helpPages.messagesDrafts.messagesDrafts18'
    }
];

export default messagesDraftsItems;

const entityRecognitionItems = [
    {
        id: 'entityRecognition1',
        title: 'helpPages.entityRecognition.entityRecognition1'
    },
    {
        id: 'entityRecognition4',
        title: 'helpPages.entityRecognition.entityRecognition4'
    },
    {
        id: 'entityRecognition12',
        title: 'helpPages.entityRecognition.entityRecognition12'
    }
];

export default entityRecognitionItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AgentGroups = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:agentGroups')

    return (
        <Body>
            <Title>{t('hlp:agentGroups')}</Title>
            <Header id="agentGroups1">
                {t('hlp:helpPages.agentGroups.agentGroups1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups2')}</Paragraph>
            <Header id="agentGroups3">
                {t('hlp:helpPages.agentGroups.agentGroups3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups4')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups5')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups6')}</Paragraph>
                </ListItem>
                <PageVideo video={video11} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups7')}</Paragraph>
                </ListItem>
                <PageVideo video={video12} />
                <ListItem>
                    <Paragraph id="agentGroups8">
                        <Trans i18nKey="hlp:helpPages.agentGroups.agentGroups8">
                            <Link to={routes[ROUTE.DISTRIBUTION_TAGS]} className={css('link')}>1</Link>
                        </Trans>
                    </Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups9')}</Paragraph>
                </ListItem>
                <PageVideo video={video13} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups10')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups11')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups12')}</Paragraph>
                </ListItem>
                <PageVideo video={video14} />
            </List>
            <Header id="agentGroups13">
                {t('hlp:helpPages.agentGroups.agentGroups13')}
            </Header>
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups14')}</Paragraph>
            <PageVideo video={video15} />
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups15')}</Paragraph>
            <PageVideo video={video16} />
            <Header id="agentGroups16">
                {t('hlp:helpPages.agentGroups.agentGroups16')}
            </Header>
            <Paragraph>{t('hlp:helpPages.agentGroups.agentGroups17')}</Paragraph>
            <PageVideo video={video17} />
        </Body>
    );
};

AgentGroups.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { PageTip } from '../../shared/tip';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours');

    return (
        <Body>
            <Title>{t('hlp:howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours')}</Title>
            <Header id="howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours1">
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours1'
                )}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours2'
                    )}
                </ListItem>
            </List>
            <Header id="howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours3">
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours3'
                )}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours5">
                    0
                    <Link to={routes[ROUTE.CONDITION_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours6'
                )}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours7">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours8'
                )}
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours9">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours10'
                )}
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours11">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours12">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>5</span>4
                    <Link to={routes[ROUTE.CONDITION_NODE]} className={css('link')}>
                        5
                    </Link>
                    6
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours13">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <PageTip view="info">
                <div>
                    {t(
                        'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours14'
                    )}
                </div>
            </PageTip>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours15'
                )}
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours16">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours17'
                )}
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours18">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours19">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours20">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <PageTip view="info">
                <div>
                    {t(
                        'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours21'
                    )}
                </div>
            </PageTip>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours22'
                )}
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours23">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>3
                    <span className={css('bold')}>4</span>5<span className={css('bold')}>6</span>7
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours24'
                )}
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours25">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours26'
                )}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours27">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        5
                    </Link>
                    6<span className={css('bold')}>7</span>8<span className={css('bold')}>9</span>10
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours28">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        5
                    </Link>
                    6<span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
        </Body>
    );
};

HowToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours.propTypes = {};

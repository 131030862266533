const reportValuesDetailizationItems = [
    {
        id: 'reportValuesDetailization1',
        title: 'helpPages.reportValuesDetailization.reportValuesDetailization1'
    },
    {
        id: 'reportValuesDetailization3',
        title: 'helpPages.reportValuesDetailization.reportValuesDetailization3'
    }
];

export default reportValuesDetailizationItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Button } from '@flomni/components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ROUTE, routes } from '../../../configs/routes';
import { useHistory } from 'react-router';
import { HeaderSearch } from '../search';
import { getLocalState, LOCAL_STORAGE, removeLocalState } from '../../editor/localState'
import { AUTH_KEY } from '../../editor/constants'

export const DesktopHeader = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    const history = useHistory();
    const [showSearch, setShowSearch] = useState(false);
    const [personName, setPersonName] = useState('');
    const auth = getLocalState(LOCAL_STORAGE, AUTH_KEY)

    const getPersonNameFromCookie = () => {
        try {
            const parts = `; ${document.cookie}`.split(`; user=`);
            if (parts.length === 2) {
                const data = JSON.parse(parts.pop().split(';').shift());
                if (data && data.personName) {
                    return data.personName;
                }
            }
        } catch {}
        return null;
    };

    useEffect(() => {
        const personName = getPersonNameFromCookie();
        if (personName) {
            setPersonName(personName);
        }
        setShowSearch(history.location.pathname.indexOf(routes[ROUTE.TOPICS]) > -1 || history.location.pathname.indexOf(routes[ROUTE.PAGES]) > -1);
        const listener = history.listen((route) => {
            setShowSearch(route.pathname.indexOf(routes[ROUTE.TOPICS]) > -1 || history.location.pathname.indexOf(routes[ROUTE.PAGES]) > -1);
        });
        return () => {
            listener();
        };
    }, [history]);

    const openApp = () => {
        removeLocalState(LOCAL_STORAGE, AUTH_KEY);
        history.push(routes[ROUTE.LOGIN]);
    };

    return (
        <div className={css('header')}>
            <Link to="/">
                <div className={css('logo')} />
            </Link>
            {showSearch && <HeaderSearch />}
            <div>
                {auth?.login && (
                    <div className={css('profile')}>
                        <div className={css('user-logo')} />
                        <div className={css('info')}>
                            <div className={css('info-title')} onClick={openApp}>
                                {t('hlp:flomniAccount')}
                            </div>
                            <div className={css('info-name')}>{auth.login}</div>
                        </div>
                    </div>
                )}
                {!auth?.login && (
                    <Button view="primary" size="small" onClick={openApp}>
                        {t('hlp:login')}
                    </Button>
                )}
            </div>
        </div>
    );
};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video31 from './assets/video31.mov';
import video32 from './assets/video32.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const StoryTraining = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:storyTraining');

    return (
        <Body>
            <Title>{t('hlp:storyTraining')}</Title>
            <Header id="storyTraining1">{t('hlp:helpPages.storyTraining.storyTraining1')}</Header>
            <Paragraph>{t('hlp:helpPages.storyTraining.storyTraining2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.storyTraining.storyTraining3')}</Paragraph>
            <PageVideo video={video31} />
            <Paragraph>{t('hlp:helpPages.storyTraining.storyTraining4')}</Paragraph>
            <PageVideo video={video32} />
            <Paragraph>{t('hlp:helpPages.storyTraining.storyTraining5')}</Paragraph>
        </Body>
    );
};

StoryTraining.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const StoriesSub = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:storiesSub');

    return (
        <Body>
            <Title>{t('hlp:storiesSub')}</Title>
            <Header id="storiesSub1">{t('hlp:helpPages.storiesSub.storiesSub1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.storiesSub.storiesSub2')}</ListItem>
                <ListItem>{t('hlp:helpPages.storiesSub.storiesSub3')}</ListItem>
            </List>
            <Header id="storiesSub4">{t('hlp:helpPages.storiesSub.storiesSub4')}</Header>
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub5')}</Paragraph>
            <PageVideo video={video23} />
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub8')}</Paragraph>
            <PageVideo video={video24} />
            <Header id="storiesSub9">{t('hlp:helpPages.storiesSub.storiesSub9')}</Header>
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub10')}</Paragraph>
            <PageVideo video={video25} />
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub11')}</Paragraph>
            <PageVideo video={video26} />
            <Header id="storiesSub12">{t('hlp:helpPages.storiesSub.storiesSub12')}</Header>
            <Paragraph>{t('hlp:helpPages.storiesSub.storiesSub13')}</Paragraph>
            <PageVideo video={video27} />
        </Body>
    );
};

StoriesSub.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MetadataOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:metadataOverview');

    return (
        <Body>
            <Title>{t('hlp:metadataOverview')}</Title>
            <Header id="metadataOverview1">{t('hlp:helpPages.metadataOverview.metadataOverview1')}</Header>
            <Paragraph>{t('hlp:helpPages.metadataOverview.metadataOverview2')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="metadataOverview3">{t('hlp:helpPages.metadataOverview.metadataOverview3')}</Header>
            <Paragraph>{t('hlp:helpPages.metadataOverview.metadataOverview4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview5')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview6')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview7')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview8')}</ListItem>
            </List>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.metadataOverview.metadataOverview9">
                    0
                    <Link to={routes[ROUTE.DISTRIBUTION_TAGS]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.metadataOverview.metadataOverview10')}</Paragraph>
            <PageVideo video={video3} />
            <Header id="metadataOverview11">{t('hlp:helpPages.metadataOverview.metadataOverview11')}</Header>
            <Paragraph>{t('hlp:helpPages.metadataOverview.metadataOverview12')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview13')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview14')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview15')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview16')}</ListItem>
                <ListItem>{t('hlp:helpPages.metadataOverview.metadataOverview17')}</ListItem>
            </List>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.metadataOverview.metadataOverview18')}</Paragraph>
            <PageVideo video={video5} />
        </Body>
    );
};

MetadataOverview.propTypes = {};

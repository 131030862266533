const tagChangesItems = [
    {
        id: 'tagChanges1',
        title: 'helpPages.tagChanges.tagChanges1'
    },
    {
        id: 'tagChanges3',
        title: 'helpPages.tagChanges.tagChanges3'
    }
];

export default tagChangesItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatMessageEditAndDelete = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatMessageEditAndDelete');

    return (
        <Body>
            <Title>{t('hlp:chatMessageEditAndDelete')}</Title>
            <Header id="chatMessageEditAndDelete1">
                {t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete3')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete4')}</Paragraph>
            <PageVideo video={video22} />
            <Header id="chatMessageEditAndDelete5">
                {t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete5')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete7')}</Paragraph>
            <PageVideo video={video23} />
        </Body>
    );
};

ChatMessageEditAndDelete.propTypes = {};

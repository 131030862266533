const remindersItems = [
    {
        id: 'reminders1',
        title: 'helpPages.reminders.reminders1'
    },
    {
        id: 'reminders13',
        title: 'helpPages.reminders.reminders13'
    }
];

export default remindersItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatAddEmoji = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatAddEmoji')

    return (
        <Body>
            <Title>{t('hlp:chatAddEmoji')}</Title>
            <Header id="chatAddEmoji1">{t('hlp:helpPages.chatAddEmoji.chatAddEmoji1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji2')}</Paragraph>
            <PageVideo video={video16} />
            <Paragraph>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji3')}</Paragraph>
            <PageVideo video={video17} />
            <Paragraph>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji5')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji6')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji7')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji8')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji9')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji10')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji11')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji12')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatAddEmoji.chatAddEmoji13')}</ListItem>
            </List>
            <PageVideo video={video18} />
        </Body>
    );
};

ChatAddEmoji.propTypes = {};

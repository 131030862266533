const sessionsOverviewItems = [
    {
        id: 'sessionsOverview1',
        title: 'helpPages.sessionsOverview.sessionsOverview1'
    },
    {
        id: 'sessionsOverview3',
        title: 'helpPages.sessionsOverview.sessionsOverview3'
    }
];

export default sessionsOverviewItems;

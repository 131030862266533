import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToInsertAndApplyDataFromClientNotification = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToInsertAndApplyDataFromClientNotification');

    return (
        <Body>
            <Title>{t('hlp:howToInsertAndApplyDataFromClientNotification')}</Title>
            <Header id="howToInsertAndApplyDataFromClientNotification1">
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification1'
                )}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification2'
                    )}
                </ListItem>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification3'
                    )}
                </ListItem>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification4'
                    )}
                </ListItem>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification5'
                    )}
                </ListItem>
            </List>
            <Header id="howToInsertAndApplyDataFromClientNotification6">
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification6'
                )}
            </Header>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification7'
                )}
            </Paragraph>
            <code className={css('code')}>
                <pre>{`
"phoneNum": "790000000000",
"template":  {
    "name": "Text",
    "vars": {
        "Name": "Иван Иванович"
        "Date": "20.09.2021",
    },
    "meta": {
        "Name": "Иван Иванович",
        "Date": "20.09.2021",
    }
}
`}
                </pre>
            </code>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification9'
                )}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification10'
                )}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification11'
                )}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification12'
                )}
            </Paragraph>
            <Paragraph>
                {`${t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification13'
                )} @{{content.metaData.authPhone}}.`}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification14'
                )}
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification15">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification16'
                )}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification17'
                )}
                {`@{{content.metaData.templateName}}. `}
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification18'
                )}
                {`@{{content.metaData.templateName}}, `}
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification19'
                )}
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification20">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification21'
                )}
                {`@{{content.metaData.Name}}, @{{content.metaData.Date}}.`}
            </Paragraph>
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification22'
                )}
            </Paragraph>
            <PageVideo video={video3} />
            <PageVideo video={video4} />
            <Paragraph>
                {t(
                    'hlp:helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification23'
                )}
            </Paragraph>
        </Body>
    );
};

HowToInsertAndApplyDataFromClientNotification.propTypes = {};

const timeoutNodeItems = [
    {
        id: 'timeoutNode1',
        title: 'helpPages.timeoutNode.timeoutNode1'
    },
    {
        id: 'timeoutNode5',
        title: 'helpPages.timeoutNode.timeoutNode5'
    },
    {
        id: 'timeoutNode6',
        title: 'helpPages.timeoutNode.timeoutNode6'
    }
];

export default timeoutNodeItems;

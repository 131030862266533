import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video33 from './assets/video33.mov';
import video34 from './assets/video34.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const IncomingMessagesSet = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:incomingMessagesSet');

    return (
        <Body>
            <Title>{t('hlp:incomingMessagesSet')}</Title>
            <Header id="incomingMessagesSet1">
                {t('hlp:helpPages.incomingMessagesSet.incomingMessagesSet1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.incomingMessagesSet.incomingMessagesSet2')}</Paragraph>
            <PageVideo video={video33} />
            <Paragraph>{t('hlp:helpPages.incomingMessagesSet.incomingMessagesSet3')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.incomingMessagesSet.incomingMessagesSet4')}</Paragraph>
            <PageVideo video={video34} />
        </Body>
    );
};

IncomingMessagesSet.propTypes = {};

const stopWordsItems = [
    {
        id: 'stopWords1',
        title: 'helpPages.stopWords.stopWords1'
    },
    {
        id: 'stopWords4',
        title: 'helpPages.stopWords.stopWords4'
    },
    {
        id: 'stopWords7',
        title: 'helpPages.stopWords.stopWords7'
    }
];

export default stopWordsItems;

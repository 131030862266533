import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatNote = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatNote');

    return (
        <Body>
            <Title>{t('hlp:chatNote')}</Title>
            <Header id="chatNote1">{t('hlp:helpPages.chatNote.chatNote1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatNote.chatNote2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatNote.chatNote3')}</Paragraph>
            <PageVideo video={video19} />
            <Paragraph>{t('hlp:helpPages.chatNote.chatNote4')}</Paragraph>
            <PageVideo video={video20} />
            <Header id="chatNote5">{t('hlp:helpPages.chatNote.chatNote5')}</Header>
            <Paragraph>{t('hlp:helpPages.chatNote.chatNote6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatNote.chatNote7')}</Paragraph>
            <PageVideo video={video21} />
        </Body>
    );
};

ChatNote.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const WordOrPhraseSearch = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:wordOrPhraseSearch');

    return (
        <Body>
            <Title>{t('hlp:wordOrPhraseSearch')}</Title>
            <Header id="wordOrPhraseSearch1">
                {t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch3')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch4')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch5')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch6')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch7')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch8')}</Paragraph>
            <PageVideo video={video11} />
            <Header id="wordOrPhraseSearch9">
                {t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch9')}
            </Header>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch11')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch12')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch13')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch14')}</ListItem>
                <ListItem>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch15')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.wordOrPhraseSearch.wordOrPhraseSearch16')}</Paragraph>
            <PageVideo video={video12} />
        </Body>
    );
};

WordOrPhraseSearch.propTypes = {};

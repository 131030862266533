import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video41 from './assets/video41.mov';
import video42 from './assets/video42.mov';
import video43 from './assets/video43.mov';
import video44 from './assets/video44.mov';
import video45 from './assets/video45.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ReportValuesDetailization = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:reportValuesDetailization');

    return (
        <Body>
            <Title>{t('hlp:reportValuesDetailization')}</Title>
            <Header id="reportValuesDetailization1">
                {t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization2')}</Paragraph>
            <Header id="reportValuesDetailization3">
                {t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization5')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization6')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization7')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization8')}</Paragraph>
            <PageVideo video={video41} />
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization9')}</Paragraph>
            <PageVideo video={video42} />
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization10')}</Paragraph>
            <PageVideo video={video43} />
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization11')}</Paragraph>
            <PageVideo video={video44} />
            <Paragraph>{t('hlp:helpPages.reportValuesDetailization.reportValuesDetailization12')}</Paragraph>
            <PageVideo video={video45} />
        </Body>
    );
};

ReportValuesDetailization.propTypes = {};

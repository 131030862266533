const ruleCreationItems = [
    {
        id: 'ruleCreation1',
        title: 'helpPages.ruleCreation.ruleCreation1'
    },
    {
        id: 'ruleCreation4',
        title: 'helpPages.ruleCreation.ruleCreation4'
    },
    {
        id: 'ruleCreation16',
        title: 'helpPages.ruleCreation.ruleCreation16'
    },
    {
        id: 'ruleCreation19',
        title: 'helpPages.ruleCreation.ruleCreation19'
    }
];

export default ruleCreationItems;

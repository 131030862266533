import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatArchive = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatArchive')

    return (
        <Body>
            <Title>{t('hlp:chatArchive')}</Title>
            <Header id="chatArchive1">{t('hlp:helpPages.chatArchive.chatArchive1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive2')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="chatArchive3">{t('hlp:helpPages.chatArchive.chatArchive3')}</Header>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive5')}</Paragraph>
            <PageVideo video={video2} />
            <Header id="chatArchive6">{t('hlp:helpPages.chatArchive.chatArchive6')}</Header>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive7')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive8')}</ListItem>
                <PageVideo video={video3} />
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive9')}</ListItem>
                <PageVideo video={video4} />
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatArchive.chatArchive10">
                        0<Link to={routes[ROUTE.MESSAGES_DRAFTS]} className={css('link')}>1</Link>
                    </Trans>
                </ListItem>
                <PageVideo video={video5} />
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive11')}</ListItem>
                <PageVideo video={video6} />
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive12')}</ListItem>
                <PageVideo video={video7} />
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive13')}</ListItem>
            </List>
            <Header id="chatArchive14">{t('hlp:helpPages.chatArchive.chatArchive14')}</Header>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive15')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive16')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive17')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive18')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatArchive.chatArchive19')}</ListItem>
                <PageVideo video={video8} />
            </List>
            <Paragraph>{t('hlp:helpPages.chatArchive.chatArchive20')}</Paragraph>
        </Body>
    );
};

ChatArchive.propTypes = {};

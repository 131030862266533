const metadataOverviewItems = [
    {
        id: 'metadataOverview1',
        title: 'helpPages.metadataOverview.metadataOverview1'
    },
    {
        id: 'metadataOverview3',
        title: 'helpPages.metadataOverview.metadataOverview3'
    },
    {
        id: 'metadataOverview11',
        title: 'helpPages.metadataOverview.metadataOverview11'
    }
];

export default metadataOverviewItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { ROUTE, routes } from '../../../../configs/routes';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatAddTemplate = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatAddTemplate')

    return (
        <Body>
            <Title>{t('hlp:chatAddTemplate')}</Title>
            <Header id="chatAddTemplate1">{t('hlp:helpPages.chatAddTemplate.chatAddTemplate1')}</Header>
            <Paragraph id="chatAddTemplate2">
                <Trans i18nKey="hlp:helpPages.chatAddTemplate.chatAddTemplate2">
                    0<Link to={routes[ROUTE.MESSAGES_DRAFTS]} className={css('link')}/>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatAddTemplate.chatAddTemplate3')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatAddTemplate.chatAddTemplate4')}</ListItem>
                <PageVideo video={video14} />
                <ListItem>{t('hlp:helpPages.chatAddTemplate.chatAddTemplate5')}</ListItem>
                <PageVideo video={video15} />
            </List>
        </Body>
    );
};

ChatAddTemplate.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SessionsOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:sessionsOverview');

    return (
        <Body>
            <Title>{t('hlp:sessionsOverview')}</Title>
            <Header id="sessionsOverview1">{t('hlp:helpPages.sessionsOverview.sessionsOverview1')}</Header>
            <Paragraph>{t('hlp:helpPages.sessionsOverview.sessionsOverview2')}</Paragraph>
            <PageVideo video={video25} />
            <Header id="sessionsOverview3">{t('hlp:helpPages.sessionsOverview.sessionsOverview3')}</Header>
            <Paragraph>{t('hlp:helpPages.sessionsOverview.sessionsOverview4')}</Paragraph>
            <PageVideo video={video26} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.sessionsOverview.sessionsOverview5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.sessionsOverview.sessionsOverview6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.sessionsOverview.sessionsOverview7')}</Paragraph>
            <PageVideo video={video27} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.sessionsOverview.sessionsOverview8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video28} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.sessionsOverview.sessionsOverview9">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video29} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.sessionsOverview.sessionsOverview10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video30} />
        </Body>
    );
};

SessionsOverview.propTypes = {};

const chatDialogSortAndFilterItems = [
    {
        id: 'chatDialogSortAndFilter1',
        title: 'helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter1'
    },
    {
        id: 'chatDialogSortAndFilter10',
        title: 'helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter10'
    }
];

export default chatDialogSortAndFilterItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import video31 from './assets/video31.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessagesDrafts = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:messagesDrafts');

    return (
        <Body>
            <Title>{t('hlp:messagesDrafts')}</Title>
            <Header id="messagesDrafts1">{t('hlp:helpPages.messagesDrafts.messagesDrafts1')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts3')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts4')}</ListItem>
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts5')}</ListItem>
            </List>
            <Header id="messagesDrafts6">{t('hlp:helpPages.messagesDrafts.messagesDrafts6')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts7')}</Paragraph>
            <PageVideo video={video22} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messagesDrafts.messagesDrafts8">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <Header id="messagesDrafts9">{t('hlp:helpPages.messagesDrafts.messagesDrafts9')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts10')}</Paragraph>
            <PageVideo video={video23} />
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts11')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts12')}</ListItem>
                <PageVideo video={video24} />
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts13')}</ListItem>
                <PageVideo video={video25} />
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts14')}</ListItem>
                <PageVideo video={video26} />
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts15')}</ListItem>
                <PageVideo video={video27} />
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts16')}</ListItem>
                <PageVideo video={video28} />
                <ListItem>{t('hlp:helpPages.messagesDrafts.messagesDrafts17')}</ListItem>
                <PageVideo video={video29} />
            </List>
            <Header id="messagesDrafts18">{t('hlp:helpPages.messagesDrafts.messagesDrafts18')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts19')}</Paragraph>
            <PageVideo video={video30} />
            <Paragraph>{t('hlp:helpPages.messagesDrafts.messagesDrafts20')}</Paragraph>
            <PageVideo video={video31} />
        </Body>
    );
};

MessagesDrafts.propTypes = {};

const artificialIntelligenceItems = [
    {
        id: 'artificialIntelligence2',
        title: 'helpPages.artificialIntelligence.artificialIntelligence2'
    },
    {
        id: 'artificialIntelligence5',
        title: 'helpPages.artificialIntelligence.artificialIntelligence5'
    },
    {
        id: 'artificialIntelligence11',
        title: 'helpPages.artificialIntelligence.artificialIntelligence11'
    }
];

export default artificialIntelligenceItems;

const operatorsTeamItems = [
    {
        id: 'operatorsTeam1',
        title: 'helpPages.operatorsTeam.operatorsTeam1'
    },
    {
        id: 'operatorsTeam4',
        title: 'helpPages.operatorsTeam.operatorsTeam4'
    },
    {
        id: 'operatorsTeam10',
        title: 'helpPages.operatorsTeam.operatorsTeam10'
    },
];

export default operatorsTeamItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const WhatIsWidget = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:whatIsWidget');

    return (
        <Body>
            <Title>{t('hlp:whatIsWidget')}</Title>
            <Header id="whatIsWidget1">{t('hlp:helpPages.whatIsWidget.whatIsWidget1')}</Header>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget3')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget4')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget5')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget7')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video1} />
            <Header id="whatIsWidget8">{t('hlp:helpPages.whatIsWidget.whatIsWidget8')}</Header>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget9')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget10')}</Paragraph>
                </ListItem>
                <PageVideo video={video2} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget11')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget12')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget13')}</Paragraph>
                </ListItem>
                <PageVideo video={video3} />
            </List>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget14')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget15')}</Paragraph>
            <PageVideo video={video5} />
            <Header id="whatIsWidget16">{t('hlp:helpPages.whatIsWidget.whatIsWidget16')}</Header>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget17')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget19')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget20')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget21')}</Paragraph>
            <code className={css('code')}>
                <pre style={{ margin: 0 }}>{`<!-- Flomni widget -->
<script>
  window.flomniConfig = {
    clientKey: '1111111111111111111111',
  }
</script>
<script src="https://i.dev.flomni.com/chat.corner.js"></script>
<!-- Flomni widget -->`}</pre>
            </code>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget22')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget23')}</Paragraph>
            <Header id="whatIsWidget24">{t('hlp:helpPages.whatIsWidget.whatIsWidget24')}</Header>
            <Paragraph>{t('hlp:helpPages.whatIsWidget.whatIsWidget25')}</Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

WhatIsWidget.propTypes = {};

const messagesButtonsItems = [
    {
        id: 'messagesButtons1',
        title: 'helpPages.messagesButtons.messagesButtons1'
    },
    {
        id: 'messagesButtons7',
        title: 'helpPages.messagesButtons.messagesButtons7'
    },
    {
        id: 'messagesButtons13',
        title: 'helpPages.messagesButtons.messagesButtons13'
    }
];

export default messagesButtonsItems;

const conditionNodeItems = [
    {
        id: 'conditionNode1',
        title: 'helpPages.conditionNode.conditionNode1'
    },
    {
        id: 'conditionNode4',
        title: 'helpPages.conditionNode.conditionNode4'
    },
    {
        id: 'conditionNode5',
        title: 'helpPages.conditionNode.conditionNode5'
    }
];

export default conditionNodeItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node, bool } from 'prop-types';

export const List = ({ children, orderedList }) => {
    const css = useStyles(styles);
    if (orderedList) {
        return <ol className={css('items')}>{children}</ol>;
    }
    return <ul className={css('items')}>{children}</ul>;
};

List.propTypes = {
    children: node.isRequired,
    orderedList: bool
};

List.defaultProps = {
    orderedList: false
  };

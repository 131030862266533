import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToUseVariables = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToUseVariables');

    return (
        <Body>
            <Title>{t('hlp:howToUseVariables')}</Title>
            <Header id="howToUseVariables0">{t('hlp:helpPages.howToUseVariables.howToUseVariables0')}</Header>
            <Paragraph>{t('hlp:helpPages.howToUseVariables.howToUseVariables1')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables2">
                        0
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables3">
                        0
                        <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables4">
                        0
                        <Link to={routes[ROUTE.JS_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables5">
                        0
                        <Link to={routes[ROUTE.WEBHOOK_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables6">
                        0
                        <Link to={routes[ROUTE.CONDITION_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables7">
                        0
                        <Link to={routes[ROUTE.TEXT_ANALYSIS_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables8">
                        0
                        <Link to={routes[ROUTE.STORAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables9">
                        0
                        <Link to={routes[ROUTE.JSON_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.howToUseVariables.howToUseVariables10')}</Paragraph>
            <Header id="howToUseVariables11">
                {t('hlp:helpPages.howToUseVariables.howToUseVariables11')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables12">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Header id="howToUseVariables13">
                {t('hlp:helpPages.howToUseVariables.howToUseVariables13')}
            </Header>
            <Paragraph>{t('hlp:helpPages.howToUseVariables.howToUseVariables14')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseVariables.howToUseVariables15">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
        </Body>
    );
};

HowToUseVariables.propTypes = {};

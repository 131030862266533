import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const RecognitionNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:recognitionNode');

    return (
        <Body>
            <Title>{t('hlp:recognitionNode')}</Title>
            <Header id="recognitionNode1">{t('hlp:helpPages.recognitionNode.recognitionNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.recognitionNode.recognitionNode2')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode3">
                        0
                        <Link to={routes[ROUTE.TEXT_ANALYSIS_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
            </List>
            <PageTip view="info">
                <div>
                    <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode4">
                        0
                        <Link to={routes[ROUTE.MEGABRAIN_OVERVIEW]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </div>
            </PageTip>
            <Header id="recognitionNode5">{t('hlp:helpPages.recognitionNode.recognitionNode5')}</Header>
            <PageVideo video={video1} />
            <Header id="recognitionNode6">{t('hlp:helpPages.recognitionNode.recognitionNode6')}</Header>
            <Header2 id="recognitionNode7">{t('hlp:helpPages.recognitionNode.recognitionNode7')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode8">
                    0
                    <Link to={routes[ROUTE.STORIES_SUB]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header2 id="recognitionNode9">{t('hlp:helpPages.recognitionNode.recognitionNode9')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode10">
                    0
                    <Link to={routes[ROUTE.CONTEXTS]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Header2 id="recognitionNode11">{t('hlp:helpPages.recognitionNode.recognitionNode11')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode12">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.recognitionNode.recognitionNode13')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode14">
                    0
                    <Link to={routes[ROUTE.INCOMING_MESSAGES_SET]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Header2 id="recognitionNode15">{t('hlp:helpPages.recognitionNode.recognitionNode15')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.recognitionNode.recognitionNode16">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Header2 id="recognitionNode17">{t('hlp:helpPages.recognitionNode.recognitionNode17')}</Header2>
            <Paragraph>{t('hlp:helpPages.recognitionNode.recognitionNode18')}</Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

RecognitionNode.propTypes = {};

const howToUseJsWithBots = [
    {
        id: 'howToUseJsWithBots1',
        title: 'helpPages.howToUseJsWithBots.howToUseJsWithBots1'
    },
    {
        id: 'howToUseJsWithBots5',
        title: 'helpPages.howToUseJsWithBots.howToUseJsWithBots5'
    }
];

export default howToUseJsWithBots;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { any, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@flomni/components';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../configs/routes';
import { getFormattedText } from '../../services/search';

export const SearchPageResults = ({ searchContainer, result, clearResults }) => {
    const css = useStyles(styles);
    const { t } = useTranslation();

    let width = 0;
    if (searchContainer) {
        const rect = searchContainer.current.getBoundingClientRect();
        if (rect) {
            width = rect.width;
        }
    }

    return (
        <Dropdown
            view="arrowless"
            classes={{ root: css('dropdown') }}
            active={true}
            onOverlayClick={clearResults}
        >
            <div className={css('wrap')} style={{ width: width ? width + 'px' : null }}>
                {result.items.slice(0, 3).map((item, index) => (
                    <Link
                        onClick={clearResults}
                        to={`/pages/${item.url}`}
                        key={index}
                        className={css('dropdown-item')}
                        dangerouslySetInnerHTML={{
                            __html: getFormattedText(
                                item.title,
                                item.indexes,
                                css('highlight')
                            )
                        }}
                    />
                ))}
                <Link
                    className={css(['dropdown-item', 'highlight'])}
                    to={`${routes[ROUTE.SEARCH]}?key=${result.key}`}
                >
                    {t('hlp:searchForKey', { key: result.key })}
                </Link>
                {result.items.length > 3 && (
                    <Link
                        className={css(['dropdown-item', 'highlight', 'group'])}
                        to={`${routes[ROUTE.SEARCH]}?key=${result.key}`}
                    >
                        <span>{t('hlp:see3MoreResults')}</span>
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.00002 11.2917L11.0001 7.29171L7.00002 3.29167"
                                stroke="#4F4F4F"
                                strokeWidth="1.60002"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.0001 7.29175L2.99998 7.29175"
                                stroke="#4F4F4F"
                                strokeWidth="1.60002"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Link>
                )}
            </div>
        </Dropdown>
    );
};

SearchPageResults.propTypes = {
    result: object.isRequired,
    clearResults: func.isRequired,
    searchContainer: any
};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToAddSearchableDataToUsersPofile = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToAddSearchableDataToUsersPofile');

    return (
        <Body>
            <Title>{t('hlp:howToAddSearchableDataToUsersPofile')}</Title>
            <Header id="howToAddSearchableDataToUsersPofile1">
                {t('hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile1')}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile2'
                    )}
                </ListItem>
            </List>
            <Header id="howToAddSearchableDataToUsersPofile3">
                {t('hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile5">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile6">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile7">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4<span className={css('bold')}>5</span>6
                    <span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile8">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                    4<span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile9">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
        </Body>
    );
};

HowToAddSearchableDataToUsersPofile.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node, string } from 'prop-types';
import { Tip } from '@flomni/components';

export const PageTip = ({ children, view }) => {
    const css = useStyles(styles);
    return (
        <Tip size="small" view={view} classes={{ root: css('tip'), content: css('content') }}>
            {children}
        </Tip>
    );
};

PageTip.propTypes = {
    children: node.isRequired,
    view: string
};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const StorageNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:storageNode');

    return (
        <Body>
            <Title>{t('hlp:storageNode')}</Title>
            <Header id="storageNode1">{t('hlp:helpPages.storageNode.storageNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.storageNode.storageNode2')}</ListItem>
            </List>
            <Header id="storageNode3">{t('hlp:helpPages.storageNode.storageNode3')}</Header>
            <PageVideo video={video1} />
            <Header id="storageNode4">{t('hlp:helpPages.storageNode.storageNode4')}</Header>
            <Header2 id="storageNode5">{t('hlp:helpPages.storageNode.storageNode5')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode6">
                    0
                    <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header2 id="storageNode7">{t('hlp:helpPages.storageNode.storageNode7')}</Header2>
            <Paragraph>{t('hlp:helpPages.storageNode.storageNode8')}</Paragraph>
            <PageVideo video={video3} />
            <Header2 id="storageNode9">{t('hlp:helpPages.storageNode.storageNode9')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode10">
                    0<span className={css('bold')}>1</span>2
                    <Link to={routes[ROUTE.SYSTEM_VARIABLES]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <PageTip>{t('hlp:helpPages.storageNode.storageNode11')}</PageTip>
            <Header id="storageNode12">{t('hlp:helpPages.storageNode.storageNode12')}</Header>
            <Header2 id="storageNode13">{t('hlp:helpPages.storageNode.storageNode13')}</Header2>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode14">
                    0
                    <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode15">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode16">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode17">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode18">
                    0<span className={css('bold')}>1</span>2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <Header2 id="storageNode19">{t('hlp:helpPages.storageNode.storageNode19')}</Header2>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode20">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                        5
                    </Link>
                    6<span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.storageNode.storageNode21">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode22">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode23">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.storageNode.storageNode24">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.storageNode.storageNode25')}</Paragraph>
        </Body>
    );
};

StorageNode.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { array, object, string } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import useStateRef from '../../../hooks/useStateRef';

export const StickyMenu = ({ items, container, mainClass }) => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    const [selected, setSelected] = useState(null);
    const [nodes, setNodes] = useState({});
    const [, setItemsIds, itemsIdsRef] = useStateRef(null);
    const [, setMenuContainer, menuContainerRef] = useStateRef(null);
    const [menuFixedWidth, setIsMenuFixedWidth] = useState(null);

    const getElement = useCallback(
        (id) => {
            return nodes[id] || document.getElementById(id);
        },
        [nodes]
    );

    const onItemClick = useCallback(
        (id) => {
            const el = getElement(id);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        },
        [getElement]
    );

    const isElementInViewport = (el) => {
        if (el) {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
        return false;
    };

    const onScroll = useCallback(() => {
        const rect = menuContainerRef.current.getBoundingClientRect();
        setIsMenuFixedWidth(rect.top < 25 ? rect.width : null);

        const itemIdInViewPort = itemsIdsRef.current.find((id) => isElementInViewport(getElement(id)));
        if (itemIdInViewPort) {
            setSelected(itemIdInViewPort);
        }
    }, [getElement, itemsIdsRef, menuContainerRef]);

    useEffect(() => {
        const elNodes = {};
        const ids = [];
        items.forEach((item) => {
            const el = document.getElementById(item.id);
            if (el) {
                elNodes[item.id] = el;
            }
            ids.push(item.id);
        });
        setNodes(elNodes);
        setItemsIds(ids);
        setSelected(items.length ? items[0].id : null);
    }, [items, setItemsIds]);

    useEffect(() => {
        onItemClick(new URL(window.location).searchParams.get('ref'));
        container.addEventListener('scroll', onScroll);
        return () => {
            container.removeEventListener('scroll', onScroll);
        };
    }, [container, onScroll, onItemClick]);

    return (
        <div className={mainClass} ref={(node) => setMenuContainer(node)}>
            <div
                className={css(menuFixedWidth ? 'main-fixed' : null)}
                style={{ width: menuFixedWidth ? menuFixedWidth + 'px' : '100%' }}
            >
                <div className={css('header')}>{t('hlp:contents')}</div>
                <div>
                    {items.map((item, index) => (
                        <div
                            key={item.id}
                            className={css(['item', selected === item.id ? '--active' : null])}
                            onClick={() => onItemClick(item.id)}
                        >
                            {t(`hlp:${item.title}`)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

StickyMenu.propTypes = {
    items: array.isRequired,
    container: object.isRequired,
    mainClass: string
};

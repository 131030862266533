const noteNodeItems = [
    {
        id: 'noteNode1',
        title: 'helpPages.noteNode.noteNode1'
    },
    {
        id: 'noteNode3',
        title: 'helpPages.noteNode.noteNode3'
    },
    {
        id: 'noteNode4',
        title: 'helpPages.noteNode.noteNode4'
    }
];

export default noteNodeItems;

import React, {memo, useCallback, useState} from 'react'
import { func, object, string } from 'prop-types'
import {Button, IconButton, Popup, PopupContent, PopupTitle, SvgBtDelete, SvgBtPlus, PopupButtons} from '@flomni/components'
import { SvgBtEdit } from '@flomni/components'
import { useTranslation } from 'react-i18next'
import cn from './index.module.scss'
import { SvgBtDrug, SvgBtRightChevron } from '@flomni/components'
import { EmptyDropZone } from '../empty-drop-zone'
import { KIND } from '../constants'
import { SortableHandle } from 'react-sortable-hoc'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import arrayMove from '../utils'
import { ReactSortable } from 'react-sortablejs'

export const GroupBlock = memo((props) => {
  const { group, groups, ln, onEdit, onDelete, setCurrentGroup, menuOptions, setEditPage, pages, saveMenu } = props
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showConfirmDeleteGroup, setShowConfirmDeleteGroup] = useState(false)

  const handlerClickItem = useCallback((item) => {
    switch (item.kind) {
      case KIND.GROUP:
        setCurrentGroup(menuOptions.find((option) => option.item.id === item.id))
        break
      default:
        setEditPage({folder: group, page: group ? pages.find((page) => page.id === item.pageId) : item})
    }
  }, [menuOptions, pages])

  const DragHandle = SortableHandle(() => (
    <IconButton>
      <SvgBtDrug className={cn.icon} />
    </IconButton>
  ))

  const onEnd = useCallback((evt) => {
    const groupId = evt.to.parentElement.dataset.id
    if (group) {
      if (group?.items[evt.oldIndex].kind === KIND.GROUP && !groupId) {
        return
      }
      if (!groupId) {
        group.items.splice(evt.oldIndex, 1)
      } else {
        if (groupId !== group.id) {
          groups.find((item) => item.id === groupId).items.splice(evt.newIndex, 0, group.items[evt.oldIndex])
          group.items.splice(evt.oldIndex, 1)
        } else {
          group.items = arrayMove(group.items, evt.oldIndex, evt.newIndex)
        }
      }
    } else {
      if (!groupId) {
        return
      }
      groups.find((item) => item.id === groupId).items.splice(evt.newIndex, 0, {
        title: {
          [ln]: pages[evt.oldIndex].metadata[ln].title,
        },
        kind: KIND.PAGE_LINK,
        pageId: pages[evt.oldIndex].id
      })
    }
    saveMenu()
  }, [pages, group, groups])

  return (
    <div className={cn.root}>
      <div className={cn.header}>
        <div className={cn.title}>
          <span>{group?.title ? group.title[ln] : t('hlp:withoutGroup')}</span>
          <span className={cn.buttons}>
            <IconButton onClick={() => setEditPage({isNew: true, folder: group})}>
              <SvgBtPlus />
            </IconButton>
            {!!onEdit && <IconButton onClick={() => onEdit()}>
              <SvgBtEdit />
            </IconButton>}
            {!!onEdit && <IconButton onClick={() => setShowConfirmDeleteGroup(true)}>
              <SvgBtDelete />
            </IconButton>}
          </span>
        </div>
        {!!group?.slug && <div className={cn.info}>
          <span>{group.slug}</span>
          <DragHandle />
        </div>}
      </div>
      <div data-id={group?.id}>
        <ReactSortable list={group?.items || pages || []} setList={() => {}} group='shared-group-name' onEnd={onEnd}>
          <>
            {!group && pages?.map((item) => (
              <div className={cn['sub-item']} key={item.id} onClick={() => handlerClickItem(item)}>
                <div className={cn['sub-wrapper']}>
                  <div className={cn['sub-icon']}>
                    <SvgBtDrug />
                  </div>
                  <div className={cn['sub-title-page']}>
                    {item.metadata[ln]?.title}
                  </div>
                </div>
                <div className={cn['sub-slug']}>
                  {item.slug}
                </div>
              </div>
            ))}

            {group?.items.map((subItem) => (
              <div className={cn['sub-item']} key={subItem.id || subItem.pageId} onClick={() => handlerClickItem(subItem)}>
                <div className={cn['sub-wrapper']}>
                  <div className={cn['sub-icon']}>
                    {subItem.kind === KIND.GROUP ? <SvgBtRightChevron className={cn.iconPage} /> : <SvgBtDrug />}
                  </div>
                  <div className={subItem.kind === KIND.GROUP ? cn['sub-title'] : cn['sub-title-page']}>
                    {subItem.title[ln]}
                  </div>
                </div>
                <div className={cn['sub-slug']}>
                  {subItem.slug}
                </div>
              </div>
            ))}
          </>
        </ReactSortable>
      </div>
      {!group?.items?.length && (!pages?.length || group) && <EmptyDropZone />}
      {showConfirmDeleteGroup && (
        <Popup open={true}>
          <PopupTitle view="warning">{t('hlp:deletePage')}</PopupTitle>
          <PopupContent classes={{ root: css`confirm-content` }}>
            {t('hlp:deleteGroupDescription')}
          </PopupContent>
          <PopupButtons>
            <Button view="warning" size="medium" onClick={() => {
              onDelete()
              setShowConfirmDeleteGroup(false)
            }}>
              {t('hlp:delete')}
            </Button>
            <Button view="cancel" size="medium" onClick={() => setShowConfirmDeleteGroup(false)}>
              {t('hlp:cancel')}
            </Button>
          </PopupButtons>
        </Popup>
      )}
    </div>
  )
})

GroupBlock.propTypes = {
  group: object,
  ln: string.isRequired,
  onEdit: func
}

GroupBlock.defaultProps = {}

const jsonNodeItems = [
    {
        id: 'jsonNode1',
        title: 'helpPages.jsonNode.jsonNode1'
    },
    {
        id: 'jsonNode3',
        title: 'helpPages.jsonNode.jsonNode3'
    },
    {
        id: 'jsonNode4',
        title: 'helpPages.jsonNode.jsonNode4'
    }
];

export default jsonNodeItems;

const chatbotPresetsItems = [
    {
        id: 'chatbotThreads1',
        title: 'helpPages.chatbotPresets.chatbotPresets1'
    },
    {
        id: 'chatbotPresets4',
        title: 'helpPages.chatbotPresets.chatbotPresets4'
    }
];

export default chatbotPresetsItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { string } from 'prop-types';
import { useState } from 'react';

export const PageImage = ({ image, width }) => {
    const css = useStyles(styles);
    const [showStub, setShowStub] = useState(true);

    const handleImageLoaded = () => {
        setShowStub(false);
    };

    return (
        <div className={css(['img', showStub ? '--stub' : null])} style={{ width }}>
            {image && <img src={image} alt={image} style={{ width }} onLoad={handleImageLoaded} />}
        </div>
    );
};

PageImage.propTypes = {
    image: string.isRequired,
    width: string
};

export const URL = {
    MENU: `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/editor/menu`,
    PAGES: `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/editor/pages`,
    ATTACHMENTS: `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/editor/attachments`,
    PAGE_BY_URL: (encodedUrl, language) => `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/public/pages/by-url/${encodedUrl}/${language}/`,
    PUBLIC_MENU: `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/public/menu`,
    SEARCH: `https://${process.env.REACT_APP_EDITOR_BACK_HOST}/public/pages/search`,
}

export const AUTH_KEY = 'auth'

export const KIND = {
  GROUP: 'group',
  PAGE_LINK: 'pageLink'
}

export const LANGUAGES = [
  {label: 'Русский', value: 'ru'},
  {label: 'English', value: 'en'}
]

export const BLOCKS = [
  {kind: 'Header', content: true},
  {kind: 'Header2', content: true},
  {kind: 'Paragraph', content: true},
  {kind: 'PageTip', content: true},
  {kind: 'PageImage', attach: true},
  {kind: 'PageVideo', attach: true}
]

export const ATTACHMENTS = [{kind: 'image', accept: 'image/*'}, {kind: 'video', accept: 'video/*'}]

export const BODY = 'Body'
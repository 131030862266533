const trainingSetCreationItems = [
    {
        id: 'trainingSetCreation1',
        title: 'helpPages.trainingSetCreation.trainingSetCreation1'
    },
    {
        id: 'trainingSetCreation3',
        title: 'helpPages.trainingSetCreation.trainingSetCreation3'
    },
    {
        id: 'trainingSetCreation5',
        title: 'helpPages.trainingSetCreation.trainingSetCreation5'
    },
    {
        id: 'trainingSetCreation8',
        title: 'helpPages.trainingSetCreation.trainingSetCreation8'
    },
];

export default trainingSetCreationItems;

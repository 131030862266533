const tagNodeItems = [
    {
        id: 'tagNode1',
        title: 'helpPages.tagNode.tagNode1'
    },
    {
        id: 'tagNode4',
        title: 'helpPages.tagNode.tagNode4'
    },
    {
        id: 'tagNode5',
        title: 'helpPages.tagNode.tagNode5'
    }
];

export default tagNodeItems;

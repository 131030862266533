import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Menu } from '../../menu';
import { Redirect, Route, Switch } from 'react-router';
import { ROUTE, routes } from '../../../configs/routes';
import { WhatIsFlomni } from '../content/what-is-flomni';
import { FirstChatbot } from '../content/first-chatbot';
import { ChatbotReleases } from '../content/chatbot-releases';
import { FirstStepsWithNodes } from '../content/first-steps-with-nodes';
import { ChatbotBuilderOverview } from '../content/chatbot-builder-overview';
import { ChatManagementNode } from '../content/chat-management-node';
import { WebhookNode } from '../content/webhook-node';
import { JsNode } from '../content/js-node';
import { MessageNode } from '../content/message-node';
import { EnterPointNode } from '../content/enter-point-node';
import { ExitPointNode } from '../content/exit-point-node';
import { StorageNode } from '../content/storage-node';
import { TagNode } from '../content/tag-node';
import { JsonNode } from '../content/json-node';
import { NoteNode } from '../content/note-node';
import { TextAnalysisNode } from '../content/text-analysis-node';
import { RecognitionNode } from '../content/recognition-node';
import { RandomizerNode } from '../content/randomizer-node';
import { TimeoutNode } from '../content/timeout-node';
import { ConditionNode } from '../content/condition-node';
import { ActionChainNode } from '../content/action-chain-node';
import { AnalyticsOverview } from '../content/analytics-overview';
import { HowToConnectAgentToChatAndDisableChatbot } from '../content/how-to-connect-agent-to-chat-and-disable-chatbot';
import { HowToStoreUserPhoneNumberIntoUserProfile } from '../content/how-to-store-user-phone-number-into-user-profile';
import { useState } from 'react';
import FormMessage from '../shared/form-message';
import { object } from 'prop-types';
import { StickyMenu } from '../sticky-menu';
import firstChatbotItems from '../content/first-chatbot/navigation-items';
import chatbotReleasesItems from '../content/chatbot-releases/navigation-items';
import firstStepsWithNodesItems from '../content/first-steps-with-nodes/navigation-items';
import chatbotBuilderOverviewItems from '../content/chatbot-builder-overview/navigation-items';
import chatManagementNodeItems from '../content/chat-management-node/navigation-items';
import jsNodeItems from '../content/js-node/navigation-items';
import webhookNodeItems from '../content/webhook-node/navigation-items';
import messageNodeItems from '../content/message-node/navigation-items';
import enterPointNodeItems from '../content/enter-point-node/navigation-items';
import exitPointNodeItems from '../content/exit-point-node/navigation-items';
import storageNodeItems from '../content/storage-node/navigation-items';
import tagNodeItems from '../content/tag-node/navigation-items';
import jsonNodeItems from '../content/json-node/navigation-items';
import noteNodeItems from '../content/note-node/navigation-items';
import textAnalysisNodeItems from '../content/text-analysis-node/navigation-items';
import recognitionNodeItems from '../content/recognition-node/navigation-items';
import randomizerNodeItems from '../content/randomizer-node/navigation-items';
import timeoutNodeItems from '../content/timeout-node/navigation-items';
import conditionNodeItems from '../content/condition-node/navigation-items';
import actionChainNodeItems from '../content/action-chain-node/navigation-items';
import analyticsOverviewItems from '../content/analytics-overview/navigation-items';
import howToConnectAgentToChatAndDisableChatbotItems from '../content/how-to-connect-agent-to-chat-and-disable-chatbot/navigation-items';
import howToStoreUserPhoneNumberIntoUserProfileItems from '../content/how-to-store-user-phone-number-into-user-profile/navigation-items';
import { HowToValidateUserTypedText } from '../content/how-to-validate-user-typed-text';
import howToValidateUserTypedTextItems from '../content/how-to-validate-user-typed-text/navigation-items';
import { HowToPreventTextEntry } from '../content/how-to-prevent-text-entry';
import howToPreventTextEntryItems from '../content/how-to-prevent-text-entry/navigation-items';
import { HowToAddSearchableDataToUsersPofile } from '../content/how-to-add-searchable-data-to-users-pofile';
import howToAddSearchableDataToUsersPofileItems from '../content/how-to-add-searchable-data-to-users-pofile/navigation-items';
import { HowToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours } from '../content/how-to-create-different-chatbot-welcome-messages-depends-on-work-time-hours';
import howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHoursItems from '../content/how-to-create-different-chatbot-welcome-messages-depends-on-work-time-hours/navigation-items';
import { HowToMakeRequestToExternalSystemsUsingApi } from '../content/how-to-make-request-to-external-systems-using-api';
import howToMakeRequestToExternalSystemsUsingApiItems from '../content/how-to-make-request-to-external-systems-using-api/navigation-items';
import { HowToCreateVariables } from '../content/how-to-create-variables';
import howToCreateVariablesItems from '../content/how-to-create-variables/navigation-items';
import { HowToUseVariables } from '../content/how-to-use-variables';
import howToUseVariablesItems from '../content/how-to-use-variables/navigation-items';
import { SystemVariables } from '../content/system-variables';
import systemVariablesItems from '../content/system-variables/navigation-items';
import { InstallChatbotOnYourWebsite } from '../content/install-chatbot-on-your-website';
import installChatbotOnYourWebsiteItems from '../content/install-chatbot-on-your-website/navigation-items';
import { HowToChooseVariableFromArray } from '../content/how-to-choose-variable-from-array';
import howToChooseVariableFromArrayItems from '../content/how-to-choose-variable-from-array/navigation-items';
import { HowToFindWordOrPhraseInUsersMessage } from '../content/how-to-find-word-or-phrase-in-users-message';
import howToFindWordOrPhraseInUsersMessageItems from '../content/how-to-find-word-or-phrase-in-users-message/navigation-items';
import { HowToUseJsWithBots } from '../content/how-to-use-js-with-bots';
import HowToUseJsWithBotsItems from '../content/how-to-use-js-with-bots/navigation-items';
import { HowToInsertAndApplyDataFromClientNotification } from '../content/how-to-insert-and-apply-data-from-client-notification';
import HowToInsertAndApplyDataFromClientNotificationItems from '../content/how-to-insert-and-apply-data-from-client-notification/navigation-items';
import { HowToPersonalizeBotMenuInViber } from '../content/how-to-personalize-bot-menu-in-viber';
import HowToPersonalizeBotMenuInViberItems from '../content/how-to-personalize-bot-menu-in-viber/navigation-items';
import { ArtificialIntelligence } from '../content/artificial-intelligence';
import artificialIntelligenceItems from '../content/artificial-intelligence/navigation-items';
import { ChatbotBranchVersionHistory } from '../content/chatbot-branch-version-history';
import chatbotBranchVersionHistoryItems from '../content/chatbot-branch-version-history/navigation-items';
import { ChatbotLogs } from '../content/chatbot-logs';
import chatbotLogsItems from '../content/chatbot-logs/navigation-items';
import { MultibranchChatbot } from '../content/multibranch-chatbot';
import multibranchChatbotItems from '../content/multibranch-chatbot/navigation-items';
import { MiddlewareBranches } from '../content/middleware-branches';
import middlewareBranchesItems from '../content/middleware-branches/navigation-items';
import { ChatbotDraftOverview } from '../content/chatbot-draft-overview';
import chatbotDraftOverviewItems from '../content/chatbot-draft-overview/navigation-items';
import { ChatbotDraftDebugMode } from '../content/chatbot-draft-debug-mode';
import chatbotDraftDebugModeItems from '../content/chatbot-draft-debug-mode/navigation-items';
import { ChatbotErrors } from '../content/chatbot-errors';
import chatbotErrorsItems from '../content/chatbot-errors/navigation-items';
import { ChatbotThreads } from '../content/chatbot-threads';
import chatbotThreadsItems from '../content/chatbot-threads/navigation-items';
import { ChatbotPresets } from '../content/chatbot-presets';
import chatbotPresetsItems from '../content/chatbot-presets/navigation-items';
import { AccountOverview } from '../content/account-overview';
import accountOverviewItems from '../content/account-overview/navigation-items';
import { DialogsOverview } from '../content/dialogs-overview';
import dialogsOverviewItems from '../content/dialogs-overview/navigation-items';
import { MessageTransportOverview } from '../content/message-transport-overview';
import messageTransportOverviewItems from '../content/message-transport-overview/navigation-items';
import { TeamAnalytics } from '../content/team-analytics';
import teamAnalyticsItems from '../content/team-analytics/navigation-items';
import { GeneralStatistics } from '../content/general-statistics';
import generalStatisticsItems from '../content/general-statistics/navigation-items';
import { ProfileData } from '../content/profile-data';
import profileDataItems from '../content/profile-data/navigation-items';
import { Notifications } from '../content/notifications';
import notificationsItems from '../content/notifications/navigation-items';
import { Diagnostics } from '../content/diagnostics';
import diagnosticsItems from '../content/diagnostics/navigation-items';
import { AccountConfiguration } from '../content/account-configuration';
import accountConfigurationItems from '../content/account-configuration/navigation-items';
import { MessagesButtons } from '../content/messages-buttons';
import messagesButtonsItems from '../content/messages-buttons/navigation-items';
import { MessagesDrafts } from '../content/messages-drafts';
import messagesDraftsItems from '../content/messages-drafts/navigation-items';
import { OperatorsTeam } from '../content/operators-team';
import operatorsTeamItems from '../content/operators-team/navigation-items';
import { MegabrainAiOverview } from '../content/megabrain-ai-overview';
import megabrainAiOverviewItems from '../content/megabrain-ai-overview/navigation-items';
import { Definitions } from '../content/definitions';
import definitionsItems from '../content/definitions/navigation-items';
import { IncomingMessagesSet } from '../content/incoming-messages-set';
import incomingMessagesSetItems from '../content/incoming-messages-set/navigation-items';
import { TrainingSetCreation } from '../content/training-set-creation';
import trainingSetCreationItems from '../content/training-set-creation/navigation-items';
import { Performance } from '../content/performance';
import performanceItems from '../content/performance/navigation-items';
import { StoryTraining } from '../content/story-training';
import storyTrainingItems from '../content/story-training/navigation-items';
import { AddingMessages } from '../content/adding-messages';
import addingMessagesItems from '../content/adding-messages/navigation-items';
import { MessageMarkupSub } from '../content/message-markup-sub';
import messageMarkupSubItems from '../content/message-markup-sub/navigation-items';
import { ChatArchive } from '../content/chat-archive';
import chatArchiveItems from '../content/chat-archive/navigation-items';
import { ChatVisibility } from '../content/chat-visibility';
import chatVisibilityItems from '../content/chat-visibility/navigation-items';
import { AgentGroups } from '../content/agent-groups';
import agentGroupsItems from '../content/agent-groups/navigation-items';
import { StopWords } from '../content/stop-words';
import stopWordsItems from '../content/stop-words/navigation-items';
import { EntityRecognition } from '../content/entity-recognition';
import entityRecognitionItems from '../content/entity-recognition/navigation-items';
import { ChannelPrioritization } from '../content/channel-prioritization';
import channelPrioritizationItems from '../content/channel-prioritization/navigation-items';
import { ChatDistribution } from '../content/chat-distribution';
import chatDistributionItems from '../content/chat-distribution/navigation-items';
import { AgentStatuses } from '../content/agent-statuses';
import agentStatusesItems from '../content/agent-statuses/navigation-items';
import { DistributionTags } from '../content/distribution-tags';
import distributionTagsItems from '../content/distribution-tags/navigation-items';
import { ChatbotActions } from '../content/chatbot-actions';
import chatbotActionsItems from '../content/chatbot-actions/navigation-items';
import { SessionsTopics } from '../content/sessions-topics';
import sessionsTopicsItems from '../content/sessions-topics/navigation-items';
import { VariousFiltersSearch } from '../content/various-filters-search';
import variousFiltersSearchItems from '../content/various-filters-search/navigation-items';
import { AutoArchivedChats } from '../content/auto-archived-chats';
import autoArchivedChatsItems from '../content/auto-archived-chats/navigation-items';
import { Reminders } from '../content/reminders';
import remindersItems from '../content/reminders/navigation-items';
import { ChatAttachments } from '../content/chat-attachments';
import chatAttachmentsItems from '../content/chat-attachments/navigation-items';
import { ChatActions } from '../content/chat-actions';
import chatActionsItems from '../content/chat-actions/navigation-items';
import { ChatAddAction } from '../content/chat-add-action';
import chatAddActionItems from '../content/chat-add-action/navigation-items';
import { ChatAddButton } from '../content/chat-add-button';
import chatAddButtonItems from '../content/chat-add-button/navigation-items';
import { ChatAddTemplate } from '../content/chat-add-template';
import chatAddTemplateItems from '../content/chat-add-template/navigation-items';
import { ChatAddEmoji } from '../content/chat-add-emoji';
import chatAddEmojiItems from '../content/chat-add-emoji/navigation-items';
import { ChatNote } from '../content/chat-note';
import chatNoteItems from '../content/chat-note/navigation-items';
import { ChatEditClientInfo } from '../content/chat-edit-client-info';
import chatEditClientInfoItems from '../content/chat-edit-client-info/navigation-items';
import { ChatDialogSortAndFilter } from '../content/chat-dialog-sort-and-filter';
import chatDialogSortAndFilterItems from '../content/chat-dialog-sort-and-filter/navigation-items';
import { ChatMessageEditAndDelete } from '../content/chat-message-edit-and-delete';
import chatMessageEditAndDeleteItems from '../content/chat-message-edit-and-delete/navigation-items';
import { WordOrPhraseSearch } from '../content/word-or-phrase-search';
import wordOrPhraseSearchItems from '../content/word-or-phrase-search/navigation-items';
import { HowToSetUpBotStatisticsCollection } from '../content/how-to-set-up-bot-statistics-collection';
import howToSetUpBotStatisticsCollectionItems from '../content/how-to-set-up-bot-statistics-collection/navigation-items';
import { Contexts } from '../content/contexts';
import contextsItems from '../content/contexts/navigation-items';
import { TopicRecognition } from '../content/topic-recognition';
import topicRecognitionItems from '../content/topic-recognition/navigation-items';
import { EmotionRecognition } from '../content/emotion-recognition';
import emotionRecognitionItems from '../content/emotion-recognition/navigation-items';
import { StoriesSub } from '../content/stories-sub';
import storiesSubItems from '../content/stories-sub/navigation-items';
import reportValuesDetailizationItems from '../content/report-values-detailization/navigation-items';
import { ReportValuesDetailization } from '../content/report-values-detailization';
import { HowToUseButtonTextsByButtonsArray } from '../content/how-to-use-button-texts-by-buttons-array';
import howToUseButtonTextsByButtonsArrayItems from '../content/how-to-use-button-texts-by-buttons-array/navigation-items';
import { SearchServiceOverview } from '../content/search-service-overview';
import searchServiceOverviewItems from '../content/search-service-overview/navigation-items';
import { SearchByUserId } from '../content/search-by-user-id';
import searchByPhoneNumberItems from '../content/search-by-phone-number/navigation-items';
import { SearchByPhoneNumber } from '../content/search-by-phone-number';
import searchByUserIdItems from '../content/search-by-user-id/navigation-items';
import { HelpOverview } from '../content/help-overview';
import helpOverviewItems from '../content/help-overview/navigation-items';
import { RuleCreation } from '../content/rule-creation';
import ruleCreationItems from '../content/rule-creation/navigation-items';
import { AvailableChannelsList } from '../content/available-channels-list';
import availableChannelsListItems from '../content/available-channels-list/navigation-items';
import { WhatIsWidget } from '../content/what-is-widget';
import whatIsWidgetItems from '../content/what-is-widget/navigation-items';
import { SingleChat } from '../content/single-chat';
import singleChatItems from '../content/single-chat/navigation-items';
import { BuiltInChat } from '../content/built-in-chat';
import builtInChatItems from '../content/built-in-chat/navigation-items';
import { BuiltInLinker } from '../content/built-in-linker';
import builtInLinkerItems from '../content/built-in-linker/navigation-items';
import { CornerLinker } from '../content/corner-linker';
import cornerLinkerItems from '../content/corner-linker/navigation-items';
import { MetadataOverview } from '../content/metadata-overview';
import metadataOverviewItems from '../content/metadata-overview/navigation-items';
import { Integrations } from '../content/integrations';
import integrationsItems from '../content/integrations/navigation-items';
import { MyReports } from '../content/my-reports';
import myReportsItems from '../content/my-reports/navigation-items';
import { CustomerServiceAssessment } from '../content/customer-service-assessment';
import customerServiceAssessmentItems from '../content/customer-service-assessment/navigation-items';
import { TagChanges } from '../content/tag-changes';
import tagChangesItems from '../content/tag-changes/navigation-items';
import { ReportsMegabrain } from '../content/reports-megabrain';
import reportsMegabrainItems from '../content/reports-megabrain/navigation-items';
import { SessionsOverview } from '../content/sessions-overview';
import sessionsOverviewItems from '../content/sessions-overview/navigation-items';
import { OtherReportsOptions } from '../content/other-reports-options';
import otherReportsOptionsItems from '../content/other-reports-options/navigation-items';
import { OperatorWork } from '../content/operator-work';
import operatorWorkItems from '../content/operator-work/navigation-items';
import { MessageTransportReport } from '../content/message-transport-report';
import messageTransportReportItems from '../content/message-transport-report/navigation-items';
import { ServerUrlConfigAndWebhookEvents } from '../content/serve-url-config-and-webhook-events';
import serverUrlConfigAndWebhookEventsItems from '../content/serve-url-config-and-webhook-events/navigation-items';
import { EventManagement } from '../content/event-management';
import eventManagementItems from '../content/event-management/navigation-items';
import { ChatHints } from '../content/chat-hints';
import chatHintsItems from '../content/chat-hints/navigation-items';
import { ReportSchedule } from '../content/report-schedule';
import reportScheduleItems from '../content/report-schedule/navigation-items';
import { ChartConfiguration } from '../content/chart-configuration';
import chartConfigurationItems from '../content/chart-configuration/navigation-items';
import { ReportConfigurator } from '../content/report-configurator';
import reportConfiguratorItems from '../content/report-configurator/navigation-items';
import { HowToMakeStubsForNonWorkingHours } from '../content/how-to-make-stubs-for-non-working-hours';
import howToMakeStubsForNonWorkingHoursItems from '../content/how-to-make-stubs-for-non-working-hours/navigation-items';
import { Dashboard } from '../content/dashboard';
import dashboardItems from '../content/dashboard/navigation-items';
import { HowToUseMultiLanguagesInBot } from '../content/how-to-use-multi-languages-in-bot';
import howToUseMultiLanguagesInBotItems from '../content/how-to-use-multi-languages-in-bot/navigation-items';

export const Navigation = ({ container }) => {
    const css = useStyles(styles);
    const [isOpenedMessageForm, setIsOpenedMessageForm] = useState(false);

    return (
        <div className={css('page')}>
            <Menu mainClass={css('menu')} container={container} />
            <div className={css('separator')} />
            {isOpenedMessageForm && (
                <FormMessage
                    onCancel={() => setIsOpenedMessageForm(false)}
                    onSend={() => setIsOpenedMessageForm(false)}
                />
            )}
            <Switch>
                <Route path={routes[ROUTE.HOW_TO_USE_MULTI_LANGUAGES_IN_BOT]}>
                    <HowToUseMultiLanguagesInBot openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToUseMultiLanguagesInBotItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.DASHBOARD]}>
                    <Dashboard openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={dashboardItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_MAKE_STUBS_FOR_NON_WORKING_HOURS]}>
                    <HowToMakeStubsForNonWorkingHours openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToMakeStubsForNonWorkingHoursItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.REPORT_CONFIGURATOR]}>
                    <ReportConfigurator openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={reportConfiguratorItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHART_CONFIGURATION]}>
                    <ChartConfiguration openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chartConfigurationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.REPORT_SCHEDULE]}>
                    <ReportSchedule openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={reportScheduleItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_HINTS]}>
                    <ChatHints openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={chatHintsItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.EVENT_MANAGEMENT]}>
                    <EventManagement openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={eventManagementItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SERVER_URL_CONFIG_AND_WEBHOOK_EVENTS]}>
                    <ServerUrlConfigAndWebhookEvents openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={serverUrlConfigAndWebhookEventsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGE_TRANSPORT_REPORT]}>
                    <MessageTransportReport openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messageTransportReportItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.OPERATOR_WORK]}>
                    <OperatorWork openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={operatorWorkItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.OTHER_REPORTS_OPTIONS]}>
                    <OtherReportsOptions openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={otherReportsOptionsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.REPORTS_MEGABRAIN]}>
                    <ReportsMegabrain openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={reportsMegabrainItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SESSIONS_OVERVIEW]}>
                    <SessionsOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={sessionsOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.TAG_CHANGES]}>
                    <TagChanges openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={tagChangesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CUSTOMER_SERVICE_ASSESSMENT]}>
                    <CustomerServiceAssessment openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={customerServiceAssessmentItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MY_REPORTS]}>
                    <MyReports openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={myReportsItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.INTEGRATIONS]}>
                    <Integrations openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={integrationsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.METADATA_OVERVIEW]}>
                    <MetadataOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={metadataOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CORNER_LINKER]}>
                    <CornerLinker openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={cornerLinkerItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.BUILT_IN_LINKER]}>
                    <BuiltInLinker openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={builtInLinkerItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.BUILT_IN_CHAT]}>
                    <BuiltInChat openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={builtInChatItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SINGLE_CHAT]}>
                    <SingleChat openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={singleChatItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.WHAT_IS_WIDGET]}>
                    <WhatIsWidget openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={whatIsWidgetItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.AVAILABLE_CHANNELS_LIST]}>
                    <AvailableChannelsList openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={availableChannelsListItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.AVAILABLE_CHANNELS_LIST]}>
                    <AvailableChannelsList openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={availableChannelsListItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.RULE_CREATION]}>
                    <RuleCreation openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={ruleCreationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HELP_OVERVIEW]}>
                    <HelpOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={helpOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SEARCH_BY_PHONE_NUMBER]}>
                    <SearchByPhoneNumber openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={searchByPhoneNumberItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SEARCH_BY_USER_ID]}>
                    <SearchByUserId openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={searchByUserIdItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SEARCH_SERVICE_OVERVIEW]}>
                    <SearchServiceOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={searchServiceOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_USE_BUTTON_TEXTS_BY_BUTTONS_ARRAY]}>
                    <HowToUseButtonTextsByButtonsArray openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToUseButtonTextsByButtonsArrayItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.REPORT_VALUES_DETAILIZATION]}>
                    <ReportValuesDetailization openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={reportValuesDetailizationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.STORIES_SUB]}>
                    <StoriesSub openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={storiesSubItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.EMOTION_RECOGNITION]}>
                    <EmotionRecognition openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={emotionRecognitionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.TOPIC_RECOGNITION]}>
                    <TopicRecognition openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={topicRecognitionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CONTEXTS]}>
                    <Contexts openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={contextsItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_SET_UP_BOT_STATISTICS_COLLECTION]}>
                    <HowToSetUpBotStatisticsCollection openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToSetUpBotStatisticsCollectionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.WORD_OR_PHRASE_SEARCH]}>
                    <WordOrPhraseSearch openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={wordOrPhraseSearchItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_DIALOG_SORT_AND_FILTER]}>
                    <ChatDialogSortAndFilter openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatDialogSortAndFilterItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_DIALOG_SORT_AND_FILTER]}>
                    <ChatDialogSortAndFilter openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatDialogSortAndFilterItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_MESSAGE_EDIT_AND_DELETE]}>
                    <ChatMessageEditAndDelete openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatMessageEditAndDeleteItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_EDIT_CLIENT_INFO]}>
                    <ChatEditClientInfo openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatEditClientInfoItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_NOTE]}>
                    <ChatNote openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={chatNoteItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.CHAT_ADD_EMOJI]}>
                    <ChatAddEmoji openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatAddEmojiItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ADD_TEMPLATE]}>
                    <ChatAddTemplate openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatAddTemplateItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ADD_BUTTON]}>
                    <ChatAddButton openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatAddButtonItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ADD_ACTION]}>
                    <ChatAddAction openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatAddActionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ATTACHMENTS]}>
                    <ChatAttachments openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatAttachmentsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ACTIONS]}>
                    <ChatActions openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatActionsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.REMINDERS]}>
                    <Reminders openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={remindersItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.AUTO_ARCHIVED_CHATS]}>
                    <AutoArchivedChats openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={autoArchivedChatsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SESSIONS_TOPICS]}>
                    <SessionsTopics openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={sessionsTopicsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.VARIOUS_FILTERS_SEARCH]}>
                    <VariousFiltersSearch openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={variousFiltersSearchItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_ACTIONS]}>
                    <ChatbotActions openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotActionsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.DISTRIBUTION_TAGS]}>
                    <DistributionTags openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={distributionTagsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.AGENT_STATUSES]}>
                    <AgentStatuses openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={agentStatusesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_DISTRIBUTION]}>
                    <ChatDistribution openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatDistributionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.STOP_WORDS]}>
                    <StopWords openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={stopWordsItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.CHANNEL_PRIORITIZATION]}>
                    <ChannelPrioritization openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={channelPrioritizationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ENTITY_RECOGNITION]}>
                    <EntityRecognition openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={entityRecognitionItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.AGENT_GROUPS]}>
                    <AgentGroups openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={agentGroupsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_VISIBILITY]}>
                    <ChatVisibility openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatVisibilityItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_ARCHIVE]}>
                    <ChatArchive openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatArchiveItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGE_MARKUP_SUB]}>
                    <MessageMarkupSub openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messageMarkupSubItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ADDING_MESSAGES]}>
                    <AddingMessages openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={addingMessagesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.STORY_TRAINING]}>
                    <StoryTraining openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={storyTrainingItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.PERFORMANCE]}>
                    <Performance openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={performanceItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.TRAINING_SET_CREATION]}>
                    <TrainingSetCreation openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={trainingSetCreationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.INCOMING_MESSAGES_SET]}>
                    <IncomingMessagesSet openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={incomingMessagesSetItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MEGABRAIN_OVERVIEW]}>
                    <MegabrainAiOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={megabrainAiOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.DEFINITIONS]}>
                    <Definitions openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={definitionsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.OPERATORS_TEAM]}>
                    <OperatorsTeam openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={operatorsTeamItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGES_DRAFTS]}>
                    <MessagesDrafts openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messagesDraftsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGES_BUTTONS]}>
                    <MessagesButtons openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messagesButtonsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ACCOUNT_CONFIGURATION]}>
                    <AccountConfiguration openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={accountConfigurationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.PROFILE_DATA]}>
                    <ProfileData openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={profileDataItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.DIAGNOSTICS]}>
                    <Diagnostics openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={diagnosticsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.NOTIFICATIONS]}>
                    <Notifications openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={notificationsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.GENERAL_STATISTICS]}>
                    <GeneralStatistics openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={generalStatisticsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.TEAM_ANALYTICS]}>
                    <TeamAnalytics openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={teamAnalyticsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGE_TRANSPORT_OVERVIEW]}>
                    <MessageTransportOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messageTransportOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.DIALOGS_OVERVIEW]}>
                    <DialogsOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={dialogsOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ACCOUNT_OVERVIEW]}>
                    <AccountOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={accountOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_ERRORS]}>
                    <ChatbotErrors openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotErrorsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_THREADS]}>
                    <ChatbotThreads openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotThreadsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_PRESETS]}>
                    <ChatbotPresets openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotPresetsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_DRAFT_DEBUG_MODE]}>
                    <ChatbotDraftDebugMode openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotDraftDebugModeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_DRAFT_OVERVIEW]}>
                    <ChatbotDraftOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotDraftOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MIDDLEWARE_BRANCHES]}>
                    <MiddlewareBranches openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={middlewareBranchesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MULTIBRANCH_CHATBOT]}>
                    <MultibranchChatbot />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={multibranchChatbotItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_LOGS]}>
                    <ChatbotLogs />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotLogsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_BRANCH_VERSION_HISTORY]}>
                    <ChatbotBranchVersionHistory />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotBranchVersionHistoryItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ARTIFICIAL_INTELLIGENCE]}>
                    <ArtificialIntelligence />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={artificialIntelligenceItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_FIND_WORD_OR_PHRASE_IN_USERS_MESSAGE]}>
                    <HowToFindWordOrPhraseInUsersMessage />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToFindWordOrPhraseInUsersMessageItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_USE_JS_WITH_BOTS]}>
                    <HowToUseJsWithBots />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={HowToUseJsWithBotsItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_INSERT_AND_APPLY_DATA_FROM_CLIENT_NOTIFICATION]}>
                    <HowToInsertAndApplyDataFromClientNotification />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={HowToInsertAndApplyDataFromClientNotificationItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_PERSONALIZE_BOT_MENU_IN_VIBER]}>
                    <HowToPersonalizeBotMenuInViber />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={HowToPersonalizeBotMenuInViberItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_CHOOSE_VARIABLE_FROM_ARRAY]}>
                    <HowToChooseVariableFromArray />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToChooseVariableFromArrayItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.INSTALL_CHATBOT_ON_YOUR_WEBSITE]}>
                    <InstallChatbotOnYourWebsite />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={installChatbotOnYourWebsiteItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.SYSTEM_VARIABLES]}>
                    <SystemVariables />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={systemVariablesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_USE_VARIABLES]}>
                    <HowToUseVariables />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToUseVariablesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_CREATE_VARIABLES]}>
                    <HowToCreateVariables />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToCreateVariablesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_MAKE_REQUEST_TO_EXTERNAL_SYSTEMS_USING_API]}>
                    <HowToMakeRequestToExternalSystemsUsingApi />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToMakeRequestToExternalSystemsUsingApiItems}
                        container={container}
                    />
                </Route>
                <Route
                    path={
                        routes[
                            ROUTE.HOW_TO_CREATE_DIFFERENT_CHATBOT_WELCOME_MESSAGES_DEPENDS_ON_WORK_TIME_HOURS
                        ]
                    }
                >
                    <HowToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHours />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToCreateDifferentChatbotWelcomeMessagesDependsOnWorkTimeHoursItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_ADD_SEARCHABLE_DATA_TO_USERS_PROFILE]}>
                    <HowToAddSearchableDataToUsersPofile />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToAddSearchableDataToUsersPofileItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_PREVENT_TEXT_ENTRY]}>
                    <HowToPreventTextEntry />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToPreventTextEntryItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.WHAT_IS_FLOMNI]}>
                    <WhatIsFlomni />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={[]} container={container} />
                </Route>
                <Route path={routes[ROUTE.BUILD_YOUR_FIRST_CHATBOT]}>
                    <FirstChatbot openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={firstChatbotItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ANALYTICS_OVERVIEW]}>
                    <AnalyticsOverview />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={analyticsOverviewItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_VALIDATE_USERS_TYPED_TEXT]}>
                    <HowToValidateUserTypedText />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToValidateUserTypedTextItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_STORE_USERS_PHONE_NUMBER_INTO_USERS_PROFILE]}>
                    <HowToStoreUserPhoneNumberIntoUserProfile />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToStoreUserPhoneNumberIntoUserProfileItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.HOW_TO_CONNECT_AGENT_TO_CHAT_AND_DISABLE_CHATBOT]}>
                    <HowToConnectAgentToChatAndDisableChatbot />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={howToConnectAgentToChatAndDisableChatbotItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_RELEASES]}>
                    <ChatbotReleases />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotReleasesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.FIRST_STEPS_WITH_NODES]}>
                    <FirstStepsWithNodes />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={firstStepsWithNodesItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CHAT_MANAGEMENT_NODE]}>
                    <ChatManagementNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatManagementNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.ACTION_CHAIN]}>
                    <ActionChainNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={actionChainNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.CONDITION_NODE]}>
                    <ConditionNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={conditionNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.STORAGE_NODE]}>
                    <StorageNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={storageNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.RANDOMIZER_NODE]}>
                    <RandomizerNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={randomizerNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.TIMEOUT_NODE]}>
                    <TimeoutNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={timeoutNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.JSON_NODE]}>
                    <JsonNode />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={jsonNodeItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.TEXT_ANALYSIS_NODE]}>
                    <TextAnalysisNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={textAnalysisNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.RECOGNITION_NODE]}>
                    <RecognitionNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={recognitionNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.NOTE_NODE]}>
                    <NoteNode />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={noteNodeItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.TAG_NODE]}>
                    <TagNode />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={tagNodeItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.ENTER_POINT_NODE]}>
                    <EnterPointNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={enterPointNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.EXIT_POINT_NODE]}>
                    <ExitPointNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={exitPointNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.WEBHOOK_NODE]}>
                    <WebhookNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={webhookNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.MESSAGE_NODE]}>
                    <MessageNode />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={messageNodeItems}
                        container={container}
                    />
                </Route>
                <Route path={routes[ROUTE.JS_NODE]}>
                    <JsNode />
                    <div className={css('separator')} />
                    <StickyMenu mainClass={css('sticky-menu')} items={jsNodeItems} container={container} />
                </Route>
                <Route path={routes[ROUTE.CHATBOT_BUILDER_OVERVIEW]}>
                    <ChatbotBuilderOverview openMessageForm={() => setIsOpenedMessageForm(true)} />
                    <div className={css('separator')} />
                    <StickyMenu
                        mainClass={css('sticky-menu')}
                        items={chatbotBuilderOverviewItems}
                        container={container}
                    />
                </Route>

                <Redirect from={routes[ROUTE.TOPICS]} exact to={routes[ROUTE.WHAT_IS_FLOMNI]} />
                <Redirect from={routes[ROUTE.GET_STARTED]} exact to={routes[ROUTE.WHAT_IS_FLOMNI]} />
                <Redirect from={routes[ROUTE.INTRO]} exact to={routes[ROUTE.WHAT_IS_FLOMNI]} />
                <Redirect from={routes[ROUTE.MEGABRAIN]} exact to={routes[ROUTE.MEGABRAIN_OVERVIEW]} />
                <Redirect from={routes[ROUTE.TRAINING_SETS]} exact to={routes[ROUTE.INCOMING_MESSAGES_SET]} />
                <Redirect from={routes[ROUTE.STORIES]} exact to={routes[ROUTE.STOP_WORDS]} />
                <Redirect
                    from={routes[ROUTE.TRAINING_AND_PERFORMANCE]}
                    exact
                    to={routes[ROUTE.PERFORMANCE]}
                />
                <Redirect from={routes[ROUTE.DIALOGS]} exact to={routes[ROUTE.DIALOGS_OVERVIEW]} />
                <Redirect
                    from={routes[ROUTE.DIALOGS_SEARCH]}
                    exact
                    to={routes[ROUTE.VARIOUS_FILTERS_SEARCH]}
                />
                <Redirect from={routes[ROUTE.GROUP_CONFIGURATION]} exact to={routes[ROUTE.CHAT_ARCHIVE]} />
                <Redirect from={routes[ROUTE.ANALYTICS]} exact to={routes[ROUTE.ANALYTICS_OVERVIEW]} />
                <Redirect from={routes[ROUTE.REPORTS]} exact to={routes[ROUTE.REPORT_VALUES_DETAILIZATION]} />
                <Redirect from={routes[ROUTE.TARGET_MONITORING]} exact to={routes[ROUTE.RULE_CREATION]} />
                <Redirect from={routes[ROUTE.EVENTS]} exact to={routes[ROUTE.AUTO_ARCHIVED_CHATS]} />
                <Redirect from={routes[ROUTE.CHATS]} exact to={routes[ROUTE.CHAT_ATTACHMENTS]} />
                <Redirect from={routes[ROUTE.DIALOGS_OPERATORS]} exact to={routes[ROUTE.CHAT_ATTACHMENTS]} />
                <Redirect
                    from={routes[ROUTE.DIALOGS_SUPERVISORS]}
                    exact
                    to={routes[ROUTE.DIALOGS_OVERVIEW]}
                />
                <Redirect from={routes[ROUTE.DIALOGS_ADMINISTRATORS]} exact to={routes[ROUTE.CHAT_ARCHIVE]} />

                <Redirect
                    from={routes[ROUTE.MESSAGE_TRANSPORT]}
                    exact
                    to={routes[ROUTE.MESSAGE_TRANSPORT_OVERVIEW]}
                />
                <Redirect from={routes[ROUTE.ACCOUNT]} exact to={routes[ROUTE.ACCOUNT_OVERVIEW]} />
                <Redirect from={routes[ROUTE.TIPS]} exact to={routes[ROUTE.TIPS_OVERVIEW]} />

                <Redirect
                    from={routes[ROUTE.CHATBOT_BUILDER]}
                    exact
                    to={routes[ROUTE.CHATBOT_BUILDER_OVERVIEW]}
                />
                <Redirect
                    from={routes[ROUTE.CHATBOT_NODE_ELEMENTS]}
                    exact
                    to={routes[ROUTE.FIRST_STEPS_WITH_NODES]}
                />
                <Redirect from={routes[ROUTE.DIALOGS_STATISTICS]} exact to={routes[ROUTE.TEAM_ANALYTICS]} />
                <Redirect from={routes[ROUTE.ACCOUNT_MANAGEMENT]} exact to={routes[ROUTE.PROFILE_DATA]} />
                <Redirect from={routes[ROUTE.MAIN_DEFINITIONS]} exact to={routes[ROUTE.DEFINITIONS]} />

                <Redirect from={routes[ROUTE.ACTION_NODES]} exact to={routes[ROUTE.MESSAGE_NODE]} />
                <Redirect from={routes[ROUTE.TRANSFER_NODES]} exact to={routes[ROUTE.ENTER_POINT_NODE]} />
                <Redirect from={routes[ROUTE.STORAGE_NODES]} exact to={routes[ROUTE.STORAGE_NODE]} />
                <Redirect from={routes[ROUTE.LOGIC_NODES]} exact to={routes[ROUTE.TEXT_ANALYSIS_NODE]} />

                <Redirect
                    from={routes[ROUTE.CHATBOT_VARIABLES]}
                    exact
                    to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]}
                />
                <Redirect
                    from={routes[ROUTE.USER_CASES]}
                    exact
                    to={routes[ROUTE.HOW_TO_CONNECT_AGENT_TO_CHAT_AND_DISABLE_CHATBOT]}
                />
                <Redirect from={routes[ROUTE.TEST_TOOLS]} exact to={routes[ROUTE.TEST_TOOLS_OVERVIEW]} />

                <Redirect
                    from={routes[ROUTE.CHATBOT_TEMPLATES]}
                    exact
                    to={routes[ROUTE.CHATBOT_TEMPLATES_OVERVIEW]}
                />

                <Redirect
                    from={routes[ROUTE.ADVANCED_FEATURES]}
                    exact
                    to={routes[ROUTE.ARTIFICIAL_INTELLIGENCE]}
                />
                <Redirect
                    from={routes[ROUTE.CHATBOT_DRAFT]}
                    exact
                    to={routes[ROUTE.CHATBOT_DRAFT_OVERVIEW]}
                />

                <Redirect
                    from={routes[ROUTE.SEARCH_SERVICE]}
                    exact
                    to={routes[ROUTE.SEARCH_SERVICE_OVERVIEW]}
                />
                <Redirect from={routes[ROUTE.HELP]} exact to={routes[ROUTE.HELP_OVERVIEW]} />
                <Redirect from={routes[ROUTE.CONFIGURATION]} exact to={routes[ROUTE.CHANNELS]} />
                <Redirect from={routes[ROUTE.CHANNELS]} exact to={routes[ROUTE.AVAILABLE_CHANNELS_LIST]} />
                <Redirect from={routes[ROUTE.WIDGET_APPEARANCE]} exact to={routes[ROUTE.WHAT_IS_WIDGET]} />
                <Redirect from={routes[ROUTE.METADATA]} exact to={routes[ROUTE.METADATA_OVERVIEW]} />
                <Redirect
                    from={routes[ROUTE.INTEGRATIONS_CONFIG]}
                    exact
                    to={routes[ROUTE.SERVER_URL_CONFIG_AND_WEBHOOK_EVENTS]}
                />
                <Redirect
                    from={routes[ROUTE.ANALYTICS_CONFIGURATION]}
                    exact
                    to={routes[ROUTE.EVENT_MANAGEMENT]}
                />

                <Redirect from="*" to={routes[ROUTE.NOT_FOUND]} />
            </Switch>
        </div>
    );
};

Navigation.propTypes = {
    container: object.isRequired
};

const analyticsOverviewItems = [
    {
        id: 'analyticsOverview1',
        title: 'helpPages.analyticsOverview.analyticsOverview1'
    },
    {
        id: 'analyticsOverview6',
        title: 'helpPages.analyticsOverview.analyticsOverview6'
    }
];

export default analyticsOverviewItems;

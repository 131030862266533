import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const JsonNode = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:jsonNode');

    return (
        <Body>
            <Title>{t('hlp:jsonNode')}</Title>
            <Header id="jsonNode1">{t('hlp:helpPages.jsonNode.jsonNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.jsonNode.jsonNode2')}</ListItem>
            </List>
            <Header id="jsonNode3">{t('hlp:helpPages.jsonNode.jsonNode3')}</Header>
            <PageVideo video={video1} />
            <Header id="jsonNode4">{t('hlp:helpPages.jsonNode.jsonNode4')}</Header>
            <PageVideo video={video2} />
        </Body>
    );
};

JsonNode.propTypes = {};

const howToChooseVariableFromArrayItems = [
    {
        id: 'howToChooseVariableFromArray1',
        title: 'helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray1'
    },
    {
        id: 'howToChooseVariableFromArray3',
        title: 'helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray3'
    }
];

export default howToChooseVariableFromArrayItems;

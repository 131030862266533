const diagnosticsItems = [
    {
        id: 'diagnostics1',
        title: 'helpPages.diagnostics.diagnostics1'
    },
    {
        id: 'diagnostics3',
        title: 'helpPages.diagnostics.diagnostics3'
    },
    {
        id: 'diagnostics5',
        title: 'helpPages.diagnostics.diagnostics5'
    },
    {
        id: 'diagnostics7',
        title: 'helpPages.diagnostics.diagnostics7'
    }
];

export default diagnosticsItems;

const chatVisibilityItems = [
    {
        id: 'chatVisibility1',
        title: 'helpPages.chatVisibility.chatVisibility1'
    },
    {
        id: 'chatVisibility4',
        title: 'helpPages.chatVisibility.chatVisibility4'
    }
];

export default chatVisibilityItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AutoArchivedChats = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:autoArchivedChats')

    return (
        <Body>
            <Title>{t('hlp:autoArchivedChats')}</Title>
            <Header id="autoArchivedChats1">{t('hlp:helpPages.autoArchivedChats.autoArchivedChats1')}</Header>
            <Paragraph>{t('hlp:helpPages.autoArchivedChats.autoArchivedChats2')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="autoArchivedChats3">{t('hlp:helpPages.autoArchivedChats.autoArchivedChats3')}</Header>
            <Paragraph>{t('hlp:helpPages.autoArchivedChats.autoArchivedChats4')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.autoArchivedChats.autoArchivedChats5')}</Paragraph>
            <PageVideo video={video3} />
        </Body>
    );
};

AutoArchivedChats.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Performance = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:performance');

    return (
        <Body>
            <Title>{t('hlp:performance')}</Title>
            <Header id="performance1">{t('hlp:helpPages.performance.performance1')}</Header>
            <Paragraph>{t('hlp:helpPages.performance.performance2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance3')}</Paragraph>
            <PageVideo video={video28} />
            <Paragraph>{t('hlp:helpPages.performance.performance4')}</Paragraph>
            <PageVideo video={video29} />
            <Paragraph>{t('hlp:helpPages.performance.performance5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance8')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance9')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance10')}</Paragraph>
            <PageVideo video={video30} />
            <Paragraph>{t('hlp:helpPages.performance.performance11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance12')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.performance.performance13')}</Paragraph>
        </Body>
    );
};

Performance.propTypes = {};

import { menu } from '../configs/menu';
import escapeStringRegexp from 'escape-string-regexp';

const getSearchResultsForItem = (inHeaders, page, resources, lowerKey, topName, t) => {
    const result = [];
    if (resources[page]) {
        const header = t(`hlp:${page}`);
        const texts = inHeaders ? [header] : [header, ...Object.values(resources[page])];
        const results = [];
        let total = 0;
        texts.forEach((text) => {
            const str = text.replaceAll(/<[\d/]*?>/g, '');
            const indexes = [];
            const matches = str.toLowerCase().matchAll(lowerKey);
            for (const match of matches) {
                indexes.push({
                    start: match.index,
                    end: match.index + lowerKey.length
                });
            }
            if (indexes.length) {
                results.push({
                    text: str,
                    indexes
                });
            }
            total += indexes.length;
        });
        if (results.length) {
            result.push({
                page,
                topName,
                results,
                total
            });
        }
    }
    return result;
};

const searchForMenuItem = (inHeaders, menu, resources, lowerKey, topName, t) => {
    return getSearchResultsForItem(inHeaders, menu.name, resources, lowerKey, topName, t).concat(
        searchForMenuItems(inHeaders, menu.items, resources, lowerKey, topName, t)
    );
};

const searchForMenuItems = (inHeaders, menuItems, resources, lowerKey, topName, t) => {
    if (menuItems && menuItems.length) {
        return menuItems.reduce((acc, menu) => {
            return acc.concat(
                searchForMenuItem(inHeaders, menu, resources, lowerKey, topName || menu.name, t)
            );
        }, []);
    }
    return [];
};

const getResults = (inHeaders, resources, key, t) => {
    const items = searchForMenuItems(
        inHeaders,
        menu,
        resources || {},
        escapeStringRegexp(key.toLowerCase()),
        null,
        t
    );
    return {
        key,
        items,
        total: items.reduce((acc, item) => acc + item.total, 0)
    };
};

const getSearchResults = (resources, key, t) => {
    return getResults(false, resources, key, t);
};

const getSearchResultsInHeaders = (resources, key, t) => {
    return getResults(true, resources, key, t);
};

const getFormattedText = (text, indexes, highlightClass) => {
    let result = '';
    let position = 0;
    indexes.forEach((index) => {
        result += text.slice(position, index.start);
        result += `<span class=${highlightClass}>${text.slice(index.start, index.end)}</span>`;
        position = index.end;
    });
    result += text.slice(position);
    return result;
};

export { getSearchResults, getSearchResultsInHeaders, getFormattedText };

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToChooseVariableFromArray = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToChooseVariableFromArray');

    return (
        <Body>
            <Title>{t('hlp:howToChooseVariableFromArray')}</Title>
            <Header id="howToChooseVariableFromArray1">
                {t('hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray1')}
            </Header>
            <List>
                <ListItem>
                    {t('hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray2')}
                </ListItem>
            </List>
            <Header id="howToChooseVariableFromArray3">
                {t('hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray5">
                    0
                    <Link to={routes[ROUTE.WEBHOOK_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray6">
                    0
                    <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray7">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray8">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray9')}
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToChooseVariableFromArray.howToChooseVariableFromArray10">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
        </Body>
    );
};

HowToChooseVariableFromArray.propTypes = {};

const chatbotThreadsItems = [
    {
        id: 'chatbotThreads1',
        title: 'helpPages.chatbotThreads.chatbotThreads1'
    },
    {
        id: 'chatbotThreads5',
        title: 'helpPages.chatbotThreads.chatbotThreads5'
    },
    {
        id: 'chatbotThreads9',
        title: 'helpPages.chatbotThreads.chatbotThreads9'
    },
    {
        id: 'chatbotThreads35',
        title: 'helpPages.chatbotThreads.chatbotThreads35'
    }
];

export default chatbotThreadsItems;

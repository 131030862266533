import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Reminders = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:reminders');

    return (
        <Body>
            <Title>{t('hlp:reminders')}</Title>
            <Header id="reminders1">{t('hlp:helpPages.reminders.reminders1')}</Header>
            <Paragraph>{t('hlp:helpPages.reminders.reminders2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.reminders.reminders3')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders4')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders5')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders8')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.reminders.reminders9')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.reminders.reminders10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.reminders.reminders11')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.reminders.reminders12')}</Paragraph>
            <PageVideo video={video6} />
            <Header id="reminders13">{t('hlp:helpPages.reminders.reminders13')}</Header>
            <Paragraph>{t('hlp:helpPages.reminders.reminders14')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.reminders.reminders15')}</Paragraph>
            <PageVideo video={video8} />
            <Paragraph>{t('hlp:helpPages.reminders.reminders16')}</Paragraph>
            <PageVideo video={video9} />
        </Body>
    );
};

Reminders.propTypes = {};

import { useContext, useLayoutEffect } from 'react';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Main } from '../main';
import { Footer } from '../footer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { NotFound } from '../not-found';
import { ROUTE, routes } from '../../configs/routes';
import { Navigation } from '../pages/navigation';
import { Page } from '../pages/page';
import EmbeddedLoader from '@flomni/modules/dist/components/Loaders/EmbeddedLoader';
import { Snackbar } from '@flomni/components';
import { Search } from '../search';
import { Search2 } from '../search2';
import { MobileHeader } from '../header/mobile';
import { DesktopHeader } from '../header/desktop';
import { store } from '../../state/state';

export const App = () => {
    const css = useStyles(styles);
    const { i18n } = useTranslation();
    const ADMIN_CLIENT_KEY = '59b0f882275c951dd20494c3';
    const language = i18n.language;
    const { pathname } = useLocation();
    const [isCustomScroll, setIsCustomScroll] = useState(false);
    const { isShowGlobalLoader, messages, dispatch } = useContext(store);
    const [container, setContainer] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
        const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

        if (!isMobileDevice) {
            let rootFontSize = 12;
            if (window.screen.width >= 1366) {
                rootFontSize = 13;
            }
            if (window.screen.width >= 1440) {
                rootFontSize = 14;
            }
            if (window.screen.width >= 1600) {
                rootFontSize = 16;
            }
            document.querySelector('html').style['fontSize'] = `${rootFontSize}px`;
        }

        if (!isMacLike) {
            setIsCustomScroll(true);
        }

        setIsMobile(window.screen.width < 1280);

        const orientationChangeListener = window.addEventListener('orientationchange', () => {
            setIsMobile(window.screen.width < 1280);
        });

        return () => {
            window.removeEventListener('orientationchange', orientationChangeListener);
        };
    }, []);

    useEffect(() => {
        window.flomni = {
            clientKey: ADMIN_CLIENT_KEY,
            entrypoint: 'support',
            language,
            onDestroyed: () => delete window.flomni
        };

        const script = document.createElement('script');
        script.id = 'widget';
        script.src = `${process.env.REACT_APP_WIDGET_HOST}/chat.corner.js`;
        document.body.append(script);

        return () => {
            const widget = document.querySelector('#widget');
            if (widget) {
                widget.remove();
            }
        };
    }, [language]);

    useLayoutEffect(() => {
        if (container) {
            container.scrollTo(0, 0);
        }
    }, [pathname, container]);

    return (
        <div className={css(['app', isCustomScroll ? 'custom-scroll' : null])}>
            <div className={css('top-left-circle')} />
            <svg
                className={css('top-right-polygon')}
                width="54"
                height="92"
                viewBox="0 0 54 92"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M48.7352 18.5609C58.0584 13.1549 69.7376 19.8689 69.7577 30.6461L69.8337 71.3492C69.8538 82.1264 58.1997 88.8839 48.8563 83.5127L13.5684 63.2269C4.22505 57.8557 4.19993 44.3843 13.5232 38.9783L48.7352 18.5609Z"
                    stroke="#4F4F4F"
                    strokeWidth="2"
                />
            </svg>
            <div className={css('center-rect')} />
            <div className={css('center-rect-border')} />
            <div className={css('bottom-left-rect')} />
            <div className={css('bottom-left-rect-border')} />
            <div className={css('bottom-left-circle')} />
            <div className={css('content')} ref={(node) => setContainer(node)}>
                {isMobile ? <MobileHeader /> : <DesktopHeader />}
                <Switch>
                    <Route exact path="/">
                        <Main />
                    </Route>
                    <Route path={routes[ROUTE.PAGES]}>
                        {container && <Page container={container} />}
                    </Route>
                    <Route path={routes[ROUTE.TOPICS]}>
                        {container && <Navigation container={container} />}
                    </Route>
                    <Route path={routes[ROUTE.SEARCH]}>{container && <Search2 container={container} />}</Route>
                    <Route path={routes[ROUTE.NOT_FOUND]}>
                        <NotFound />
                    </Route>
                    <Redirect from="*" to={routes[ROUTE.NOT_FOUND]} />
                </Switch>
                <Footer isMobile={isMobile} />
                <div id="flomni" />
            </div>
            {isShowGlobalLoader && (
                <div className={css('global-loader')}>
                    <EmbeddedLoader show={true} global={true} />
                </div>
            )}
            <Snackbar
                classes={{ root: css('snackbar') }}
                messages={messages}
                onCloseEnd={() => dispatch({ type: 'clearMessages' })}
            />
        </div>
    );
};

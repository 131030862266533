import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import video39 from './assets/video39.mov';
import video40 from './assets/video40.mov';
import video41 from './assets/video41.mov';
import video42 from './assets/video42.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TrainingSetCreation = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:trainingSetCreation');

    return (
        <Body>
            <Title>{t('hlp:trainingSetCreation')}</Title>
            <Header id="trainingSetCreation1">
                {t('hlp:helpPages.trainingSetCreation.trainingSetCreation1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation2')}</Paragraph>
            <Header id="trainingSetCreation3">
                {t('hlp:helpPages.trainingSetCreation.trainingSetCreation3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation4')}</Paragraph>
            <PageVideo video={video35} />
            <PageVideo video={video36} />
            <Header id="trainingSetCreation5">
                {t('hlp:helpPages.trainingSetCreation.trainingSetCreation5')}
            </Header>
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation6')}</Paragraph>
            <PageVideo video={video37} />
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation7')}</Paragraph>
            <PageVideo video={video38} />
            <PageVideo video={video39} />
            <Header id="trainingSetCreation8">
                {t('hlp:helpPages.trainingSetCreation.trainingSetCreation8')}
            </Header>
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation9')}</Paragraph>
            <PageVideo video={video40} />
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation11')}</Paragraph>
            <PageVideo video={video41} />
            <Paragraph>{t('hlp:helpPages.trainingSetCreation.trainingSetCreation12')}</Paragraph>
            <PageVideo video={video42} />
        </Body>
    );
};

TrainingSetCreation.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video24 from './assets/video24.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Integrations = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:integrations');

    return (
        <Body>
            <Title>{t('hlp:integrations')}</Title>
            <Header id="integrations1">{t('hlp:helpPages.integrations.integrations1')}</Header>
            <Paragraph>{t('hlp:helpPages.integrations.integrations2')}</Paragraph>
            <PageVideo video={video24} />
            <Header id="integrations3">{t('hlp:helpPages.integrations.integrations3')}</Header>
            <Paragraph>{t('hlp:helpPages.integrations.integrations4')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.integrations.integrations5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.integrations.integrations6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.integrations.integrations7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.integrations.integrations8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
        </Body>
    );
};

Integrations.propTypes = {};

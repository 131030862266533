import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input, Button, ControlContainer, Tooltip } from '@flomni/components'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'
import axios from "axios";
import { URL, AUTH_KEY } from '../constants'
import { useHistory } from 'react-router'
import { ROUTE, routes } from '../../../configs/routes'
import { LOCAL_STORAGE, setLocalState } from '../localState'

export const Login = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [invalidLoginError, setInvalidLoginError] = useState(null)
  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange'
  })
  const history = useHistory()

  const handlerClickLogin = async (event) => {
    event.preventDefault()
    setInvalidLoginError(null)
    const form = getValues()
    const token = `Basic ${btoa(`${form.login}:${form.password}`)}`
    axios.get(URL.MENU, { headers: { Authorization: token }}).then(() => {
      setLocalState(LOCAL_STORAGE, AUTH_KEY, {token, login: form.login})
      history.push(routes[ROUTE.EDITOR])
    }).catch((err) => {
      setInvalidLoginError(err.response?.data)
    })
  }

  const getPasswordErrorMessage = () => {
    if (errors.password) {
      return errors.password.message
    }
    if (invalidLoginError?.statusCode === 401) {
      return t('hlp:incorrectLoginOrPassword')
    }
    return null
  }

  return (
    <div className={css(classnames('main'))}>
      <div className={css('container')}>
        <div />
        <div className={css('center')}>
          <div className={css('center-content')}>
            <div>
              <div className={css('logo')} />
              <div className={css('form')}>
                <form>
                  <div className={css('title')}>{t('hlp:signInToAccount')}</div>
                  <div className={css('title-block')}>{t('hlp:loginName')}</div>
                  <ControlContainer
                    warning={errors.login ? errors.login.message : null}
                  >
                    <Input
                      autoFocus
                      placeholder={t('hlp:loginName')}
                      warning={!!errors.login}
                      name='login'
                      ref={register({ required: t('hlp:loginRequired') })}
                    />
                  </ControlContainer>
                  <div className={css('title-block')}>
                    <span>{t('hlp:password')}</span>
                  </div>
                  <ControlContainer warning={getPasswordErrorMessage()}>
                    <Input
                      placeholder={t('hlp:password')}
                      warning={!!errors.password}
                      name='password'
                      type='password'
                      ref={register({ required: t('hlp:passwordRequired') })}
                    />
                  </ControlContainer>
                  <div className={css('action-block')}>
                    <Button
                      view="primary"
                      onClick={handlerClickLogin}
                      disabled={!formState.isValid || !formState.isDirty}
                    >
                      {t('hlp:login')}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className={css('footer')}>
              <a className={css('footer-link')} href='https://flomni.com/' target='_blank'>
                @ Flomni
              </a>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  )
}

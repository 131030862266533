const builtInChatItems = [
    {
        id: 'builtInChat1',
        title: 'helpPages.builtInChat.builtInChat1'
    },
    {
        id: 'builtInChat3',
        title: 'helpPages.builtInChat.builtInChat3'
    }
];

export default builtInChatItems;

import React from 'react'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { useTranslation } from 'react-i18next'
import {
    Button,
    ControlContainer,
    Input,
    Popup,
    PopupButtons,
    PopupContent,
    PopupTitle
} from '@flomni/components'
import { func, string, array } from 'prop-types'
import { useForm } from 'react-hook-form'
import { uniqueId } from 'lodash-es'
import { KIND } from '../constants'

const GroupDialog = ({ group, menu, onCancel, ln, currentGroup, saveMenu }) => {
    const css = useStyles(styles)
    const { t } = useTranslation()

    const { register, formState, errors, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: group?.title[ln] || '',
            slug: group?.slug || ''
        }
    })

    const handlerClickSend = (event) => {
        event.preventDefault()
        const form = getValues()
        if (group.isNew) {
            (currentGroup?.item?.items || menu).push({
                title: {
                    [ln]: form.title
                },
                slug: form.slug,
                kind: KIND.GROUP,
                items: [],
                id: uniqueId('menu')
            })
        } else {
            group.title[ln] = form.title
            group.slug = form.slug
        }
        saveMenu()
    }

    const handlerClickOnCancel = (event) => {
        event.preventDefault()
        onCancel()
    }

    return (
        <Popup open={true}>
            <PopupTitle>{t('hlp:editingMenu')}</PopupTitle>
            <form>
                <PopupContent>
                    <div className={css('item')}>
                        <div className={css('title')}>{t('hlp:name')}</div>
                        <ControlContainer warning={errors.title ? t('hlp:messageRequired') : null}>
                            <Input
                                autoFocus
                                classes={{ root: css('field') }}
                                warning={!!errors.title}
                                name="title"
                                ref={register({ required: true })}
                            />
                        </ControlContainer>
                    </div>
                    <div className={css('item')}>
                        <div className={css('title')}>{t('hlp:slug')}</div>
                        <ControlContainer warning={errors.slug ? t('hlp:messageRequired') : null}>
                            <Input
                                classes={{ root: css('field') }}
                                warning={!!errors.slug}
                                name="slug"
                                ref={register({ required: true })}
                            />
                        </ControlContainer>
                    </div>
                </PopupContent>
                <PopupButtons>
                    <Button
                        view="primary"
                        onClick={handlerClickSend}
                        disabled={!formState.isValid || !formState.isDirty}
                    >
                        {t('hlp:save')}
                    </Button>
                    <Button view="cancel" onClick={handlerClickOnCancel}>
                        {t('hlp:cancel')}
                    </Button>
                </PopupButtons>
            </form>
        </Popup>
    )
}

GroupDialog.propTypes = {
    onSend: func.isRequired,
    onCancel: func.isRequired,
    ln: string.isRequired,
    menu: array.isRequired,
    editGroup: func
}

export default GroupDialog

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ActionChainNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:actionChainNode')

    return (
        <Body>
            <Title>{t('hlp:actionChainNode')}</Title>
            <Header id="actionChainNode1">{t('hlp:helpPages.actionChainNode.actionChainNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.actionChainNode.actionChainNode2')}</ListItem>
                <ListItem>{t('hlp:helpPages.actionChainNode.actionChainNode3')}</ListItem>
                <ListItem>{t('hlp:helpPages.actionChainNode.actionChainNode4')}</ListItem>
            </List>
            <Header id="actionChainNode5">{t('hlp:helpPages.actionChainNode.actionChainNode5')}</Header>
            <PageVideo video={video1} />
            <Header id="actionChainNode6">{t('hlp:helpPages.actionChainNode.actionChainNode6')}</Header>
            <Header2 id="actionChainNode7">{t('hlp:helpPages.actionChainNode.actionChainNode7')}</Header2>
            <Paragraph>{t('hlp:helpPages.actionChainNode.actionChainNode8')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode9">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header2 id="actionChainNode10">{t('hlp:helpPages.actionChainNode.actionChainNode10')}</Header2>
            <Paragraph>{t('hlp:helpPages.actionChainNode.actionChainNode11')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode12">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode13">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <Header id="actionChainNode14">{t('hlp:helpPages.actionChainNode.actionChainNode14')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode15">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.actionChainNode.actionChainNode16')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode17">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            7
                        </Link>
                        8<span className={css('bold')}>9</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode18">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            7
                        </Link>
                        8<span className={css('bold')}>9</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode19">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            7
                        </Link>
                        8<span className={css('bold')}>9</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode20">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            7
                        </Link>
                        8<span className={css('bold')}>9</span>10
                    </Trans>
                </ListItem>
            </List>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.actionChainNode.actionChainNode21">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
        </Body>
    );
};

ActionChainNode.propTypes = {};

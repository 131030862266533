import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const BuiltInLinker = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:builtInLinker')

    return (
        <Body>
            <Title>{t('hlp:builtInLinker')}</Title>
            <Header id="builtInLinker1">{t('hlp:helpPages.builtInLinker.builtInLinker1')}</Header>
            <Paragraph>{t('hlp:helpPages.builtInLinker.builtInLinker2')}</Paragraph>
            <PageVideo video={video18} />
            <Header id="builtInLinker3">{t('hlp:helpPages.builtInLinker.builtInLinker3')}</Header>
            <Paragraph>{t('hlp:helpPages.builtInLinker.builtInLinker4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.builtInLinker.builtInLinker5')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInLinker.builtInLinker6')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInLinker.builtInLinker7')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInLinker.builtInLinker8')}</ListItem>
            </List>
            <PageVideo video={video19} />
        </Body>
    );
};

BuiltInLinker.propTypes = {};

const searchByPhoneNumberItems = [
    {
        id: 'searchByPhoneNumber1',
        title: 'helpPages.searchByPhoneNumber.searchByPhoneNumber1'
    },
    {
        id: 'searchByPhoneNumber3',
        title: 'helpPages.searchByPhoneNumber.searchByPhoneNumber3'
    }
];

export default searchByPhoneNumberItems;

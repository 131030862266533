const howToSetUpBotStatisticsCollectionItems = [
    {
        id: 'howToSetUpBotStatisticsCollection1',
        title: 'helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection1'
    },
    {
        id: 'howToSetUpBotStatisticsCollection5',
        title: 'helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection5'
    },
    {
        id: 'howToSetUpBotStatisticsCollection14',
        title: 'helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection14'
    }
];

export default howToSetUpBotStatisticsCollectionItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const WebhookNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:webhookNode');

    return (
        <Body>
            <Title>{t('hlp:webhookNode')}</Title>
            <Header id="webhookNode1">{t('hlp:helpPages.webhookNode.webhookNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.webhookNode.webhookNode2')}</ListItem>
            </List>
            <Header id="webhookNode3">{t('hlp:helpPages.webhookNode.webhookNode3')}</Header>
            <PageVideo video={video1} />
            <Header id="webhookNode4">{t('hlp:helpPages.webhookNode.webhookNode4')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.webhookNode.webhookNode5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.webhookNode.webhookNode6')}</Paragraph>
            <List>
                <ListItem>{t('hlp:helpPages.webhookNode.webhookNode7')}</ListItem>
                <ListItem>{t('hlp:helpPages.webhookNode.webhookNode8')}</ListItem>
                <ListItem>{t('hlp:helpPages.webhookNode.webhookNode9')}</ListItem>
            </List>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.webhookNode.webhookNode10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.webhookNode.webhookNode11')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.webhookNode.webhookNode12">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.webhookNode.webhookNode13')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.webhookNode.webhookNode14">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.webhookNode.webhookNode15')}</Paragraph>
        </Body>
    );
};

WebhookNode.propTypes = {};

const customerServiceAssessmentItems = [
    {
        id: 'customerServiceAssessment1',
        title: 'helpPages.customerServiceAssessment.customerServiceAssessment1'
    },
    {
        id: 'customerServiceAssessment3',
        title: 'helpPages.customerServiceAssessment.customerServiceAssessment3'
    }
];

export default customerServiceAssessmentItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AnalyticsOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:analyticsOverview')

    return (
        <Body>
            <Title>{t('hlp:analyticsOverview')}</Title>
            <Header id="analyticsOverview1">{t('hlp:helpPages.analyticsOverview.analyticsOverview1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.analyticsOverview.analyticsOverview2')}</ListItem>
                <ListItem>{t('hlp:helpPages.analyticsOverview.analyticsOverview3')}</ListItem>
                <ListItem>{t('hlp:helpPages.analyticsOverview.analyticsOverview4')}</ListItem>
                <ListItem>{t('hlp:helpPages.analyticsOverview.analyticsOverview5')}</ListItem>
            </List>
            <Header id="analyticsOverview6">{t('hlp:helpPages.analyticsOverview.analyticsOverview6')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview7">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Header2 id="analyticsOverview8">
                {t('hlp:helpPages.analyticsOverview.analyticsOverview8')}
            </Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview9">
                    0
                    <Link to={routes[ROUTE.SESSIONS_OVERVIEW]} className={css('link')}>
                        1
                    </Link>
                    3
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview10">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview11">
                    <span className={css('bold')}>0</span>1
                </Trans>
            </Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview12">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview13">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview14">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview15">
                        <span className={css('bold')}>0</span>1
                        <Link to={routes[ROUTE.MEGABRAIN_OVERVIEW]} className={css('link')}>
                            3
                        </Link>
                        4
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview16">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview17">
                        <span className={css('bold')}>0</span>1
                        <Link to={routes[ROUTE.DISTRIBUTION_TAGS]} className={css('link')}>
                            2
                        </Link>
                        3
                        <Link to={routes[ROUTE.SESSIONS_TOPICS]} className={css('link')}>
                            4
                        </Link>
                        5
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview18">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
            </List>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview19">
                    <span className={css('bold')}>0</span>1
                    <Link to={routes[ROUTE.EVENT_MANAGEMENT]} className={css('link')}>
                        2
                    </Link>
                    3
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Header2 id="analyticsOverview20">
                {t('hlp:helpPages.analyticsOverview.analyticsOverview20')}
            </Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview21">
                    0
                    <Link to={routes[ROUTE.RULE_CREATION]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Header2 id="analyticsOverview22">
                {t('hlp:helpPages.analyticsOverview.analyticsOverview22')}
            </Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview23">
                    0
                    <Link to={routes[ROUTE.REPORT_CONFIGURATOR]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.analyticsOverview.analyticsOverview24')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview25">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview26">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview27">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.analyticsOverview.analyticsOverview28">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
            </List>
        </Body>
    );
};

AnalyticsOverview.propTypes = {};

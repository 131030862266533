import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const usePageAccess = (key) => {
    const { i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        const checkPermissions = () => {
            if (!i18n.exists(key)) {
                history.push('/');
            }
        };
        i18n.on('languageChanged', checkPermissions);

        checkPermissions();

        return () => {
            i18n.off('languageChanged', checkPermissions);
        };
    }, [key, i18n, history]);
};

export default usePageAccess;

const searchByUserIdItems = [
    {
        id: 'searchByUserId1',
        title: 'helpPages.searchByUserId.searchByUserId1'
    },
    {
        id: 'searchByUserId3',
        title: 'helpPages.searchByUserId.searchByUserId3'
    },
    {
        id: 'searchByUserId6',
        title: 'helpPages.searchByUserId.searchByUserId6'
    },
    {
        id: 'searchByUserId19',
        title: 'helpPages.searchByUserId.searchByUserId19'
    }
];

export default searchByUserIdItems;

const webhookNodeItems = [
    {
        id: 'webhookNode1',
        title: 'helpPages.webhookNode.webhookNode1'
    },
    {
        id: 'webhookNode3',
        title: 'helpPages.webhookNode.webhookNode3'
    },
    {
        id: 'webhookNode4',
        title: 'helpPages.webhookNode.webhookNode4'
    }
];

export default webhookNodeItems;

const helpOverviewItems = [
    {
        id: 'helpOverview1',
        title: 'helpPages.helpOverview.helpOverview1'
    },
    {
        id: 'helpOverview11',
        title: 'helpPages.helpOverview.helpOverview11'
    },
    {
        id: 'helpOverview13',
        title: 'helpPages.helpOverview.helpOverview13'
    }
];

export default helpOverviewItems;

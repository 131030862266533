const singleChatItems = [
    {
        id: 'singleChat1',
        title: 'helpPages.singleChat.singleChat1'
    },
    {
        id: 'singleChat5',
        title: 'helpPages.singleChat.singleChat5'
    },
    {
        id: 'singleChat17',
        title: 'helpPages.singleChat.singleChat17'
    },
    {
        id: 'singleChat20',
        title: 'helpPages.singleChat.singleChat20'
    },
    {
        id: 'singleChat29',
        title: 'helpPages.singleChat.singleChat29'
    }
];

export default singleChatItems;

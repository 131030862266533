import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessageTransportReport = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:messageTransportReport');

    return (
        <Body>
            <Title>{t('hlp:messageTransportReport')}</Title>
            <Header id="messageTransportReport1">
                {t('hlp:helpPages.messageTransportReport.messageTransportReport1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.messageTransportReport.messageTransportReport2')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="messageTransportReport3">
                {t('hlp:helpPages.messageTransportReport.messageTransportReport3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.messageTransportReport.messageTransportReport4')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph id="messageTransportReport5">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportReport.messageTransportReport6')}</Paragraph>
            <Paragraph id="messageTransportReport7">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph id="messageTransportReport8">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph id="messageTransportReport9">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport9">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph id="messageTransportReport10">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph id="messageTransportReport11">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport11">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph id="messageTransportReport12">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport12">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportReport.messageTransportReport13')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph id="messageTransportReport14">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport14">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportReport.messageTransportReport15')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph id="messageTransportReport16">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport16">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph id="messageTransportReport17">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport17">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph id="messageTransportReport18">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport18">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph id="messageTransportReport19">
                <Trans i18nKey="hlp:helpPages.messageTransportReport.messageTransportReport19">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
        </Body>
    );
};

MessageTransportReport.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video21 from './assets/video21.mov';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChartConfiguration = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chartConfiguration');

    return (
        <Body>
            <Title>{t('hlp:chartConfiguration')}</Title>
            <Header id="chartConfiguration1">
                {t('hlp:helpPages.chartConfiguration.chartConfiguration1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chartConfiguration.chartConfiguration2')}</Paragraph>
            <PageVideo video={video21} />
            <Header id="chartConfiguration3">
                {t('hlp:helpPages.chartConfiguration.chartConfiguration3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chartConfiguration.chartConfiguration4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chartConfiguration.chartConfiguration5')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration6')}</ListItem>
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration7')}</ListItem>
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration8')}</ListItem>
                <PageVideo video={video22} />
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration9')}</ListItem>
                <PageVideo video={video23} />
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration10')}</ListItem>
                <PageVideo video={video24} />
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration11')}</ListItem>
                <PageVideo video={video25} />
                <ListItem>{t('hlp:helpPages.chartConfiguration.chartConfiguration12')}</ListItem>
                <PageVideo video={video26} />
            </List>
            <Header id="chartConfiguration13">
                {t('hlp:helpPages.chartConfiguration.chartConfiguration13')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chartConfiguration.chartConfiguration14')}</Paragraph>
            <PageVideo video={video27} />
            <Paragraph>{t('hlp:helpPages.chartConfiguration.chartConfiguration15')}</Paragraph>
            <PageVideo video={video28} />
        </Body>
    );
};

ChartConfiguration.propTypes = {};

const textAnalysisNodeItems = [
    {
        id: 'textAnalysisNode1',
        title: 'helpPages.textAnalysisNode.textAnalysisNode1'
    },
    {
        id: 'textAnalysisNode3',
        title: 'helpPages.textAnalysisNode.textAnalysisNode3'
    },
    {
        id: 'textAnalysisNode4',
        title: 'helpPages.textAnalysisNode.textAnalysisNode4'
    },
    {
        id: 'textAnalysisNode11',
        title: 'helpPages.textAnalysisNode.textAnalysisNode11'
    }
];

export default textAnalysisNodeItems;

import { useTranslation } from 'react-i18next';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Dropdown, DropdownItem } from '@flomni/components';
import { useState } from 'react';
import { changeLanguage } from '@flomni/modules/dist/services/i18n/i18n';
import resources from '../../i18n/resources';
import { bool } from 'prop-types';

export const Footer = ({ isMobile }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const [showLanguages, setShowLanguages] = useState(false);

    const setLanguage = async (language) => {
        setShowLanguages(false);
        await changeLanguage(language);
    };

    return (
        <div className={css('footer')}>
            <div className={css('copyright')}>
                {isMobile
                    ? t('hlp:copyrightShort', { year: new Date().getFullYear() })
                    : t('hlp:copyright', { year: new Date().getFullYear() })}
            </div>
            <div>
                <Dropdown
                    active={showLanguages}
                    classes={{ root: css('dropdown') }}
                    position={isMobile ? 'left' : 'right'}
                    direction="up"
                    onOverlayClick={() => {
                        setShowLanguages(false);
                    }}
                >
                    {Object.keys(resources).map((language) => (
                        <DropdownItem
                            active={language === i18n.language}
                            key={language}
                            view="pure"
                            classes={{ root: css('dropdown-item') }}
                            onClick={() => setLanguage(language)}
                        >
                            {t(`language.${language}`)}
                        </DropdownItem>
                    ))}
                </Dropdown>
                <div className={css('language')} onClick={() => setShowLanguages(!showLanguages)}>
                    <div className={css('icon')} />
                    <div> {t(`language.${i18n.language}`)}</div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    isMobile: bool
};

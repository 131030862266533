import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToPersonalizeBotMenuInViber = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToPersonalizeBotMenuInViber');

    return (
        <Body>
            <Title>{t('hlp:howToPersonalizeBotMenuInViber')}</Title>
            <Header id="howToPersonalizeBotMenuInViber1">
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber1')}
            </Header>
            <List>
                <ListItem>
                    {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber2')}
                </ListItem>
            </List>
            <Header id="howToPersonalizeBotMenuInViber3">
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber3')}
            </Header>

            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber4')}
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber5')}{' '}
                {`@{{content.metaData.templateName}}.`}
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber6')}
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber7')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber8')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber9')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber10')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber11')}
            </Paragraph>
            <code className={css('code')}>
                <pre>
                    {`
{
    "sender":{
       "name":"FLOMNI.com",
       "avatar":"https://dl-media.viber.com/5/share/2/long/vibes/icon/image/0x0/b2e2/
       788773f8fc5ea4cb7b284a2da5a285f31103ed9fdde4464116c7ccafcfc3b2e2.jpg"
    },
    "type":"rich_media",
    "min_api_version":2,
    "alt_text":"Для согласия на получение оповещений напишите в ответ "Да"",
    "rich_media":{
       "Type":"rich_media",
       "ButtonsGroupColumns":6,
       "ButtonsGroupRows":7,
       "BgColor":"#FFFFFF",
       "Buttons":[
          {
             "Columns":6,
             "Rows":2,
             "ActionType":"reply",
             "ActionBody":"flbtn_main_menu",
             "Image":"https://static-dev.flomni.com/back/media/bot/
             b99e548d-9c20-4481-9457-2bf79986ac4e.png"
          },
          {
             "Columns":6,
             "Rows":2,
             "Text":"Для управления записью в медицинский центр, пожалуйста, перейдите в меню.",
             "ActionType":"reply",
             "ActionBody":"flbtn_Меню",
             "TextSize":"medium",
             "TextVAlign":"middle",
             "TextHAlign":"left"
          },
          {
             "Columns":6,
             "Rows":1,
             "ActionType":"reply",
             "ActionBody":"flbtn_Отследить",
             "Text":"Отследить",
             "TextSize":"large",
             "TextVAlign":"middle",
             "TextHAlign":"middle",
             "BgColor":"#FF9C00"
          },
          {
             "Columns":6,
             "Rows":1,
             "ActionType":"reply",
             "ActionBody":"flbtn_Забрать самостоятельно",
             "Text":"Забрать самостоятельно",
             "TextSize":"large",
             "TextVAlign":"middle",
             "TextHAlign":"middle",
             "BgColor":"#FF9C00"
          },
          {
             "Columns":6,
             "Rows":1,
             "ActionType":"reply",
             "ActionBody":"flbtn_Согласовать доставку",
             "Text":"Сгласовать доставку",
             "TextSize":"large",
             "TextVAlign":"middle",
             "TextHAlign":"middle",
             "BgColor":"#FF9C00"
          }
       ]
    }
 }
                     
                `}
                </pre>
            </code>
        </Body>
    );
};

HowToPersonalizeBotMenuInViber.propTypes = {};

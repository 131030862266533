import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { PageImage } from '../../shared/image';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { PageTip } from '../../shared/tip';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import image3 from './assets/image3.jpg';
import image4 from './assets/image4.jpg';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToMakeRequestToExternalSystemsUsingApi = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToMakeRequestToExternalSystemsUsingApi');

    return (
        <Body>
            <Title>{t('hlp:howToMakeRequestToExternalSystemsUsingApi')}</Title>
            <Header id="howToMakeRequestToExternalSystemsUsingApi1">
                {t(
                    'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi1'
                )}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi2'
                    )}
                </ListItem>
            </List>
            <Header id="howToMakeRequestToExternalSystemsUsingApi3">
                {t(
                    'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi3'
                )}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi4">
                    0
                    <Link to={routes[ROUTE.WEBHOOK_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi5">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageImage image={image1} />
            <Paragraph>
                {t(
                    'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi6'
                )}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi7">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi8">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6<span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                {t(
                    'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi9'
                )}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi10">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi11">
                    0
                    <Link to={routes[ROUTE.HOW_TO_USE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                {t(
                    'hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi12'
                )}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi13">
                    0
                    <Link to={routes[ROUTE.CONDITION_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageImage image={image2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi14">
                    0
                    <Link to={routes[ROUTE.ACTION_CHAIN]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <PageImage image={image3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi15">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageImage image={image4} />
            <PageTip>
                <div>
                    <Trans i18nKey="hlp:helpPages.howToMakeRequestToExternalSystemsUsingApi.howToMakeRequestToExternalSystemsUsingApi16">
                        0
                        <Link to={routes[ROUTE.SYSTEM_VARIABLES]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </div>
            </PageTip>
        </Body>
    );
};

HowToMakeRequestToExternalSystemsUsingApi.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { menu } from '../../configs/menu';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROUTE, routes } from '../../configs/routes';
import { NavLink } from 'react-router-dom';
import useStateRef from '../../hooks/useStateRef';
import { object, string } from 'prop-types';

export const Menu = ({ container, mainClass }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [openedList, setOpenedList] = useState([]);
    const [, setMenuContainer, menuContainerRef] = useStateRef(null);
    const [menuFixedWidth, setIsMenuFixedWidth] = useState(null);

    useEffect(() => {
        const opened = [];
        Object.keys(routes).forEach((name) => {
            if (history.location.pathname.indexOf(routes[name]) > -1) {
                opened.push(name);
            }
        });
        setOpenedList(opened);
    }, [history]);

    const onScroll = useCallback(() => {
        const rect = menuContainerRef.current.getBoundingClientRect();
        setIsMenuFixedWidth(rect.top < 25 ? rect.width : null);
    }, [menuContainerRef]);

    useEffect(() => {
        container && container.addEventListener('scroll', onScroll);
        return () => {
            container && container.removeEventListener('scroll', onScroll);
        };
    }, [container, onScroll]);

    const MenuItem = ({ name, items, rootClass, index }) => {
        const isOpened = openedList.includes(name);

        const handlerClickItem = (e) => {
            e.stopPropagation();
            if (items.length) {
                setOpenedList(isOpened ? openedList.filter((n) => n !== name) : [...openedList, name]);
            }
        };

        if (!i18n.exists(`hlp:${name}`)) {
            return null
        }
        return (
            <div
                className={css([
                    'menu-item',
                    items.length ? '--main' : null,
                    rootClass || null,
                    index === 0 ? '--first' : null
                ])}
                onClick={handlerClickItem}
            >
                <div className={css(['menu-item-header', items.length ? '--openable' : null])}>
                    {items.length > 0 && (
                        <div className={css('menu-item-header-title')}>
                            <div className={css('menu-item-arrow')}>
                                {!isOpened && (
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.00002 11.2917L11.0001 7.29171L7.00002 3.29167"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.0001 7.29175L2.99998 7.29175"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                                {isOpened && (
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.70831 6.99996L6.70835 11L10.7084 6.99996"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.70837 11L6.70837 2.99992"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span>{t(`hlp:${name}`)}</span>
                        </div>
                    )}
                    {items.length === 0 && (
                        <NavLink
                            activeClassName={css('menu-item-active')}
                            to={routes[name] || routes[ROUTE.NOT_FOUND]}
                        >
                            {t(`hlp:${name}`)}
                        </NavLink>
                    )}
                </div>
                {items.length > 0 &&
                    isOpened &&
                    items.map((item, index) => (
                        <MenuItem key={item.name} name={item.name} items={item.items || []} index={index} />
                    ))}
            </div>
        );
    };

    return (
        <div ref={(node) => setMenuContainer(node)} className={mainClass}>
            <div
                className={css(menuFixedWidth ? 'main-fixed' : null)}
                style={{ width: menuFixedWidth ? menuFixedWidth + 'px' : '100%' }}
            >
                {menu.map((item, index) => (
                    <MenuItem
                        key={item.name}
                        name={item.name}
                        items={item.items || []}
                        index={index}
                        rootClass="--top"
                    />
                ))}
            </div>
        </div>
    );
};

Menu.propTypes = {
    container: object,
    mainClass: string
};

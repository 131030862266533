const chatMessageEditAndDeleteItems = [
    {
        id: 'chatMessageEditAndDelete1',
        title: 'helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete1'
    },
    {
        id: 'chatMessageEditAndDelete5',
        title: 'helpPages.chatMessageEditAndDelete.chatMessageEditAndDelete5'
    }
];

export default chatMessageEditAndDeleteItems;

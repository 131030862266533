import React, { useState, useCallback, useEffect } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import {
    Button, ControlContainer, Input, Popup, PopupContent, PopupTitle, ExpandButton, Dropdown, DropdownItem
} from '@flomni/components'
import { func, string, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import ContentBlock from '../content-block'
import { BLOCKS, URL, BODY, AUTH_KEY } from '../constants'
import PopupButtons from '../PopupButtons'
import axios from 'axios'
import arrayMove from '../utils'
import { getLocalState, LOCAL_STORAGE } from '../localState'

const SortableItem = SortableElement(({ block, idx, deleteContent, updateContent }) => {
  return <ContentBlock block={block} index={idx} onDelete={deleteContent} onUpdate={updateContent} />
})

const SortableList = SortableContainer(({ content, updateContent, deleteContent }) => {
    const css = useStyles(styles)
    return (
        <div className={css('list')}>
            {content.map((block, index) => (
                <SortableItem
                    block={block}
                    index={index}
                    idx={index}
                    key={index}
                    updateContent={updateContent}
                    deleteContent={deleteContent}
                />
            ))}
        </div>
    )
})

const PageDialog = ({ editPage, onCancel, ln, savePage, deletePage }) => {
    const css = useStyles(styles)
    const { t } = useTranslation()
    const [showBlocksList, setShowBlocksList] = useState(false)
    const [page, setPage] = useState(null)
    const [draftContent, setDraftContent] = useState('')
    const [showPublish, setShowPublish] = useState(false)
    const [showConfirmDeletePage, setShowConfirmDeletePage] = useState(false)
    const { register, formState, errors, getValues, setValue, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: editPage.page?.metadata[ln]?.title || '',
            slug: editPage.page?.slug
        }
    })
    const HEADERS = { headers: { Authorization: getLocalState(LOCAL_STORAGE, AUTH_KEY)?.token }}

    const handlerClickSaveDraft = (event) => {
        event.preventDefault()
        setShowPublish(JSON.stringify(page?.content?.draft) !== JSON.stringify(page?.content?.published))
        const form = getValues()
        page.metadata = { ...page.metadata, [ln]: {title: form.title} }
        page.slug = form.slug
        setDraftContent(JSON.stringify(page?.content?.draft))
        reset({
            title: form.title,
            slug: form.slug
        })
        savePage(editPage?.page?.id, page, editPage.folder, true)
    }

    const handlerClickPublished = (event) => {
        event.preventDefault()
        page.content.published = { ...page.content.draft }
        savePage(editPage?.page?.id, page, editPage.folder)
        onCancel()
    }

    const handlerClickOnCancel = (event) => {
        event && event.preventDefault()
        onCancel()
    }

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
      setPage({...page, content: {...page.content, draft: {
          ...page.content.draft,
          [ln]: {kind: BODY, children: arrayMove(page.content.draft[ln].children, oldIndex, newIndex)}}
      }})
    }, [page])

    const handlerOverlayClick = useCallback(() => {
        setShowBlocksList(false)
    }, [])

    useEffect(async () => {
        let currentPage = null
        if (editPage?.page?.id) {
            await axios.get(`${URL.PAGES}/${editPage.page.id}`, HEADERS).then((response) => {
                currentPage = response.data
                setPage(currentPage)
            })
        } else {
            currentPage = {metadata: {[ln]: {title: ''}}, content: {
                draft: {[ln]: {kind: BODY, children: []}},
                published: {[ln]: {kind: BODY, children: []}}
            }}
            setPage(currentPage)
        }
        setDraftContent(JSON.stringify(currentPage?.content?.draft))
        setShowPublish(JSON.stringify(currentPage?.content?.draft) !== JSON.stringify(currentPage?.content?.published))
    }, [editPage])

    const addContent = useCallback((kind, content) => {
        setPage({...page, content: {...page.content, draft: {
            ...page.content.draft,
            [ln]: {kind: BODY, children: [...(page.content.draft[ln]?.children || []), {kind: kind, content: content}]}}
        }})
    }, [page])

    const updateContent = useCallback((editIndex, kind, content) => {
        setPage({...page, content: {...page.content, draft: {
            ...page.content.draft,
            [ln]: {kind: BODY, children: [...page.content.draft[ln].children.map((block, index) => {
                return editIndex === index ? {kind: kind, content: content} : block
            })]}}
        }})
        setValue('content', page.content.draft[ln].children, { shouldDirty: true })
    }, [page])

    const deleteContent = useCallback((editIndex) => {
        setPage({...page, content: {...page.content, draft: {
            ...page.content.draft,
            [ln]: {kind: BODY, children: [...page.content.draft[ln].children.filter((block, index) => {
                return editIndex !== index
            })]}}
        }})
    }, [page])

    return (
        <Popup open={true} width='extended'>
            <PopupTitle>{t('hlp:editPage')}</PopupTitle>
            <form>
                <PopupContent>
                    <div className={css('item')}>
                        <div className={css('title')}>{t('hlp:name')}</div>
                        <ControlContainer warning={errors.title ? t('hlp:messageRequired') : null}>
                            <Input
                                autoFocus
                                classes={{ root: css('field') }}
                                warning={!!errors.title}
                                name='title'
                                ref={register({ required: true })}
                            />
                        </ControlContainer>
                    </div>
                    <div className={css('item')}>
                        <div className={css('title')}>{t('hlp:slug')}</div>
                        <ControlContainer warning={errors.slug ? t('hlp:messageRequired') : null}>
                            <Input
                                classes={{ root: css('field') }}
                                warning={!!errors.slug}
                                name='slug'
                                ref={register({ required: true })}
                            />
                        </ControlContainer>
                    </div>
                    {!!page?.content?.draft[ln]?.children?.length && (
                        <>
                            <div className={css('item')}>
                                <div className={css('title')}>{t('hlp:blocksPage')}</div>
                            </div>
                            <div className={css('item')}>
                                <SortableList
                                    content={page?.content?.draft[ln].children}
                                    axis='y'
                                    onSortEnd={onSortEnd}
                                    helperClass={css('drag')}
                                    updateContent={updateContent}
                                    deleteContent={deleteContent}
                                    useDragHandle
                                />
                            </div>
                        </>
                    )}
                    <div className={css('item')}>
                        <ExpandButton
                            onClick={(e) => {
                                e.preventDefault();
                                setShowBlocksList(true)
                            }}
                            size='small'
                            view='plus'
                        >
                            {t('hlp:addBlock')}
                        </ExpandButton>
                        {showBlocksList && <Dropdown classes={{ root: css('drop-down') }} active={true} onOverlayClick={handlerOverlayClick}>
                            {BLOCKS.map((item) =>
                                <DropdownItem
                                    key={item.kind}
                                    classes={{ root: css('drop-down-item'), item: css('drop-down-content') }}
                                    view='pure'
                                    onClick={() => {
                                        addContent(item.kind)
                                        setShowBlocksList(false)
                                    }}
                                >
                                    {t(`hlp:blocks.${item.kind}`)}
                                </DropdownItem>
                            )}
                        </Dropdown>}
                    </div>
                </PopupContent>
                <PopupButtons
                    left={
                        editPage?.page?.id ?
                            <Button view='warning' size='medium' onClick={(e) => {
                                e.preventDefault()
                                setShowConfirmDeletePage(true)
                            }}>
                                {t('hlp:delete')}
                            </Button> : null
                        }
                    right={[
                        showPublish ? <Button
                            view='primary'
                            onClick={handlerClickPublished}
                        >
                            {t('hlp:publish')}
                        </Button> : <></>,
                        <Button
                            view='secondary'
                            onClick={handlerClickSaveDraft}
                            disabled={(!formState.isValid || !formState.isDirty) && draftContent === JSON.stringify(page?.content?.draft)}
                        >
                            {t('hlp:saveDraft')}
                        </Button>,
                        <Button view='cancel' onClick={handlerClickOnCancel}>
                            {t('hlp:close')}
                        </Button>
                    ]}
                />
            </form>
            {showConfirmDeletePage && (
                <Popup open={true}>
                    <PopupTitle view="warning">{t('hlp:deletePage')}</PopupTitle>
                    <PopupContent classes={{ root: css`confirm-content` }}>
                        {t('hlp:deletePageDescription')}
                    </PopupContent>
                    <PopupButtons
                        right={[
                            <Button view="warning" size="medium" onClick={() => {
                                deletePage(editPage.page.id, editPage.folder)
                                handlerClickOnCancel()
                            }}>
                                {t('hlp:delete')}
                            </Button>,
                            <Button view="cancel" size="medium" onClick={() => setShowConfirmDeletePage(false)}>
                                {t('hlp:cancel')}
                            </Button>
                        ]}
                    >
                    </PopupButtons>
                </Popup>
            )}
        </Popup>
    )
}

PageDialog.propTypes = {
    ln: string.isRequired,
    onCancel: func.isRequired,
    editPage: object.isRequired
}

export default PageDialog

import React, { cloneElement, memo } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.scss'
import { arrayOf, element, oneOfType } from 'prop-types'

const PopupButtons = (props) => {
  const css = useStyles(styles)
  const patterns = [
    ['left', 'unshift'],
    ['right', 'push']
  ]
  let gridTemplateColumns = ['1fr']

  patterns.forEach(([side, method]) => {
    if (props[side]) {
      if (Array.isArray(props[side])) {
        props[side].forEach((_) => gridTemplateColumns[method]('min-content'))
      } else {
        gridTemplateColumns[method]('min-content')
      }
    }
  })

  const normalizer = (array) => {
    return array.flat(1).map((element, index) => cloneElement(element, { key: index }))
  }

  return (
    <div className={css`container`} style={{ gridTemplateColumns: gridTemplateColumns.join(' ') }}>
      {props.left && normalizer([props.left, <div />])}
      {!props.left && props.right && normalizer([<div />, props.right])}
      {props.left && props.right && normalizer([props.right])}
    </div>
  )
}

PopupButtons.propTypes = {
  left: oneOfType([element, arrayOf(element)]),
  right: oneOfType([element, arrayOf(element)])
}

export default memo(PopupButtons)

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/app/';
import { init } from '@flomni/modules/dist/services/i18n/i18n';
import resources from './i18n/resources';
import { Spinner } from '@flomni/components';
import '@flomni/components/src/styles/vars.scss';
import './index.css';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import smoothscroll from 'smoothscroll-polyfill';
import 'url-search-params-polyfill';
import { HelpCenterProvider } from './state/state';
import { ROUTE, routes } from './configs/routes';
import { Editor } from './components/editor';
import { Login } from './components/editor/login';
import { Route, Switch } from 'react-router'

const params = new URLSearchParams(window.location.search);
const lang = params.get('lang') || localStorage.getItem('i18nextLng');
init(resources, Object.keys(resources).includes(lang) ? lang : 'ru');
smoothscroll.polyfill();

ReactDOM.render(
    <Suspense
        fallback={
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div style={{ width: '100px' }}>
                    <Spinner />
                </div>
            </div>
        }
    >
        <Router history={createBrowserHistory()}>
            <HelpCenterProvider>
                <Switch>
                    <Route path={routes[ROUTE.EDITOR]}>
                      <Editor />
                    </Route>
                    <Route path={routes[ROUTE.LOGIN]}>
                      <Login />
                    </Route>
                    <Route component={App} />
                </Switch>
            </HelpCenterProvider>
        </Router>
    </Suspense>,
    document.getElementById('root')
);

const chatbotDraftOverviewItems = [
    {
        id: 'chatbotDraftOverview1',
        title: 'helpPages.chatbotDraftOverview.chatbotDraftOverview1'
    },
    {
        id: 'chatbotDraftOverview4',
        title: 'helpPages.chatbotDraftOverview.chatbotDraftOverview4'
    }
];

export default chatbotDraftOverviewItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessagesButtons = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:messagesButtons');

    return (
        <Body>
            <Title>{t('hlp:messagesButtons')}</Title>
            <Header id="messagesButtons1">{t('hlp:helpPages.messagesButtons.messagesButtons1')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons3')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons6')}</Paragraph>
            <PageVideo video={video15} />
            <Header id="messagesButtons7">{t('hlp:helpPages.messagesButtons.messagesButtons7')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons8')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.messagesButtons.messagesButtons9')}</ListItem>
                <PageVideo video={video16} />
                <ListItem>{t('hlp:helpPages.messagesButtons.messagesButtons10')}</ListItem>
                <PageVideo video={video17} />
                <ListItem>{t('hlp:helpPages.messagesButtons.messagesButtons11')}</ListItem>
                <PageVideo video={video18} />
                <ListItem>{t('hlp:helpPages.messagesButtons.messagesButtons12')}</ListItem>
                <PageVideo video={video19} />
            </List>
            <Header id="messagesButtons13">{t('hlp:helpPages.messagesButtons.messagesButtons13')}</Header>
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons14')}</Paragraph>
            <PageVideo video={video20} />
            <Paragraph>{t('hlp:helpPages.messagesButtons.messagesButtons15')}</Paragraph>
            <PageVideo video={video21} />
        </Body>
    );
};

MessagesButtons.propTypes = {};

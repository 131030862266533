const howToAddSearchableDataToUsersPofileItems = [
    {
        id: 'howToAddSearchableDataToUsersPofile1',
        title: 'helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile1'
    },
    {
        id: 'howToAddSearchableDataToUsersPofile3',
        title: 'helpPages.howToAddSearchableDataToUsersPofile.howToAddSearchableDataToUsersPofile3'
    }
];

export default howToAddSearchableDataToUsersPofileItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SearchByPhoneNumber = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:searchByPhoneNumber');

    return (
        <Body>
            <Title>{t('hlp:searchByPhoneNumber')}</Title>
            <Header id="searchByPhoneNumber1">
                {t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber2')}</Paragraph>
            <Header id="searchByPhoneNumber3">
                {t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber4')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber5')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber8')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber9')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber10')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber11')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber12')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber13')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber14')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber15')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber16')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber17')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber19')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber20')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber21')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.searchByPhoneNumber.searchByPhoneNumber22')}</Paragraph>
            <PageVideo video={video8} />
        </Body>
    );
};

SearchByPhoneNumber.propTypes = {};

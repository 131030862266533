import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ProfileData = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:profileData');

    return (
        <Body>
            <Title>{t('hlp:profileData')}</Title>
            <Header id="profileData1">{t('hlp:helpPages.profileData.profileData1')}</Header>
            <Paragraph>{t('hlp:helpPages.profileData.profileData2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.profileData.profileData3')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.profileData.profileData4')}</ListItem>
                <PageVideo video={video1} />
                <ListItem>{t('hlp:helpPages.profileData.profileData5')}</ListItem>
                <PageVideo video={video2} />
                <ListItem>{t('hlp:helpPages.profileData.profileData6')}</ListItem>
                <PageVideo video={video3} />
                <ListItem>{t('hlp:helpPages.profileData.profileData7')}</ListItem>
                <PageVideo video={video4} />
                <ListItem>{t('hlp:helpPages.profileData.profileData8')}</ListItem>
                <PageVideo video={video5} />
                <ListItem>{t('hlp:helpPages.profileData.profileData9')}</ListItem>
                <ListItem>{t('hlp:helpPages.profileData.profileData10')}</ListItem>
                <PageVideo video={video6} />
            </List>
            <Header id="profileData11">{t('hlp:helpPages.profileData.profileData11')}</Header>
            <Paragraph>{t('hlp:helpPages.profileData.profileData12')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.profileData.profileData13')}</Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

ProfileData.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video31 from './assets/video31.mov';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import video34 from './assets/video34.mov';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const OperatorWork = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:operatorWork');

    return (
        <Body>
            <Title>{t('hlp:operatorWork')}</Title>
            <Header id="operatorWork1">{t('hlp:helpPages.operatorWork.operatorWork1')}</Header>
            <Paragraph>{t('hlp:helpPages.operatorWork.operatorWork2')}</Paragraph>
            <PageVideo video={video31} />
            <Header id="operatorWork3">{t('hlp:helpPages.operatorWork.operatorWork3')}</Header>
            <Paragraph>{t('hlp:helpPages.operatorWork.operatorWork4')}</Paragraph>
            <Paragraph id="operatorWork5">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork5">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video32} />
            <Paragraph id="operatorWork6">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.operatorWork.operatorWork7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.operatorWork.operatorWork8')}</Paragraph>
            <PageVideo video={video33} />
            <Paragraph id="operatorWork9">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork9">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video34} />
            <Paragraph id="operatorWork10">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video35} />
            <Paragraph id="operatorWork11">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork11">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video36} />
            <Paragraph id="operatorWork12">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork12">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video37} />
            <Paragraph id="operatorWork13">
                <Trans i18nKey="hlp:helpPages.operatorWork.operatorWork13">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video38} />
        </Body>
    );
};

OperatorWork.propTypes = {};

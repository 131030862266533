import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import { SvgBtSearch, Input } from '@flomni/components';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE, routes } from '../../configs/routes';
import { SearchPageResults } from '../search-page-results';
import axios from 'axios';
import { URL } from '../editor/constants';

export const Main = () => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const searchContainer = useRef();
    const history = useHistory();
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        axios.get(URL.PUBLIC_MENU).then((response) => {
            setMenu(response.data)
        })
    }, []);

    const Card = ({ title, description, image, url, isDisabled }) => {
        const card = (
            <div className={css(['card', isDisabled ? '--disabled' : null])}>
                <div className={css('card-image-container')}>
                    <div className={css(['card-image', image])} />
                </div>
                <div className={css('card-title')}>{title}</div>
                <div className={css('card-description')}>{description}</div>
            </div>
        );
        return isDisabled ? card : <Link to={url || '/'}>{card}</Link>;
    };

    const Cards = useMemo(() => {
        const resultsRender = [];
        const toCamelCase = (str) => {
            return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        };

        if (menu?.tree?.length) {
            const menuTree = menu.tree.filter((item) => !['tasks'].includes(item.slug));

            for (var i = 0; i < menuTree.length; i += 3) {
                resultsRender.push(
                    <div className={css('line')} key={i}>
                    {
                        menuTree.slice(i, i + 3).map((item) => (
                            <Card
                                key={item.slug}
                                title={t(`hlp:${toCamelCase(item.slug.trim())}_m`)}
                                description={t(`hlp:${item.slug.trim()}Description`)}
                                image={`--${item.slug.trim()}`}
                                url={`/pages/${item.slug}`}
                            />
                        ))
                    }
                    </div>
                );
            }
        }

        return (
            <div className={css('cards')}>
                {resultsRender}
            </div>
        );
    }, [menu?.tree, i18n.language]);

    const getResult = useCallback(async () => {
        const result = await axios.get(`${URL.SEARCH}/?query=${search}&language=${i18n.language}`);
        return result.data.pages.map((item) => {
            const indexes = [];
            const lowerKey = search.toLowerCase();
            const matches = item.title.toLowerCase().matchAll(search.toLowerCase());
            for (const match of matches) {
                indexes.push({
                    start: match.index,
                    end: match.index + lowerKey.length
                });
            }
            return { ...item, indexes };
        });
    }, [search, i18n.language]);

    const onKeyUp = async (e) => {
        if (e.keyCode === 13 && search) {
            history.push(`${routes[ROUTE.SEARCH]}?key=${search}`);
        } else {
            const items = search ? await getResult() : null;
            setSearchResult(search ? { items, key: search } : null);
        }
    };

    return (
        <div className={css('main')}>
            <div className={css('header')}>
                <div className={css('title')}>{t('hlp:howCanWeHelp')}</div>
                <div className={css('search-line')} ref={searchContainer}>
                    <SvgBtSearch />
                    <Input
                        onKeyUp={onKeyUp}
                        classes={{ root: css('search-input-root'), input: css('search-input') }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('hlp:searchPlaceholder')}
                    />
                </div>
                {searchResult && (
                    <div className={css('search-results')}>
                        <SearchPageResults
                            searchContainer={searchContainer}
                            result={searchResult}
                            clearResults={() => setSearchResult(null)}
                        />
                    </div>
                )}
                <div className={css('quick-search')}>
                    <div>{t('hlp:commonTroubleshootingTopics')}:</div>
                    <div className={css('quick-search-links')}>
                        <Link to={`${routes[ROUTE.SEARCH]}?key=${t('hlp:chatBot').toLowerCase()}`}>
                            {t('hlp:chatBot').toLowerCase()}
                        </Link>
                        ,&nbsp;
                        <Link to={`${routes[ROUTE.SEARCH]}?key=${t('hlp:chatDistribution').toLowerCase()}`}>
                            {t('hlp:chatDistribution').toLowerCase()}
                        </Link>
                        ,&nbsp;
                        <Link to={`${routes[ROUTE.SEARCH]}?key=${t('hlp:megabrainAi_m').toLowerCase()}`}>
                            {t('hlp:megabrainAi_m').toLowerCase()}
                        </Link>
                        ,&nbsp;
                        <Link to={`${routes[ROUTE.SEARCH]}?key=${t('hlp:analytics_m').toLowerCase()}`}>
                            {t('hlp:analytics_m').toLowerCase()}
                        </Link>
                    </div>
                </div>
            </div>

            { Cards }
        </div>
    );
};

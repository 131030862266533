import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const EntityRecognition = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:entityRecognition');

    return (
        <Body>
            <Title>{t('hlp:entityRecognition')}</Title>
            <Header id="entityRecognition1">{t('hlp:helpPages.entityRecognition.entityRecognition1')}</Header>
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition3')}</Paragraph>
            <PageVideo video={video9} />
            <Header id="entityRecognition4">{t('hlp:helpPages.entityRecognition.entityRecognition4')}</Header>
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition5')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition6')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition7')}</Paragraph>
            <PageVideo video={video12} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition8')}</Paragraph>
            <PageVideo video={video13} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition9')}</Paragraph>
            <PageVideo video={video14} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition10')}</Paragraph>
            <PageVideo video={video15} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition11')}</Paragraph>
            <PageVideo video={video16} />
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition12')}</Paragraph>
            <PageVideo video={video17} />
            <Header id="entityRecognition13">
                {t('hlp:helpPages.entityRecognition.entityRecognition13')}
            </Header>
            <Paragraph>{t('hlp:helpPages.entityRecognition.entityRecognition14')}</Paragraph>
            <PageVideo video={video18} />
        </Body>
    );
};

EntityRecognition.propTypes = {};

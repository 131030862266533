import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SystemVariables = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:systemVariables');

    return (
        <Body>
            <Title>{t('hlp:systemVariables')}</Title>
            <Header id="systemVariables1">{t('hlp:helpPages.systemVariables.systemVariables1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables2')}</ListItem>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables3')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.systemVariables.systemVariables4')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="systemVariables5">{t('hlp:helpPages.systemVariables.systemVariables5')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables6')}</ListItem>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables7')}</ListItem>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables8')}</ListItem>
                <ListItem>{t('hlp:helpPages.systemVariables.systemVariables9')}</ListItem>
            </List>
        </Body>
    );
};

SystemVariables.propTypes = {};

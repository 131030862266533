const agentGroupsItems = [
    {
        id: 'agentGroups1',
        title: 'helpPages.agentGroups.agentGroups1'
    },
    {
        id: 'agentGroups3',
        title: 'helpPages.agentGroups.agentGroups3'
    },
    {
        id: 'agentGroups13',
        title: 'helpPages.agentGroups.agentGroups13'
    },
    {
        id: 'agentGroups16',
        title: 'helpPages.agentGroups.agentGroups16'
    }
];

export default agentGroupsItems;

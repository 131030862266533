import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const BuiltInChat = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:builtInChat')

    return (
        <Body>
            <Title>{t('hlp:builtInChat')}</Title>
            <Header id="builtInChat1">{t('hlp:helpPages.builtInChat.builtInChat1')}</Header>
            <Paragraph>{t('hlp:helpPages.builtInChat.builtInChat2')}</Paragraph>
            <PageVideo video={video16} />
            <Header id="builtInChat3">{t('hlp:helpPages.builtInChat.builtInChat3')}</Header>
            <Paragraph>{t('hlp:helpPages.builtInChat.builtInChat4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat5')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat6')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat7')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat8')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat9')}</ListItem>
                <ListItem>{t('hlp:helpPages.builtInChat.builtInChat10')}</ListItem>
            </List>
            <PageVideo video={video17} />
        </Body>
    );
};

BuiltInChat.propTypes = {};

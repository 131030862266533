import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ArtificialIntelligence = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:artificialIntelligence');

    return (
        <Body>
            <Title>{t('hlp:artificialIntelligence')}</Title>
            <Paragraph id="artificialIntelligence1">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence1">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <Header id="artificialIntelligence2">
                {t('hlp:helpPages.artificialIntelligence.artificialIntelligence2')}
            </Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence3">
                        0<Link to={routes[ROUTE.MEGABRAIN_OVERVIEW]} className={css('link')} />
                        1<Link to={routes[ROUTE.ADDING_MESSAGES]} className={css('link')} />
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence4">
                        0<Link to={routes[ROUTE.RECOGNITION_NODE]} className={css('link')} />
                    </Trans>
                </ListItem>
            </List>
            <Header id="artificialIntelligence5">
                {t('hlp:helpPages.artificialIntelligence.artificialIntelligence5')}
            </Header>
            <Paragraph id="artificialIntelligence6">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence6">
                    0<Link to={routes[ROUTE.RECOGNITION_NODE]} className={css('link')} />
                    1<Link to={routes[ROUTE.STORY_TRAINING]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence7')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph id="artificialIntelligence8">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence8">
                    0<Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence9')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence10')}</Paragraph>
            <Header id="artificialIntelligence11">
                {t('hlp:helpPages.artificialIntelligence.artificialIntelligence11')}
            </Header>
            <Header2 id="artificialIntelligence12">
                {t('hlp:helpPages.artificialIntelligence.artificialIntelligence12')}
            </Header2>
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence13')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence14')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence15')}</Paragraph>
            <PageVideo video={video7} />
            <Header2 id="artificialIntelligence16">
                {t('hlp:helpPages.artificialIntelligence.artificialIntelligence16')}
            </Header2>
            <Paragraph>{t('hlp:helpPages.artificialIntelligence.artificialIntelligence17')}</Paragraph>
            <Paragraph id="artificialIntelligence18">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence18">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <Paragraph id="artificialIntelligence19">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence19">
                    0<Link to={routes[ROUTE.INCOMING_MESSAGES_SET]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
            <Paragraph id="artificialIntelligence20">
                <Trans i18nKey="hlp:helpPages.artificialIntelligence.artificialIntelligence20">
                    0<Link to={routes[ROUTE.INCOMING_MESSAGES_SET]} className={css('link')} />
                </Trans>
            </Paragraph>
        </Body>
    );
};

ArtificialIntelligence.propTypes = {};

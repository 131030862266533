import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const DialogsOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:dialogsOverview');

    return (
        <Body>
            <Title>{t('hlp:dialogsOverview')}</Title>
            <Header id="dialogsOverview1">{t('hlp:helpPages.dialogsOverview.dialogsOverview1')}</Header>
            <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview2')}</Paragraph>
            <Header id="dialogsOverview3">{t('hlp:helpPages.dialogsOverview.dialogsOverview3')}</Header>
            <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview5')}</Paragraph>
            <Paragraph id="dialogsOverview6">
                <Trans i18nKey="hlp:helpPages.dialogsOverview.dialogsOverview6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph id="dialogsOverview7">
                <Trans i18nKey="hlp:helpPages.dialogsOverview.dialogsOverview7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview8')}</Paragraph>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview9')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview10')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview11')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview12')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview13')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview14')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview15')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview16')}</Paragraph>
                </ListItem>
                <PageVideo video={video4} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview17')}</Paragraph>
                </ListItem>
                <PageVideo video={video5} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview18')}</Paragraph>
                </ListItem>
                <PageVideo video={video6} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview19')}</Paragraph>
                </ListItem>
                <PageVideo video={video7} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview20')}</Paragraph>
                </ListItem>
                <PageVideo video={video8} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview21')}</Paragraph>
                </ListItem>
                <PageVideo video={video9} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview22')}</Paragraph>
                </ListItem>
                <PageVideo video={video10} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview23')}</Paragraph>
                </ListItem>
                <PageVideo video={video11} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview24')}</Paragraph>
                </ListItem>
                <PageVideo video={video12} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.dialogsOverview.dialogsOverview25')}</Paragraph>
                </ListItem>
                <PageVideo video={video13} />
            </List>
        </Body>
    );
};

DialogsOverview.propTypes = {};

const messageMarkupSubItems = [
    {
        id: 'messageMarkupSub1',
        title: 'helpPages.messageMarkupSub.messageMarkupSub1'
    },
    {
        id: 'messageMarkupSub6',
        title: 'helpPages.messageMarkupSub.messageMarkupSub6'
    },
    {
        id: 'messageMarkupSub10',
        title: 'helpPages.messageMarkupSub.messageMarkupSub10'
    },
    {
        id: 'messageMarkupSub15',
        title: 'helpPages.messageMarkupSub.messageMarkupSub15'
    }
];

export default messageMarkupSubItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SearchServiceOverview = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:searchServiceOverview');

    return (
        <Body>
            <Title>{t('hlp:searchServiceOverview')}</Title>
            <Header id="searchServiceOverview1">
                {t('hlp:helpPages.searchServiceOverview.searchServiceOverview1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview3')}</Paragraph>
            <PageVideo video={video9} />
            <Header id="searchServiceOverview4">
                {t('hlp:helpPages.searchServiceOverview.searchServiceOverview4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview5')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview8')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video10} />
            <Header id="searchServiceOverview9">
                {t('hlp:helpPages.searchServiceOverview.searchServiceOverview9')}
            </Header>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.searchServiceOverview.searchServiceOverview12')}</Paragraph>
            <PageVideo video={video11} />
        </Body>
    );
};

SearchServiceOverview.propTypes = {};

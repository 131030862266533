const multibranchChatbotItems = [
    {
        id: 'multibranchChatbot1',
        title: 'helpPages.multibranchChatbot.multibranchChatbot1'
    },
    {
        id: 'multibranchChatbot5',
        title: 'helpPages.multibranchChatbot.multibranchChatbot5'
    },
    {
        id: 'multibranchChatbot9',
        title: 'helpPages.multibranchChatbot.multibranchChatbot9'
    }
];
 
export default multibranchChatbotItems;

const howToPreventTextEntryItems = [
    {
        id: 'howToPreventTextEntry1',
        title: 'helpPages.howToPreventTextEntry.howToPreventTextEntry1'
    },
    {
        id: 'howToPreventTextEntry3',
        title: 'helpPages.howToPreventTextEntry.howToPreventTextEntry3'
    }
];

export default howToPreventTextEntryItems;

const messageTransportOverviewItems = [
    {
        id: 'messageTransportOverview1',
        title: 'helpPages.messageTransportOverview.messageTransportOverview1'
    },
    {
        id: 'messageTransportOverview4',
        title: 'helpPages.messageTransportOverview.messageTransportOverview4'
    }
];

export default messageTransportOverviewItems;

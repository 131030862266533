import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MegabrainAiOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:megabrainAiOverview');

    return (
        <Body>
            <Title>{t('hlp:megabrainAiOverview')}</Title>
            <Header id="megabrainAiOverview1">
                {t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview1')}
            </Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.megabrainAiOverview.megabrainAiOverview2">
                        <span className={css('bold')}>0</span>2
                    </Trans>
                </ListItem>
            </List>
            <Header id="megabrainAiOverview3">
                {t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview3')}
            </Header>
            <PageVideo video={video1} />
            <Header id="megabrainAiOverview4">
                {t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview6')}</Paragraph>
            <List>
                <ListItem>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview7')}</ListItem>
                <ListItem>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview8')}</ListItem>
                <ListItem>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview9')}</ListItem>
                <ListItem>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview10')}</ListItem>
                <ListItem>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview11')}</ListItem>
            </List>
            <Paragraph id="megabrainAiOverview12">
                <Trans i18nKey="hlp:helpPages.megabrainAiOverview.megabrainAiOverview12">
                    <Link to={routes[ROUTE.ENTITY_RECOGNITION]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph id="megabrainAiOverview13">
                <Trans i18nKey="hlp:helpPages.megabrainAiOverview.megabrainAiOverview13">
                    <Link to={routes[ROUTE.TOPIC_RECOGNITION]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph id="megabrainAiOverview14">
                <Trans i18nKey="hlp:helpPages.megabrainAiOverview.megabrainAiOverview14">
                    <Link to={routes[ROUTE.ENTITY_RECOGNITION]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph id="megabrainAiOverview15">
                <Trans i18nKey="hlp:helpPages.megabrainAiOverview.megabrainAiOverview15">
                    <Link to={routes[ROUTE.TRAINING_SET_CREATION]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview16')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview17')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.megabrainAiOverview.megabrainAiOverview18')}</Paragraph>
        </Body>
    );
};

MegabrainAiOverview.propTypes = {};

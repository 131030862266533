import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToSetUpBotStatisticsCollection = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToSetUpBotStatisticsCollection');

    return (
        <Body>
            <Title>{t('hlp:howToSetUpBotStatisticsCollection')}</Title>
            <Header id="howToSetUpBotStatisticsCollection1">
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection1')}
            </Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection2">
                        0
                        <Link to={routes[ROUTE.ANALYTICS]} className={css('link')}>
                            1
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection3')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection4')}
                </ListItem>
            </List>
            <Header id="howToSetUpBotStatisticsCollection5">
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection5')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection6')}
            </Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection7')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection8')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection9')}
                </ListItem>
                <PageVideo video={video1} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection10')}
                </ListItem>
                <PageVideo video={video2} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection11')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection12')}
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection13">
                        0
                        <Link to={routes[ROUTE.ANALYTICS]} className={css('link')}>
                            1
                        </Link>
                    </Trans>
                </ListItem>
            </List>
            <Header id="howToSetUpBotStatisticsCollection14">
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection14')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection15')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection16')}
            </Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection17')}
                </ListItem>
                <PageVideo video={video3} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection18')}
                    <List orderedList>
                        <ListItem>
                            {t(
                                'hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection19'
                            )}
                        </ListItem>
                        <ListItem>
                            {t(
                                'hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection20'
                            )}
                        </ListItem>
                        <ListItem>
                            {t(
                                'hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection21'
                            )}
                        </ListItem>
                    </List>
                </ListItem>
                <PageVideo video={video4} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection22')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection23')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection24')}
                </ListItem>
                <PageVideo video={video5} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection25')}
                </ListItem>
                <PageVideo video={video6} />
                <ListItem>
                    {t('hlp:helpPages.howToSetUpBotStatisticsCollection.howToSetUpBotStatisticsCollection26')}
                </ListItem>
                <PageVideo video={video7} />
            </List>
        </Body>
    );
};

HowToSetUpBotStatisticsCollection.propTypes = {};

const chatArchiveItems = [
    {
        id: 'chatArchive1',
        title: 'helpPages.chatArchive.chatArchive1'
    },
    {
        id: 'chatArchive3',
        title: 'helpPages.chatArchive.chatArchive3'
    },
    {
        id: 'chatArchive6',
        title: 'helpPages.chatArchive.chatArchive6'
    },
    {
        id: 'chatArchive14',
        title: 'helpPages.chatArchive.chatArchive14'
    }
];

export default chatArchiveItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import useStateRef from '../../hooks/useStateRef';
import { object, string } from 'prop-types';
import axios from 'axios';
import { KIND, URL } from '../editor/constants';

export const Tree = ({ container, mainClass }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [openedList, setOpenedList] = useState([]);
    const [, setMenuContainer, menuContainerRef] = useStateRef(null);
    const [menuFixedWidth, setIsMenuFixedWidth] = useState(null);
    const [menu, setMenu] = useState(null);
    const pathname = history.location.pathname;

    useEffect(() => {
        axios.get(URL.PUBLIC_MENU).then((response) => {
            setMenu(response.data)
        })
    }, []);

    useEffect(() => {
        const opened = [];
        const findGroup = (items, path) => {
            items.forEach((item) => {
                if (item.kind === KIND.GROUP && pathname.includes(`${path}${item.slug}`)) {
                    opened.push(item.slug);
                    item.items?.length && findGroup(item.items, `${path}${item.slug}/`)
                }
            })
        }
        menu?.tree?.length && findGroup(menu.tree, '/pages/');
        setOpenedList(opened);
    }, [menu]);

    const onScroll = useCallback(() => {
        const rect = menuContainerRef.current.getBoundingClientRect();
        setIsMenuFixedWidth(rect.top < 25 ? rect.width : null);
    }, [menuContainerRef]);

    useEffect(() => {
        container && container.addEventListener('scroll', onScroll);
        return () => {
            container && container.removeEventListener('scroll', onScroll);
        };
    }, [container, onScroll]);

    const MenuItem = ({ name, items, rootClass, index, slug, kind, pageId }) => {
        const isOpened = openedList.includes(slug);

        const handlerClickItem = (e) => {
            e.stopPropagation();
            if (items.length) {
                setOpenedList(isOpened ? openedList.filter((n) => n !== slug) : [...openedList, slug]);
            }
        };

        if (!name) {
            return null
        }
        return (
            <div
                className={css([
                    'menu-item',
                    items.length ? '--main' : null,
                    rootClass || null,
                    index === 0 ? '--first' : null
                ])}
                onClick={handlerClickItem}
            >
                <div className={css(['menu-item-header', items.length ? '--openable' : null])}>
                    {kind === KIND.GROUP && (
                        <div className={css('menu-item-header-title')}>
                            <div className={css('menu-item-arrow')}>
                                {!isOpened && (
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.00002 11.2917L11.0001 7.29171L7.00002 3.29167"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.0001 7.29175L2.99998 7.29175"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                                {isOpened && (
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.70831 6.99996L6.70835 11L10.7084 6.99996"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.70837 11L6.70837 2.99992"
                                            strokeWidth="1.60002"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span>{t(`hlp:${name}`)}</span>
                        </div>
                    )}
                    {kind === KIND.PAGE_LINK && (
                        <NavLink
                            activeClassName={css('menu-item-active')}
                            to={`/pages/${menu.relationsCache.byPageId[pageId]}`}
                        >
                            {t(`hlp:${name}`)}
                        </NavLink>
                    )}
                </div>
                {items.length > 0 &&
                    isOpened &&
                    items.map((item, index) => (
                        <MenuItem key={item.pageId || item.id} name={item.title[i18n.language]} items={item.items || []} index={index} slug={item.slug} kind={item.kind} pageId={item.pageId} />
                    ))}
            </div>
        );
    };

    return (
        <div ref={(node) => setMenuContainer(node)} className={mainClass}>
            <div
                className={css(menuFixedWidth ? 'main-fixed' : null)}
                style={{ width: menuFixedWidth ? menuFixedWidth + 'px' : '100%' }}
            >
                {menu?.tree?.map((item, index) => (
                    <MenuItem
                        key={item.pageId || item.id}
                        name={item.title[i18n.language]}
                        items={item.items || []}
                        index={index}
                        rootClass="--top"
                        slug={item.slug}
                        kind={item.kind}
                        pageId={item.pageId}
                    />
                ))}
            </div>
        </div>
    );
};

Tree.propTypes = {
    container: object,
    mainClass: string
};

const accountOverviewItems = [
    {
        id: 'accountOverview1',
        title: 'helpPages.accountOverview.accountOverview1'
    },
    {
        id: 'accountOverview3',
        title: 'helpPages.accountOverview.accountOverview3'
    }
];

export default accountOverviewItems;

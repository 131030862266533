import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import video34 from './assets/video34.mov';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import video39 from './assets/video39.mov';
import video40 from './assets/video40.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const OperatorsTeam = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:operatorsTeam');

    return (
        <Body>
            <Title>{t('hlp:operatorsTeam')}</Title>
            <Header id="operatorsTeam1">{t('hlp:helpPages.operatorsTeam.operatorsTeam1')}</Header>
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam3')}</Paragraph>
            <PageVideo video={video32} />
            <Header id="operatorsTeam4">{t('hlp:helpPages.operatorsTeam.operatorsTeam4')}</Header>
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam5')}</Paragraph>
            <PageVideo video={video33} />
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam6')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.operatorsTeam.operatorsTeam7')}</ListItem>
                <PageVideo video={video34} />
                <ListItem>{t('hlp:helpPages.operatorsTeam.operatorsTeam8')}</ListItem>
                <PageVideo video={video35} />
            </List>
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam9')}</Paragraph>
            <PageVideo video={video36} />
            <Header id="operatorsTeam10">{t('hlp:helpPages.operatorsTeam.operatorsTeam10')}</Header>
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam11')}</Paragraph>
            <PageVideo video={video37} />
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam12')}</Paragraph>
            <PageVideo video={video38} />
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam13')}</Paragraph>
            <PageVideo video={video39} />
            <Paragraph>{t('hlp:helpPages.operatorsTeam.operatorsTeam14')}</Paragraph>
            <PageVideo video={video40} />
        </Body>
    );
};

OperatorsTeam.propTypes = {};

const firstChatbotItems = [
    {
        id: 'firstChatBot2',
        title: 'helpPages.buildYourFirstChatbot.firstChatBot2'
    },
    {
        id: 'firstChatBot9',
        title: 'helpPages.buildYourFirstChatbot.firstChatBot9'
    }
];

export default firstChatbotItems;

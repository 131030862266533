const chatHintsItems = [
    {
        id: 'chatHints1',
        title: 'helpPages.chatHints.chatHints1'
    },
    {
        id: 'chatHints8',
        title: 'helpPages.chatHints.chatHints8'
    },
    {
        id: 'chatHints18',
        title: 'helpPages.chatHints.chatHints18'
    }
];

export default chatHintsItems;

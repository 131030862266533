const howToUseVariablesItems = [
    {
        id: 'howToUseVariables0',
        title: 'helpPages.howToUseVariables.howToUseVariables0'
    },
    {
        id: 'howToUseVariables11',
        title: 'helpPages.howToUseVariables.howToUseVariables11'
    },
    {
        id: 'howToUseVariables13',
        title: 'helpPages.howToUseVariables.howToUseVariables13'
    }
];

export default howToUseVariablesItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video14 from './assets/video14.mov';
import usePageAccess from '../../../../hooks/usePageAccess';


export const AccountConfiguration = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:accountConfiguration')

    return (
        <Body>
            <Title>{t('hlp:accountConfiguration')}</Title>
            <Header id="accountConfiguration1">{t('hlp:helpPages.accountConfiguration.accountConfiguration1')}</Header>
            <Paragraph>{t('hlp:helpPages.accountConfiguration.accountConfiguration2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.accountConfiguration.accountConfiguration3')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.accountConfiguration.accountConfiguration4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.accountConfiguration.accountConfiguration5')}</Paragraph>
            <PageVideo video={video14} />
        </Body>
    );
};

AccountConfiguration.propTypes = {};

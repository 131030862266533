import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video22 from './assets/video22.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const EmotionRecognition = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:emotionRecognition');

    return (
        <Body>
            <Title>{t('hlp:emotionRecognition')}</Title>
            <Header id="emotionRecognition1">
                {t('hlp:helpPages.emotionRecognition.emotionRecognition1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.emotionRecognition.emotionRecognition2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.emotionRecognition.emotionRecognition3')}</Paragraph>
            <PageVideo video={video22} />
            <Paragraph>{t('hlp:helpPages.emotionRecognition.emotionRecognition4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.emotionRecognition.emotionRecognition5')}</Paragraph>
        </Body>
    );
};

EmotionRecognition.propTypes = {};

const builtInLinkerItems = [
    {
        id: 'builtInLinker1',
        title: 'helpPages.builtInLinker.builtInLinker1'
    },
    {
        id: 'builtInLinker3',
        title: 'helpPages.builtInLinker.builtInLinker3'
    }
];

export default builtInLinkerItems;

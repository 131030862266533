const howToInsertAndApplyDataFromClientNotification = [
    {
        id: 'howToInsertAndApplyDataFromClientNotification1',
        title: 'helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification1'
    },
    {
        id: 'howToInsertAndApplyDataFromClientNotification6',
        title: 'helpPages.howToInsertAndApplyDataFromClientNotification.howToInsertAndApplyDataFromClientNotification6'
    }
];

export default howToInsertAndApplyDataFromClientNotification;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const JsNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:jsNode');

    return (
        <Body>
            <Title>{t('hlp:jsNode')}</Title>
            <Header id="jsNode1">{t('hlp:helpPages.jsNode.jsNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.jsNode.jsNode2')}</ListItem>
            </List>
            <Header id="jsNode3">{t('hlp:helpPages.jsNode.jsNode3')}</Header>
            <PageVideo video={video1} />
            <Header id="jsNode4">{t('hlp:helpPages.jsNode.jsNode4')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.jsNode.jsNode5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.jsNode.jsNode6')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.jsNode.jsNode7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.jsNode.jsNode8')}</Paragraph>
            <PageVideo video={video2} />
        </Body>
    );
};

JsNode.propTypes = {};

const searchServiceOverviewItems = [
    {
        id: 'searchServiceOverview1',
        title: 'helpPages.searchServiceOverview.searchServiceOverview1'
    },
    {
        id: 'searchServiceOverview4',
        title: 'helpPages.searchServiceOverview.searchServiceOverview4'
    },
    {
        id: 'searchServiceOverview9',
        title: 'helpPages.searchServiceOverview.searchServiceOverview9'
    },
];

export default searchServiceOverviewItems;

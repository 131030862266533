const actionChainNodeItems = [
    {
        id: 'actionChainNode1',
        title: 'helpPages.actionChainNode.actionChainNode1'
    },
    {
        id: 'actionChainNode5',
        title: 'helpPages.actionChainNode.actionChainNode5'
    },
    {
        id: 'actionChainNode6',
        title: 'helpPages.actionChainNode.actionChainNode6'
    },
    {
        id: 'actionChainNode14',
        title: 'helpPages.actionChainNode.actionChainNode14'
    }
];

export default actionChainNodeItems;

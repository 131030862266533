import React, { useContext } from 'react';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ControlContainer,
    Input,
    Popup,
    PopupButtons,
    PopupContent,
    PopupTitle
} from '@flomni/components';
import { func } from 'prop-types';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { store } from '../../../../state/state';

const FormMessage = ({ onSend, onCancel }) => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    const { dispatch } = useContext(store);

    const { register, formState, errors, getValues } = useForm({
        mode: 'onChange'
    });

    const handlerClickSend = async (event) => {
        event.preventDefault();
        const form = getValues();
        dispatch({ type: 'setIsShowGlobalLoader', show: true });
        try {
            await axios.post(`https://${process.env.REACT_APP_BACK_HOST}/user/application`, {
                message: form.message
            });
            dispatch({ type: 'showSuccessMessage', message: t('hlp:messageSentNotification') });
            onSend();
        } catch {
            dispatch({ type: 'showWarningMessage', message: t('hlp:messageSentError') });
        } finally {
            dispatch({ type: 'setIsShowGlobalLoader', show: false });
        }
    };

    const handlerClickOnCancel = (event) => {
        event.preventDefault();
        onCancel();
    };

    return (
        <Popup open={true} width="extended">
            <PopupTitle>{t('hlp:itFormYouKnowWhatToDo')}</PopupTitle>
            <form>
                <PopupContent>
                    <div className={css('title')}>{t('hlp:message')}</div>
                    <div className={css('description')}>{t('hlp:messageFormDescription')}</div>
                    <ControlContainer warning={errors.message ? t('hlp:messageRequired') : null}>
                        <Input
                            autoFocus
                            textArea
                            classes={{ root: css('field') }}
                            placeholder={t('hlp:typeMessageHere')}
                            warning={!!errors.message}
                            name="message"
                            ref={register({ required: true })}
                        />
                    </ControlContainer>
                </PopupContent>
                <PopupButtons>
                    <Button
                        view="success"
                        size="small"
                        onClick={handlerClickSend}
                        disabled={!formState.isValid || !formState.isDirty}
                    >
                        {t('hlp:sendMessage')}
                    </Button>
                    <Button view="cancel" size="small" onClick={handlerClickOnCancel}>
                        {t('hlp:cancel')}
                    </Button>
                </PopupButtons>
            </form>
        </Popup>
    );
};

FormMessage.propTypes = {
    onSend: func.isRequired,
    onCancel: func.isRequired
};

export default FormMessage;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video27 from './assets/video27.mov';
import video28 from './assets/video28.mov';
import video29 from './assets/video29.mov';
import video30 from './assets/video30.mov';
import video31 from './assets/video31.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatDialogSortAndFilter = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatDialogSortAndFilter');

    return (
        <Body>
            <Title>{t('hlp:chatDialogSortAndFilter')}</Title>
            <Header id="chatDialogSortAndFilter1">
                {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter2')}</Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter3')}
                    <List orderedList>
                        <ListItem>
                            {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter4')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter5')}
                        </ListItem>
                    </List>
                </ListItem>
                <PageVideo video={video27} />
                <ListItem>
                    {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter6')}
                    <List orderedList>
                        <ListItem>
                            {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter7')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter8')}
                        </ListItem>
                    </List>
                </ListItem>
                <PageVideo video={video28} />
            </List>
            <Paragraph>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter9')}</Paragraph>
            <PageVideo video={video29} />
            <Header id="chatDialogSortAndFilter10">
                {t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter10')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter11')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter12')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter13')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter14')}</ListItem>
            </List>
            <PageVideo video={video30} />
            <Paragraph>{t('hlp:helpPages.chatDialogSortAndFilter.chatDialogSortAndFilter15')}</Paragraph>
            <PageVideo video={video31} />
        </Body>
    );
};

ChatDialogSortAndFilter.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { func } from 'prop-types';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const InstallChatbotOnYourWebsite = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:installChatbotOnYourWebsite');

    return (
        <Body>
            <Title>{t('hlp:installChatbotOnYourWebsite')}</Title>
            <Header id="installChatbotOnYourWebsite1">
                {t('hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite1')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite2')}
            </Paragraph>
            <Header id="installChatbotOnYourWebsite3">
                {t('hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite4">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite5">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite6">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite7">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite8')}
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite9">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <Header id="installChatbotOnYourWebsite10">
                {t('hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite10')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.installChatbotOnYourWebsite.installChatbotOnYourWebsite11">
                    0
                    <Link to={routes[ROUTE.BUILT_IN_CHAT]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
        </Body>
    );
};

InstallChatbotOnYourWebsite.propTypes = {
    openMessageForm: func
};

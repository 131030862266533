const myReportsItems = [
    {
        id: 'myReports1',
        title: 'helpPages.myReports.myReports1'
    },
    {
        id: 'myReports3',
        title: 'helpPages.myReports.myReports3'
    }
];

export default myReportsItems;

const agentStatusesItems = [
    {
        id: 'agentStatuses1',
        title: 'helpPages.agentStatuses.agentStatuses1'
    },
    {
        id: 'agentStatuses3',
        title: 'helpPages.agentStatuses.agentStatuses3'
    },
    {
        id: 'agentStatuses17',
        title: 'helpPages.agentStatuses.agentStatuses17'
    }
];

export default agentStatusesItems;

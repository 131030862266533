import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import video26 from './assets/video26.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatEditClientInfo = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatEditClientInfo');

    return (
        <Body>
            <Title>{t('hlp:chatEditClientInfo')}</Title>
            <Header id="chatEditClientInfo1">
                {t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo2')}</Paragraph>
            <PageVideo video={video24} />
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo3')}</Paragraph>
            <Header id="chatEditClientInfo4">
                {t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo5')}</Paragraph>
            <PageVideo video={video25} />
            <Header id="chatEditClientInfo6">
                {t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo6')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo7')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo8')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo9')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo10')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo11')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo12')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo13')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo14')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo15')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo16')}</Paragraph>
            <Header id="chatEditClientInfo17">
                {t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo17')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatEditClientInfo.chatEditClientInfo18')}</Paragraph>
            <PageVideo video={video26} />
        </Body>
    );
};

ChatEditClientInfo.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const RuleCreation = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:ruleCreation');

    return (
        <Body>
            <Title>{t('hlp:ruleCreation')}</Title>
            <Header id="ruleCreation1">{t('hlp:helpPages.ruleCreation.ruleCreation1')}</Header>
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation3')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="ruleCreation4">{t('hlp:helpPages.ruleCreation.ruleCreation4')}</Header>
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation5')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation6')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation7')}</Paragraph>
                </ListItem>
                <PageVideo video={video3} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation8')}</Paragraph>
                </ListItem>
                <PageVideo video={video4} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation9')}</Paragraph>
                </ListItem>
                <PageVideo video={video5} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation10')}</Paragraph>
                </ListItem>
                <PageVideo video={video6} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation11')}</Paragraph>
                </ListItem>
                <PageVideo video={video7} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation12')}</Paragraph>
                </ListItem>
                <PageVideo video={video8} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation13')}</Paragraph>
                </ListItem>
                <PageVideo video={video9} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation14')}</Paragraph>
                </ListItem>
                <PageVideo video={video10} />
                <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation15')}</Paragraph>
            </List>
            <Header id="ruleCreation16">{t('hlp:helpPages.ruleCreation.ruleCreation16')}</Header>
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation17')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation18')}</Paragraph>
            <PageVideo video={video12} />
            <Header id="ruleCreation19">{t('hlp:helpPages.ruleCreation.ruleCreation19')}</Header>
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation20')}</Paragraph>
            <PageVideo video={video13} />
            <Paragraph>{t('hlp:helpPages.ruleCreation.ruleCreation21')}</Paragraph>
            <PageVideo video={video14} />
        </Body>
    );
};

RuleCreation.propTypes = {};

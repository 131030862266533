const howToPersonalizeBotMenuInViber = [
    {
        id: 'howToPersonalizeBotMenuInViber1',
        title: 'helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber1'
    },
    {
        id: 'howToPersonalizeBotMenuInViber3',
        title: 'helpPages.howToPersonalizeBotMenuInViber.howToPersonalizeBotMenuInViber3'
    }
];

export default howToPersonalizeBotMenuInViber;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ServerUrlConfigAndWebhookEvents = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:serverUrlConfigAndWebhookEvents');

    return (
        <Body>
            <Title>{t('hlp:serverUrlConfigAndWebhookEvents')}</Title>
            <Header id="serverUrlConfigAndWebhookEvents1">
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents1')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents2')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents3')}
            </Paragraph>
            <PageVideo video={video1} />
            <Header id="serverUrlConfigAndWebhookEvents4">
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents4')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents5')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents6')}
            </Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents7')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents8')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents9')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents10')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents11')}
                </ListItem>
            </List>
            <PageVideo video={video2} />
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents12')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.serverUrlConfigAndWebhookEvents.serverUrlConfigAndWebhookEvents13')}
            </Paragraph>
            <a
                href="https://documenter.getpostman.com/view/1005345/S17owpY2?version=latest#intro"
                target="_blank"
                rel="noreferrer"
                className={css('link')}
            >
                https://documenter.getpostman.com/view/1005345/S17owpY2?version=latest#intro
            </a>
        </Body>
    );
};

ServerUrlConfigAndWebhookEvents.propTypes = {};

const reportScheduleItems = [
    {
        id: 'reportSchedule1',
        title: 'helpPages.reportSchedule.reportSchedule1'
    },
    {
        id: 'reportSchedule3',
        title: 'helpPages.reportSchedule.reportSchedule3'
    },
    {
        id: 'reportSchedule12',
        title: 'helpPages.reportSchedule.reportSchedule12'
    }
];

export default reportScheduleItems;

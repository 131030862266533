import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatActions = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatActions')

    return (
        <Body>
            <Title>{t('hlp:chatActions')}</Title>
            <Header id="chatActions1">{t('hlp:helpPages.chatActions.chatActions1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions2')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions3')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions4')}</ListItem>
            </List>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions5')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions6')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions7')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions8')}</Paragraph>
            <PageVideo video={video6} />
            <Header id="chatActions9">{t('hlp:helpPages.chatActions.chatActions9')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatActions.chatActions10">
                    0
                    <Link to={routes[ROUTE.SESSIONS_TOPICS]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions11')}</Paragraph>
            <PageVideo video={video8} />
            <Header id="chatActions12">{t('hlp:helpPages.chatActions.chatActions12')}</Header>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions13')}</Paragraph>
            <PageVideo video={video9} />
            <Header id="chatActions14">{t('hlp:helpPages.chatActions.chatActions14')}</Header>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions15')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions16')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions17')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions18')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions19')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatActions.chatActions20')}</ListItem>
            </List>
            <PageVideo video={video10} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatActions.chatActions21">
                    0
                    <Link to={routes[ROUTE.REMINDERS]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <Header id="chatActions22">{t('hlp:helpPages.chatActions.chatActions22')}</Header>
            <Paragraph>{t('hlp:helpPages.chatActions.chatActions23')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatActions.chatActions24">
                    0
                    <Link to={routes[ROUTE.SESSIONS_TOPICS]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
        </Body>
    );
};

ChatActions.propTypes = {};

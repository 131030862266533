import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToUseJsWithBots = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToUseJsWithBots');

    return (
        <Body>
            <Title>{t('hlp:howToUseJsWithBots')}</Title>
            <Header id="howToUseJsWithBots1">
                {t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots1')}
            </Header>
            <List>
                <ListItem>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots2')}</ListItem>
                <ListItem>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots3')}</ListItem>
                <ListItem>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots4')}</ListItem>
            </List>
            <Header id="howToUseJsWithBots5">
                {t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots5')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots8')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots9')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots10')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots11')}</Paragraph>
            <Paragraph>return moment(userDate,'DD.MM.YYYY').format('YYYY-MM-DD')</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots12')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots13">
                    <span className={css(['bold', 'italic'])}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots14">
                    <span className={css(['bold', 'italic'])}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots15')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots16">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots17')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots19')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots20')}</Paragraph>
            <Paragraph>
                let parsed = phoneNum.match(/^\+?(7|8)(\d{10})$/)
                <br />
                console.log(parsed)
                <br />
                {'if (parsed) return `+7${parsed[2]}`'}
            </Paragraph>
            <Paragraph>
                parsed = phoneNum.match(/^9(\d{9})$/)
                <br />
                {'if (parsed) return `+79${parsed[1]}`'}
            </Paragraph>
            <Paragraph>return false</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots21')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots22">
                    <span className={css(['bold', 'italic'])}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots23">
                    <span className={css(['bold', 'italic'])}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.howToUseJsWithBots.howToUseJsWithBots24')}</Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

HowToUseJsWithBots.propTypes = {};

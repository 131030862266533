const chatbotErrorsItems = [
    {
        id: 'chatbotErrors1',
        title: 'helpPages.chatbotErrors.chatbotErrors1'
    },
    {
        id: 'chatbotErrors5',
        title: 'helpPages.chatbotErrors.chatbotErrors5'
    }
];

export default chatbotErrorsItems;

const howToUseMultiLanguagesInBotItems = [
    {
        id: 'howToUseMultiLanguagesInBot1',
        title: 'helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot1'
    },
    {
        id: 'howToUseMultiLanguagesInBot3',
        title: 'helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot3'
    }
];

export default howToUseMultiLanguagesInBotItems;

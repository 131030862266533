import React from 'react'
import { useTranslation } from 'react-i18next'

import cn from './index.module.scss'

export const EmptyDropZone = () => {
  const { t } = useTranslation()

  return (
    <div className={cn.root}>
      <div className={cn.body}>{t('hlp:dragPageOtherCategory')}</div>
    </div>
  )
}

EmptyDropZone.propTypes = {}

EmptyDropZone.defaultProps = {}

const howToStoreUserPhoneNumberIntoUserProfileItems = [
    {
        id: 'howToStoreUserPhoneNumberIntoUserProfile1',
        title: 'helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile1'
    },
    {
        id: 'howToStoreUserPhoneNumberIntoUserProfile3',
        title: 'helpPages.howToStoreUserPhoneNumberIntoUserProfile.howToStoreUserPhoneNumberIntoUserProfile3'
    }
];

export default howToStoreUserPhoneNumberIntoUserProfileItems;

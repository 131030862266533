const storageNodeItems = [
    {
        id: 'storageNode1',
        title: 'helpPages.storageNode.storageNode1'
    },
    {
        id: 'storageNode3',
        title: 'helpPages.storageNode.storageNode3'
    },
    {
        id: 'storageNode4',
        title: 'helpPages.storageNode.storageNode4'
    },
    {
        id: 'storageNode12',
        title: 'helpPages.storageNode.storageNode12'
    }
];

export default storageNodeItems;

const middlewareBranchesItems = [
    {
        id: 'middlewareBranches1',
        title: 'helpPages.middlewareBranches.middlewareBranches1'
    },
    {
        id: 'middlewareBranches5',
        title: 'helpPages.middlewareBranches.middlewareBranches5'
    }
];

export default middlewareBranchesItems;

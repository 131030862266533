import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessageTransportOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:messageTransportOverview');

    return (
        <Body>
            <Title>{t('hlp:messageTransportOverview')}</Title>
            <Header id="messageTransportOverview1">
                {t('hlp:helpPages.messageTransportOverview.messageTransportOverview1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview3')}</Paragraph>
            <Header id="messageTransportOverview4">
                {t('hlp:helpPages.messageTransportOverview.messageTransportOverview4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview6')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview8')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview9')}</Paragraph>
            {/* <PageVideo video={video2} /> */}
            <Paragraph id="messageTransportOverview10">
                <Trans i18nKey="hlp:helpPages.messageTransportOverview.messageTransportOverview10">
                    0<Link to={routes[ROUTE.MESSAGE_TRANSPORT_OVERVIEW]} className={css('link')} />
                </Trans>
            </Paragraph>
            {/* <PageVideo video={video3} /> */}
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageTransportOverview.messageTransportOverview12')}</Paragraph>
        </Body>
    );
};

MessageTransportOverview.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Diagnostics = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:diagnostics');

    return (
        <Body>
            <Title>{t('hlp:diagnostics')}</Title>
            <Header id="diagnostics1">{t('hlp:helpPages.diagnostics.diagnostics1')}</Header>
            <Paragraph>{t('hlp:helpPages.diagnostics.diagnostics2')}</Paragraph>
            <Header id="diagnostics3">{t('hlp:helpPages.diagnostics.diagnostics3')}</Header>
            <Paragraph>{t('hlp:helpPages.diagnostics.diagnostics4')}</Paragraph>
            <PageVideo video={video11} />
            <Header id="diagnostics5">{t('hlp:helpPages.diagnostics.diagnostics5')}</Header>
            <Paragraph>{t('hlp:helpPages.diagnostics.diagnostics6')}</Paragraph>
            <PageVideo video={video12} />
            <Header id="diagnostics7">{t('hlp:helpPages.diagnostics.diagnostics7')}</Header>
            <Paragraph>{t('hlp:helpPages.diagnostics.diagnostics8')}</Paragraph>
            <PageVideo video={video13} />
        </Body>
    );
};

Diagnostics.propTypes = {};

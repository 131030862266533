const chatbotBranchVersionHistoryItems = [
    {
        id: 'chatbotBranchVersionHistory1',
        title: 'helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory1'
    },
    {
        id: 'chatbotBranchVersionHistory4',
        title: 'helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory4'
    }
];

export default chatbotBranchVersionHistoryItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node } from 'prop-types';

export const Paragraph = ({ children }) => {
    const css = useStyles(styles);

    return <div className={css('text')}>{children}</div>;
};

Paragraph.propTypes = {
    children: node.isRequired
};

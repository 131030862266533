import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToFindWordOrPhraseInUsersMessage = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToFindWordOrPhraseInUsersMessage');

    return (
        <Body>
            <Title>{t('hlp:howToFindWordOrPhraseInUsersMessage')}</Title>
            <Header id="howToFindWordOrPhraseInUsersMessage1">
                {t('hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage1')}
            </Header>
            <List>
                <ListItem>
                    {t(
                        'hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage2'
                    )}
                </ListItem>
            </List>
            <Header id="howToFindWordOrPhraseInUsersMessage3">
                {t('hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage5">
                    0
                    <Link to={routes[ROUTE.CONDITION_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage6">
                    0<span className={css('bold')}>1</span>3
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage7">
                    0<span className={css('bold')}>1</span>3
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage8">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage9">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage10">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph>
                {t('hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage11')}
            </Paragraph>
            <PageVideo video={video8} />
            <Paragraph>
                {t('hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage12')}
            </Paragraph>
            <PageVideo video={video9} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage13">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
        </Body>
    );
};

HowToFindWordOrPhraseInUsersMessage.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotDraftDebugMode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotDraftDebugMode');

    return (
        <Body>
            <Title>{t('hlp:chatbotDraftDebugMode')}</Title>
            <Header id="chatbotDraftDebugMode1">
                {t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode1')}
            </Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode2')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode3')}</Paragraph>
                </ListItem>
            </List>
            <Header id="chatbotDraftDebugMode4">
                {t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode4')}
            </Header>
            <Paragraph id="chatbotDraftDebugMode5">
                <Trans i18nKey="hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode5">
                    0<span className={css('bold')}></span>
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
            <Paragraph>{t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode6')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph id="chatbotDraftDebugMode7">
                <Trans i18nKey="hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode7">
                    0<span className={css('bold')}></span>
                </Trans>
            </Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode8')}</Paragraph>
            <PageVideo video={video12} />
        </Body>
    );
};

ChatbotDraftDebugMode.propTypes = {};

const chatbotActionsItems = [
    {
        id: 'chatbotActions1',
        title: 'helpPages.chatbotActions.chatbotActions1'
    },
    {
        id: 'chatbotActions3',
        title: 'helpPages.chatbotActions.chatbotActions3'
    }
];

export default chatbotActionsItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const CustomerServiceAssessment = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:customerServiceAssessment');

    return (
        <Body>
            <Title>{t('hlp:customerServiceAssessment')}</Title>
            <Header id="customerServiceAssessment1">
                {t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment2')}</Paragraph>
            <PageVideo video={video12} />
            <Header id="customerServiceAssessment3">
                {t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment4')}</Paragraph>
            <PageVideo video={video13} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment6')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment9')}</Paragraph>
            <PageVideo video={video14} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment12')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.customerServiceAssessment.customerServiceAssessment13')}</Paragraph>
            <PageVideo video={video15} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment14">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video16} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment15">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video17} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment16">
                    <span className={css('bold')}>0</span>1<span className={css('bold')}>2</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video18} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment17">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video19} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.customerServiceAssessment.customerServiceAssessment18">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video20} />
        </Body>
    );
};

CustomerServiceAssessment.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node, string } from 'prop-types';
import { IconButton, SvgBtLink } from '@flomni/components';
import copy from 'copy-to-clipboard';
import { useContext } from 'react';
import { store } from '../../../../state/state';
import { useTranslation } from 'react-i18next';

export const Header = ({ id, children }) => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    const { dispatch } = useContext(store);

    const copyLink = () => {
        const url = `${window.location.origin}${window.location.pathname}?ref=${id}`;
        if (copy(url)) {
            dispatch({ type: 'showSuccessMessage', message: t('hlp:copied') });
        }
    };

    return (
        <div id={id} className={css('header')}>
            {id && (
                <div className={css('share')}>
                    <IconButton onClick={copyLink}>
                        <SvgBtLink />
                    </IconButton>
                </div>
            )}
            {children}
        </div>
    );
};

Header.propTypes = {
    children: node.isRequired,
    id: string.isRequired
};

const chatbotReleasesItems = [
    {
        id: 'chatbotReleases1',
        title: 'helpPages.chatbotReleases.chatbotReleases1'
    },
    {
        id: 'chatbotReleases6',
        title: 'helpPages.chatbotReleases.chatbotReleases6'
    }
];

export default chatbotReleasesItems;

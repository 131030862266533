const chatAttachmentsItems = [
    {
        id: 'chatAttachments1',
        title: 'helpPages.chatAttachments.chatAttachments1'
    },
    {
        id: 'chatAttachments5',
        title: 'helpPages.chatAttachments.chatAttachments5'
    },
    {
        id: 'chatAttachments8',
        title: 'helpPages.chatAttachments.chatAttachments8'
    },
    {
        id: 'chatAttachments10',
        title: 'helpPages.chatAttachments.chatAttachments10'
    }
];

export default chatAttachmentsItems;

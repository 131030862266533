import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video48 from './assets/video48.mov';
import video49 from './assets/video49.mov';
import video50 from './assets/video50.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotActions = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotActions');

    return (
        <Body>
            <Title>{t('hlp:chatbotActions')}</Title>
            <Header id="chatbotActions1">{t('hlp:helpPages.chatbotActions.chatbotActions1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions2')}</Paragraph>
            <PageVideo video={video48} />
            <Header id="chatbotActions3">{t('hlp:helpPages.chatbotActions.chatbotActions3')}</Header>
            <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions4')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions5')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions8')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions9')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video49} />
            <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions11')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotActions.chatbotActions12')}</Paragraph>
            <PageVideo video={video50} />
            <Paragraph id="chatbotActions">
                <Trans i18nKey="hlp:helpPages.chatbotActions.chatbotActions13">
                    0<Link to={routes[ROUTE.CHAT_ADD_ACTION]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
        </Body>
    );
};

ChatbotActions.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { func } from 'prop-types';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const FirstChatbot = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:buildYourFirstChatbot');

    return (
        <Body>
            <Title>{t('hlp:buildYourFirstChatbot')}</Title>
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot1')}</Paragraph>
            <Header id="firstChatBot2">{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot2')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot3')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot4">
                        0
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                        2<span className={css('bold')}>3</span>4
                        <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                            4
                        </Link>
                        6
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot5">
                        0
                        <Link to={routes[ROUTE.HOW_TO_CREATE_VARIABLES]} className={css('link')}>
                            1s
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot6">
                        0
                        <Link to={routes[ROUTE.FIRST_STEPS_WITH_NODES]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot8')}</Paragraph>
            <Header id="firstChatBot9">{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot9')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot10">
                    0
                    <a href="https://my.flomni.com/" target="_blank" rel="noreferrer" className={css('link')}>
                        1
                    </a>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot11')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot12')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot13">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot14')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot15')}</Paragraph>
            <PageVideo video={video6} />
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot16')}</Paragraph>
            <PageVideo video={video8} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot17">
                    0
                    <Link to={routes[ROUTE.STORAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.HOW_TO_USE_VARIABLES]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot18')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot19')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot20')}</Paragraph>
            <PageVideo video={video12} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.buildYourFirstChatbot.firstChatBot21">
                    0
                    <Link to={routes[ROUTE.CHATBOT_RELEASES]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.buildYourFirstChatbot.firstChatBot22')}</Paragraph>
        </Body>
    );
};

FirstChatbot.propTypes = {
    openMessageForm: func
};

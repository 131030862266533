import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const StopWords = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:stopWords');

    return (
        <Body>
            <Title>{t('hlp:stopWords')}</Title>
            <Header id="stopWords1">{t('hlp:helpPages.stopWords.stopWords1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.stopWords.stopWords2')}</ListItem>
                <ListItem>{t('hlp:helpPages.stopWords.stopWords3')}</ListItem>
            </List>
            <Header id="stopWords4">{t('hlp:helpPages.stopWords.stopWords4')}</Header>
            <Paragraph>{t('hlp:helpPages.stopWords.stopWords5')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>{t('hlp:helpPages.stopWords.stopWords6')}</Paragraph>
            <PageVideo video={video6} />
            <Header id="stopWords7">{t('hlp:helpPages.stopWords.stopWords7')}</Header>
            <Paragraph>{t('hlp:helpPages.stopWords.stopWords8')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.stopWords.stopWords9')}</Paragraph>
            <PageVideo video={video8} />
        </Body>
    );
};

StopWords.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ReportConfigurator = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:reportConfigurator');

    return (
        <Body>
            <Title>{t('hlp:reportConfigurator')}</Title>
            <Header id="reportConfigurator1">
                {t('hlp:helpPages.reportConfigurator.reportConfigurator1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator2')}</Paragraph>
            <PageVideo video={video10} />
            <Header id="reportConfigurator3">
                {t('hlp:helpPages.reportConfigurator.reportConfigurator3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator5')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator6')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator7')}</ListItem>
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator8')}</ListItem>
            </List>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator9')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator10')}</ListItem>
                <PageVideo video={video12} />
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator11')}</ListItem>
                <PageVideo video={video13} />
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator12')}</ListItem>
                <PageVideo video={video14} />
                <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator13')}</ListItem>
                <PageVideo video={video15} />
                <ListItem>
                    {t('hlp:helpPages.reportConfigurator.reportConfigurator14')}
                    <List orderedList>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator15')}</ListItem>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator16')}</ListItem>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator17')}</ListItem>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator18')}</ListItem>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator19')}</ListItem>
                        <ListItem>{t('hlp:helpPages.reportConfigurator.reportConfigurator20')}</ListItem>
                    </List>
                </ListItem>
            </List>
            <PageVideo video={video16} />
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator21')}</Paragraph>
            <Header id="reportConfigurator22">
                {t('hlp:helpPages.reportConfigurator.reportConfigurator22')}
            </Header>
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator23')}</Paragraph>
            <PageVideo video={video17} />
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator24')}</Paragraph>
            <PageVideo video={video18} />
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator25')}</Paragraph>
            <PageVideo video={video19} />
            <Paragraph>{t('hlp:helpPages.reportConfigurator.reportConfigurator26')}</Paragraph>
            <PageVideo video={video20} />
        </Body>
    );
};

ReportConfigurator.propTypes = {};

const messageTransportReportItems = [
    {
        id: 'messageTransportReport1',
        title: 'helpPages.messageTransportReport.messageTransportReport1'
    },
    {
        id: 'messageTransportReport3',
        title: 'helpPages.messageTransportReport.messageTransportReport3'
    }
];

export default messageTransportReportItems;

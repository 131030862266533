import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video31 from './assets/video31.mov';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AgentStatuses = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:agentStatuses')

    return (
        <Body>
            <Title>{t('hlp:agentStatuses')}</Title>
            <Header id="agentStatuses1">{t('hlp:helpPages.agentStatuses.agentStatuses1')}</Header>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses2')}</Paragraph>
            <PageVideo video={video31} />
            <Header id="agentStatuses3">{t('hlp:helpPages.agentStatuses.agentStatuses3')}</Header>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses6')}</Paragraph>
            <PageVideo video={video32} />
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses7')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses8')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses9')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses10')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses11')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses12')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses13')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses14')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses15')}</Paragraph>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses16')}</Paragraph>
            <PageVideo video={video33} />
            <Header id="agentStatuses17">{t('hlp:helpPages.agentStatuses.agentStatuses17')}</Header>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses19')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses20')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses21')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses22')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses23')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.agentStatuses.agentStatuses24')}</Paragraph>
        </Body>
    );
};

AgentStatuses.propTypes = {};

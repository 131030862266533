import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import video24 from './assets/video24.mov';
import video25 from './assets/video25.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessageMarkupSub = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:messageMarkupSub');

    return (
        <Body>
            <Title>{t('hlp:messageMarkupSub')}</Title>
            <Header id="messageMarkupSub1">{t('hlp:helpPages.messageMarkupSub.messageMarkupSub1')}</Header>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub3')}</Paragraph>
            <PageVideo video={video15} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub4')}</Paragraph>
            <PageVideo video={video16} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageMarkupSub.messageMarkupSub5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video17} />
            <Header id="messageMarkupSub6">{t('hlp:helpPages.messageMarkupSub.messageMarkupSub6')}</Header>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub8')}</Paragraph>
            <PageVideo video={video18} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub9')}</Paragraph>
            <PageVideo video={video19} />
            <Header id="messageMarkupSub10">{t('hlp:helpPages.messageMarkupSub.messageMarkupSub10')}</Header>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub11')}</Paragraph>
            <PageVideo video={video20} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub12')}</Paragraph>
            <PageVideo video={video21} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub13')}</Paragraph>
            <PageVideo video={video22} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageMarkupSub.messageMarkupSub14">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Header id="messageMarkupSub15">{t('hlp:helpPages.messageMarkupSub.messageMarkupSub15')}</Header>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub16')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub17')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub18')}</Paragraph>
            <PageVideo video={video23} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub19')}</Paragraph>
            <PageVideo video={video24} />
            <Paragraph>{t('hlp:helpPages.messageMarkupSub.messageMarkupSub20')}</Paragraph>
            <PageVideo video={video25} />
        </Body>
    );
};

MessageMarkupSub.propTypes = {};

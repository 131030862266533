import * as webStorage from './providers/webStorage'
import { LOCAL_STORAGE, SESSION_STORAGE } from './constants'

export * from './constants'

export const getLocalState = (provider, key, defaultValue = null) => {
  if ([LOCAL_STORAGE, SESSION_STORAGE].includes(provider)) {
    return webStorage.get(provider, key, defaultValue)
  }

  throw new Error('There is no the type of storage')
}

export const setLocalState = (provider, key, value, options = {}) => {
  if ([LOCAL_STORAGE, SESSION_STORAGE].includes(provider)) {
    return webStorage.set(provider, key, value)
  }

  throw new Error('There is no the type of storage')
}

export const removeLocalState = (provider, key) => {
  if ([LOCAL_STORAGE, SESSION_STORAGE].includes(provider)) {
    return webStorage.remove(provider, key)
  }

  throw new Error('There is no the type of storage')
}

export const hasLocalState = (provider, key, defaultValue) => {
  return Boolean(getLocalState(provider, key, defaultValue))
}

export const setUserCookie = (profile) => {
  const data = JSON.stringify({
    personName: profile.personName
  })
  document.cookie = `user=${data};path=/;domain=.flomni.com;max-age=86400;secure;samesite=strict`
}

export const removeUserCookie = () => {
  document.cookie = 'user=;max-age:-1'
}

const LocalState = {
  get: getLocalState,
  set: setLocalState,
  remove: removeLocalState,
  has: hasLocalState
}

export default LocalState

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotBranchVersionHistory = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotBranchVersionHistory');

    return (
        <Body>
            <Title>{t('hlp:chatbotBranchVersionHistory')}</Title>
            <Header id="chatbotBranchVersionHistory1">
                {t('hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory1')}
            </Header>
            <List>
                <ListItem>
                    <Paragraph>
                        {t('hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory2')}
                    </Paragraph>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory3">
                        0<Link to={routes[ROUTE.CHATBOT_RELEASES]} className={css('link')} />
                    </Trans>
                </ListItem>
            </List>
            <Header id="chatbotBranchVersionHistory4">
                {t('hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory4')}
            </Header>
            <Paragraph id="chatbotBranchVersionHistory5">
                <Trans i18nKey="hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory5">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
            <Paragraph id="chatbotBranchVersionHistory6">
                <Trans i18nKey="hlp:helpPages.chatbotBranchVersionHistory.chatbotBranchVersionHistory6">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video10} />
        </Body>
    );
};

ChatbotBranchVersionHistory.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Notifications = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:notifications');

    return (
        <Body>
            <Title>{t('hlp:notifications')}</Title>
            <Header id="notifications1">{t('hlp:helpPages.notifications.notifications1')}</Header>
            <Paragraph>{t('hlp:helpPages.notifications.notifications2')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.notifications.notifications3')}</ListItem>
                <ListItem>{t('hlp:helpPages.notifications.notifications4')}</ListItem>
                <PageVideo video={video8} />
                <ListItem>
                    {t('hlp:helpPages.notifications.notifications5')}
                    <List orderedList>
                        <ListItem>{t('hlp:helpPages.notifications.notifications6')}</ListItem>
                        <ListItem>{t('hlp:helpPages.notifications.notifications7')}</ListItem>
                        <ListItem>{t('hlp:helpPages.notifications.notifications8')}</ListItem>
                    </List>
                </ListItem>
                <ListItem>{t('hlp:helpPages.notifications.notifications9')}</ListItem>
                <PageVideo video={video9} />
            </List>
            <Header id="notifications10">{t('hlp:helpPages.notifications.notifications10')}</Header>
            <Paragraph>{t('hlp:helpPages.notifications.notifications11')}</Paragraph>
            <PageVideo video={video10} />
        </Body>
    );
};

Notifications.propTypes = {};

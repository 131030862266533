import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video13 from './assets/video13.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotDraftOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotDraftOverview');

    return (
        <Body>
            <Title>{t('hlp:chatbotDraftOverview')}</Title>
            <Header id="chatbotDraftOverview1">
                {t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview1')}
            </Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview2')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview3')}</Paragraph>
                </ListItem>
            </List>
            <Header id="chatbotDraftOverview4">
                {t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview4')}
            </Header>
            <Paragraph id="chatbotDraftOverview5">
                <Trans i18nKey="hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview5">
                    0<span className={css('bold')} />
                    1<Link to={routes[ROUTE.CHATBOT_ERRORS]} className={css('link')} />
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview6')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph id="chatbotDraftOverview7">
                <Trans i18nKey="hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview7">
                    0<span className={css('bold')} />
                    1<Link to={routes[ROUTE.CHATBOT_THREADS]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph id="chatbotDraftOverview8">
                <Trans i18nKey="hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview8">
                    0<Link to={routes[ROUTE.CHATBOT_THREADS]} className={css('link')} />
                    1<Link to={routes[ROUTE.CHATBOT_THREADS]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>{t('hlp:helpPages.chatbotDraftOverview.chatbotDraftOverview9')}</Paragraph>
            <PageVideo video={video13} />
        </Body>
    );
};

ChatbotDraftOverview.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageImage } from '../../shared/image';
import { PageVideo } from '../../shared/video';
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import image3 from './assets/image3.jpg';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MessageNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:messageNode');

    return (
        <Body>
            <Title>{t('hlp:messageNode')}</Title>
            <Header id="messageNode1">{t('hlp:helpPages.messageNode.messageNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode2')}</ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode3')}</ListItem>
            </List>
            <Header id="messageNode4">{t('hlp:helpPages.messageNode.messageNode4')}</Header>
            <PageVideo video={video1} />
            <Header id="messageNode5">{t('hlp:helpPages.messageNode.messageNode5')}</Header>
            <Header2 id="messageNode6">{t('hlp:helpPages.messageNode.messageNode6')}</Header2>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode7')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageNode.messageNode8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <List>
                <ListItem>
                    {t('hlp:helpPages.messageNode.messageNode9')}
                    <PageImage image={image1} width="20rem" />
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.messageNode.messageNode10')}
                    <PageImage image={image2} width="20rem" />
                </ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode11')}</ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode12')}</ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode33')}</ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode34')}</ListItem>
                <ListItem>{t('hlp:helpPages.messageNode.messageNode35')}</ListItem>
            </List>
            <Header2 id="messageNode13">{t('hlp:helpPages.messageNode.messageNode13')}</Header2>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode14')}</Paragraph>
            <PageImage image={image3} />
            <Header2 id="messageNode15">{t('hlp:helpPages.messageNode.messageNode15')}</Header2>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode16')}</Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageNode.messageNode17">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode18')}</Paragraph>
            <PageVideo video={video4} />
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.messageNode.messageNode19">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.messageNode.messageNode20">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.messageNode.messageNode21">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageNode.messageNode22">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode23')}</Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.messageNode.messageNode24">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode25')}</Paragraph>
            <PageVideo video={video6} />
            <Header2 id="messageNode26">{t('hlp:helpPages.messageNode.messageNode26')}</Header2>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode27')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode28')}</Paragraph>
            <Header2 id="messageNode29">{t('hlp:helpPages.messageNode.messageNode29')}</Header2>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode30')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.messageNode.messageNode31')}</Paragraph>
            <PageVideo video={video8} />
            <PageTip view="info">
                <div>{t('hlp:helpPages.messageNode.messageNode32')}</div>
            </PageTip>
        </Body>
    );
};

MessageNode.propTypes = {};

const contextsItems = [
    {
        id: 'contexts1',
        title: 'helpPages.contexts.contexts1'
    },
    {
        id: 'contexts4',
        title: 'helpPages.contexts.contexts4'
    },
    {
        id: 'contexts8',
        title: 'helpPages.contexts.contexts8'
    }
];

export default contextsItems;

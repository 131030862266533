const storiesSubItems = [
    {
        id: 'storiesSub1',
        title: 'helpPages.storiesSub.storiesSub1'
    },
    {
        id: 'storiesSub4',
        title: 'helpPages.storiesSub.storiesSub4'
    },
    {
        id: 'storiesSub9',
        title: 'helpPages.storiesSub.storiesSub9'
    },
    {
        id: 'storiesSub12',
        title: 'helpPages.storiesSub.storiesSub12'
    }
];

export default storiesSubItems;

const dashboardItems = [
    {
        id: 'dashboard1',
        title: 'helpPages.dashboard.dashboard1'
    },
    {
        id: 'dashboard3',
        title: 'helpPages.dashboard.dashboard3'
    }
];

export default dashboardItems;

const channelPrioritizationItems = [
    {
        id: 'channelPrioritization1',
        title: 'helpPages.channelPrioritization.channelPrioritization1'
    },
    {
        id: 'channelPrioritization4',
        title: 'helpPages.channelPrioritization.channelPrioritization4'
    },
    {
        id: 'channelPrioritization11',
        title: 'helpPages.channelPrioritization.channelPrioritization11'
    }
];

export default channelPrioritizationItems;

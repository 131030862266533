const exitPointNodeItems = [
    {
        id: 'exitPointNode1',
        title: 'helpPages.exitPointNode.exitPointNode1'
    },
    {
        id: 'exitPointNode5',
        title: 'helpPages.exitPointNode.exitPointNode5'
    },
    {
        id: 'exitPointNode6',
        title: 'helpPages.exitPointNode.exitPointNode6'
    }
];

export default exitPointNodeItems;

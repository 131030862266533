import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToUseButtonTextsByButtonsArray = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToUseButtonTextsByButtonsArray');

    return (
        <Body>
            <Title>{t('hlp:howToUseButtonTextsByButtonsArray')}</Title>
            <Header id="howToUseButtonTextsByButtonsArray1">
                {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray1')}
            </Header>
            <List>
                <ListItem>
                    {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray2')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray3')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray4')}
                </ListItem>
            </List>
            <Header id="howToUseButtonTextsByButtonsArray5">
                {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray5')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray6">
                    0<span className={css('italic')}>1</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray7">
                    0<span className={css('italic')}>1</span>2<span className={css('code')}>3</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray8')}
            </Paragraph>
            <code className={css('code')}>
                <pre>
                {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray9')}
                </pre>
            </code>
            <Paragraph>
                {t('hlp:helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray10')}
            </Paragraph>
            <PageVideo video={video1} />
        </Body>
    );
};

HowToUseButtonTextsByButtonsArray.propTypes = {};

const chartConfigurationItems = [
    {
        id: 'chartConfiguration1',
        title: 'helpPages.chartConfiguration.chartConfiguration1'
    },
    {
        id: 'chartConfiguration3',
        title: 'helpPages.chartConfiguration.chartConfiguration3'
    },
    {
        id: 'chartConfiguration13',
        title: 'helpPages.chartConfiguration.chartConfiguration13'
    }
];

export default chartConfigurationItems;

const wordOrPhraseSearchItems = [
    {
        id: 'wordOrPhraseSearch1',
        title: 'helpPages.wordOrPhraseSearch.wordOrPhraseSearch1'
    },
    {
        id: 'wordOrPhraseSearch9',
        title: 'helpPages.wordOrPhraseSearch.wordOrPhraseSearch9'
    }
];

export default wordOrPhraseSearchItems;

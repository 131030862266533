import en from './locales/en/hlp.json';
import ru from './locales/ru/hlp.json';

const resources = {
    en: {
        hlp: en
    },
    ru: {
        hlp: ru
    }
};

export default resources;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Contexts = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:contexts');

    return (
        <Body>
            <Title>{t('hlp:contexts')}</Title>
            <Header id="contexts1">{t('hlp:helpPages.contexts.contexts1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.contexts.contexts2')}</ListItem>
                <ListItem>{t('hlp:helpPages.contexts.contexts3')}</ListItem>
            </List>
            <Header id="contexts4">{t('hlp:helpPages.contexts.contexts4')}</Header>
            <Paragraph>{t('hlp:helpPages.contexts.contexts5')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.contexts.contexts6')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.contexts.contexts7')}</Paragraph>
            <PageVideo video={video3} />
            <Header id="contexts8">{t('hlp:helpPages.contexts.contexts8')}</Header>
            <Paragraph>{t('hlp:helpPages.contexts.contexts9')}</Paragraph>
            <PageVideo video={video4} />
        </Body>
    );
};

Contexts.propTypes = {};

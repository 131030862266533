const dialogsOverviewItems = [
    {
        id: 'dialogsOverview1',
        title: 'helpPages.dialogsOverview.dialogsOverview1'
    },
    {
        id: 'dialogsOverview3',
        title: 'helpPages.dialogsOverview.dialogsOverview3'
    }
];

export default dialogsOverviewItems;

import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { DesktopHeader } from '../header/desktop'
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { Menu, MenuItem, Select, Button, SvgIconExternalLink, Snackbar } from '@flomni/components'
import cn from './index.module.scss'
import classNames from 'classnames'
import { store } from '../../state/state'
import axios from 'axios'
import GroupDialog from './group-dialog'
import { GroupBlock } from './group-block'
import { uniqueId } from 'lodash-es'
import PageDialog from './page-dialog'
import { KIND, LANGUAGES, URL, AUTH_KEY } from './constants'
import { ROUTE, routes } from '../../configs/routes'
import arrayMove from './utils'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import EmbeddedLoader from '@flomni/modules/dist/components/Loaders/EmbeddedLoader'
import { useHistory } from 'react-router'
import { getLocalState, LOCAL_STORAGE } from './localState'

const SortableItem = SortableElement(({
    group,
    groups,
    ln,
    setEditGroup,
    deleteFolder,
    setCurrentGroup,
    menuOptions,
    setEditPage,
    menu,
    saveMenu,
    pages
}) => {
    return (
        <GroupBlock
            group={group}
            groups={groups}
            ln={ln}
            onEdit={() => setEditGroup(group)}
            onDelete={() => deleteFolder(group)}
            setCurrentGroup={setCurrentGroup}
            menuOptions={menuOptions}
            setEditPage={setEditPage}
            menu={menu}
            saveMenu={saveMenu}
            pages={pages}
        />
    )
})

const SortableList = SortableContainer(({
    groups,
    ln,
    setEditGroup,
    deleteFolder,
    setCurrentGroup,
    menuOptions,
    setEditPage,
    menu,
    saveMenu,
    pages
}) => {
    const css = useStyles(styles)
    return (
        <div className={css('list')}>
            {groups.filter((group) => group.kind === KIND.GROUP).map((group, index) => (
                <SortableItem
                    group={group}
                    index={index}
                    key={index}
                    ln={ln}
                    setEditGroup={setEditGroup}
                    deleteFolder={deleteFolder}
                    setCurrentGroup={setCurrentGroup}
                    menuOptions={menuOptions}
                    setEditPage={setEditPage}
                    menu={menu}
                    saveMenu={saveMenu}
                    groups={groups}
                    pages={pages}
                />
            ))}
        </div>
    )
})

export const Editor = () => {
    const css = useStyles(styles)
    const { t } = useTranslation()
    const { dispatch, menu, pages, isShowGlobalLoader, relationsCache, messages } = useContext(store)
    const [editGroup, setEditGroup] = useState(null)
    const [editPage, setEditPage] = useState(null)
    const [currentGroup, setCurrentGroup] = useState(null)
    const [ln, setLn] = useState('ru')
    const NEW_GROUP = {
        title: t('hlp:newSubCategory'),
        slug: uniqueId('new-folder-'),
        isNew: true
    }
    const history = useHistory();
    const auth = getLocalState(LOCAL_STORAGE, AUTH_KEY)
    const HEADERS = { headers: { Authorization: getLocalState(LOCAL_STORAGE, AUTH_KEY)?.token }}

    useEffect(() => {
        !auth?.token && history.push(routes[ROUTE.LOGIN])
    }, [auth])

    useEffect(() => {
        async function fetchData() {
            dispatch({ type: 'setIsShowGlobalLoader', show: true })
            try {
                const response = await Promise.all([
                    axios.get(URL.MENU, HEADERS),
                    axios.get(URL.PAGES, HEADERS),
                ])
                dispatch({ type: 'setMenu', menu: response[0].data.tree })
                dispatch({ type: 'setRelationsCache', relationsCache: response[0].data.relationsCache })
                dispatch({ type: 'setPages', pages: response[1].data.pages })
            } catch(e) {
                console.log('Load error: ', e)
                dispatch({ type: 'showWarningMessage', message: t('hlp:editorLoadDataError') })
            } finally {
                dispatch({ type: 'setIsShowGlobalLoader', show: false })
            }
        }
        fetchData()
    }, [])

    const saveMenu = useCallback(async (newMenu) => {
        dispatch({ type: 'setIsShowGlobalLoader', show: true });
        try {
            const response = await axios.put(URL.MENU, {
                tree: newMenu || menu
            }, HEADERS);
            dispatch({ type: 'setMenu', menu: response.data.tree });
            dispatch({ type: 'setRelationsCache', relationsCache: response.data.relationsCache });
            setEditGroup(null)
        } catch(e) {
            console.log('Save menu error: ', e)
            dispatch({ type: 'showWarningMessage', message: t('hlp:editorSaveMenuError') })
        } finally {
            dispatch({ type: 'setIsShowGlobalLoader', show: false })
        }
    }, [menu])

    const savePage = useCallback(async (id, page, folder, showEdit) => {
        dispatch({ type: 'setIsShowGlobalLoader', show: true });
        try {
            let response
            if (id) {
                response = await axios.patch(`${URL.PAGES}/${id}`, page, HEADERS);
                dispatch({ type: 'setPages', pages: pages.map((page) => page.id === response.data.id ? response.data : page) })
            } else {
                response = await axios.post(URL.PAGES, page, HEADERS);
                dispatch({ type: 'setPages', pages: [ ...pages, response.data] })
            }
            showEdit && setEditPage({folder: folder, page: response.data})
            if (folder) {
                const pageGroup = folder.items.find((item) => item.pageId === response.data.id)
                if (!pageGroup) {
                    folder.items.push({
                        title: {
                            [ln]: response.data.metadata[ln].title,
                        },
                        kind: KIND.PAGE_LINK,
                        pageId: response.data.id
                    })
                } else {
                    pageGroup.title[ln] = response.data.metadata[ln].title
                }
                saveMenu()
            }
        } catch(e) {
            console.log('Save page error: ', e)
            dispatch({ type: 'showWarningMessage', message: t('hlp:editorSavePageError') })
        } finally {
            dispatch({ type: 'setIsShowGlobalLoader', show: false })
        }
    }, [menu, pages])

    const deletePage = useCallback(async (id, folder) => {
        dispatch({ type: 'setIsShowGlobalLoader', show: true });
        try {
            if (folder) {
                folder.items = folder.items.filter((item) => item.pageId !== id)
                saveMenu()
            } else {
                await axios.delete(`${URL.PAGES}/${id}`, HEADERS);
                dispatch({ type: 'setPages', pages: pages.filter((page) => page.id !== id) })
            }
        } catch(e) {
            console.log('Delete page error: ', e)
            dispatch({ type: 'showWarningMessage', message: t('hlp:editorDeletePageError') })
        } finally {
            dispatch({ type: 'setIsShowGlobalLoader', show: false })
        }
    }, [menu, pages])

    const menuOptions = useMemo(() => {
        const options = []
        const parse = (items, parentTitle, parentSlug) => {
            items.forEach((item) => {
                if (item.kind === KIND.GROUP) {
                    const title = `${parentTitle ? `${parentTitle} / ` : ''}${item.title[ln] || item.title['ru'] || item.title['en']}`
                    const slug = `${parentSlug ? `${parentSlug}/` : ''}${item.slug}`
                    item.id = item.id || uniqueId('menu')
                    const index = options.push({label: title, value: slug, item: item})
                    if (currentGroup?.item.id === item.id) {
                        setCurrentGroup(options[index - 1])
                    }
                    if (item.items) {
                        parse(item.items, title, slug)
                    }
                }
            })
        }
        parse(menu)
        return options
    }, [menu, ln])

    const deleteFolder = (item) => {
        let newMenu = menu
        if (currentGroup) {
            currentGroup.item.items = currentGroup.item.items.filter((folder) => folder.id !== item.id)
        } else {
            newMenu = menu.filter((folder) => folder.id !== item.id)
        }
        saveMenu(newMenu)
    }

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
      let newMenu = menu
      if (currentGroup) {
          currentGroup.item.items = arrayMove(currentGroup.item.items, oldIndex, newIndex)
      } else {
          newMenu = arrayMove(menu, oldIndex, newIndex)
          dispatch({ type: 'setMenu', menu: newMenu });
      }
      saveMenu(newMenu)
    }, [currentGroup, menu])

    const pagesWithoutGroup = useMemo(() => {
        return pages.filter((page) => !relationsCache?.byPageId[page.id])
    }, [pages, relationsCache])

    return (
        <div className={css('main')}>
            <DesktopHeader />
            <div className={classNames(cn.root, cn.grid)}>
                <Menu classes={{ root: cn.menu }}>
                    <MenuItem onClick={() => {}} selected={true}>
                        {t('hlp:contentManagement')}
                    </MenuItem>
                    <MenuItem onClick={() => history.push('/')} classes={{root: css('link-item')}}>
                        {t('hlp:publicVersion')}
                        <div className={css('link-icon')}>
                          <SvgIconExternalLink />
                        </div>
                    </MenuItem>
                </Menu>
                <div className={cn.content}>
                    <div className={cn.bar}>
                        <div className={cn.filter}>
                            <div className={cn.usual}>
                                {t('hlp:ln')}
                                <div className={cn.ln}>
                                    <Select
                                        view='tall'
                                        menuPosition='fixed'
                                        value={LANGUAGES.find((lng) => lng.value === ln) || null}
                                        onChange={(option) => setLn(option.value)}
                                        options={LANGUAGES}
                                    />
                                </div>
                            </div>
                            <div className={classNames(cn.usual)}>
                                {t('hlp:category')}
                                <div className={cn.select}>
                                    <Select
                                        isClearable
                                        view='tall'
                                        menuPosition='fixed'
                                        value={currentGroup}
                                        onChange={(group) => setCurrentGroup(group)}
                                        options={menuOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cn.group}>
                            <Button
                                disabled={false} view='primary'
                                onClick={() => setEditGroup(NEW_GROUP)}
                            >
                                {t('hlp:addGroup')}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <GroupBlock
                            ln={ln}
                            setEditPage={setEditPage}
                            pages={pagesWithoutGroup}
                            groups={currentGroup ? currentGroup?.item?.items : menu}
                            saveMenu={saveMenu}
                        />
                        <SortableList
                            groups={currentGroup ? currentGroup?.item?.items : menu}
                            axis='y'
                            onSortEnd={onSortEnd}
                            helperClass={css('drag')}
                            ln={ln}
                            setEditGroup={setEditGroup}
                            deleteFolder={deleteFolder}
                            setCurrentGroup={setCurrentGroup}
                            menuOptions={menuOptions}
                            setEditPage={setEditPage}
                            menu={menu}
                            saveMenu={saveMenu}
                            pages={pages}
                            useDragHandle
                        />
                    </div>
                </div>
            </div>
            {!!editGroup && (
                <GroupDialog
                    onCancel={() => setEditGroup(null)}
                    onSend={() => setEditGroup(null)}
                    group={editGroup}
                    editGroup={setEditGroup}
                    ln={ln}
                    menu={menu}
                    currentGroup={currentGroup}
                    saveMenu={saveMenu}
                />
            )}
            {!!editPage && (
                <PageDialog
                    editPage={editPage}
                    ln={ln}
                    onCancel={() => setEditPage(null)}
                    savePage={savePage}
                    deletePage={deletePage}
                />
            )}
            {isShowGlobalLoader && (
                <div className={css('global-loader')}>
                    <EmbeddedLoader show={true} global={true} />
                </div>
            )}
            <Snackbar
                classes={{ root: css('snackbar') }}
                messages={messages}
                onCloseEnd={() => dispatch({ type: 'clearMessages' })}
            />
        </div>
    )
}

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AddingMessages = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:addingMessages')

    return (
        <Body>
            <Title>{t('hlp:addingMessages')}</Title>
            <Header id="addingMessages1">{t('hlp:helpPages.addingMessages.addingMessages1')}</Header>
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages3')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages4')}</Paragraph>
            <PageVideo video={video7} />
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages5')}</Paragraph>
            <PageVideo video={video8} />
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages6')}</Paragraph>
            <PageVideo video={video9} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.addingMessages.addingMessages7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video10} />
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages8')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.addingMessages.addingMessages9">
                    <span className={css('bold')}>0</span>2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages10')}</Paragraph>
            <PageVideo video={video12} />
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages11')}</Paragraph>
            <PageVideo video={video13} />
            <Header id="addingMessages12">{t('hlp:helpPages.addingMessages.addingMessages12')}</Header>
            <Paragraph>{t('hlp:helpPages.addingMessages.addingMessages13')}</Paragraph>
            <PageVideo video={video14} />
        </Body>
    );
};

AddingMessages.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotLogs = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotLogs');

    return (
        <Body>
            <Title>{t('hlp:chatbotLogs')}</Title>
            <Header id="chatbotLogs1">{t('hlp:helpPages.chatbotLogs.chatbotLogs1')}</Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotLogs.chatbotLogs2">
                        0<Link to={routes[ROUTE.WEBHOOK_NODE]} className={css('link')} />
                    </Trans>
                </ListItem>
            </List>
            <Header id="chatbotLogs3">{t('hlp:helpPages.chatbotLogs.chatbotLogs3')}</Header>
            <Paragraph id="chatbotLogs4">
                <Trans i18nKey="hlp:helpPages.chatbotLogs.chatbotLogs4">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.chatbotLogs.chatbotLogs5')}</Paragraph>
            <PageVideo video={video12} />
            <Paragraph>{t('hlp:helpPages.chatbotLogs.chatbotLogs6')}</Paragraph>
            <PageVideo video={video13} />
        </Body>
    );
};

ChatbotLogs.propTypes = {};

const topicRecognitionItems = [
    {
        id: 'topicRecognition1',
        title: 'helpPages.topicRecognition.topicRecognition1'
    },
    {
        id: 'topicRecognition3',
        title: 'helpPages.topicRecognition.topicRecognition3'
    },
    {
        id: 'topicRecognition6',
        title: 'helpPages.topicRecognition.topicRecognition6'
    },
    {
        id: 'topicRecognition8',
        title: 'helpPages.topicRecognition.topicRecognition8'
    }
];

export default topicRecognitionItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChannelPrioritization = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:channelPrioritization')

    return (
        <Body>
            <Title>{t('hlp:channelPrioritization')}</Title>
            <Header id="channelPrioritization1">
                {t('hlp:helpPages.channelPrioritization.channelPrioritization1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization3')}</Paragraph>
            <PageVideo video={video18} />
            <Header id="channelPrioritization4">
                {t('hlp:helpPages.channelPrioritization.channelPrioritization4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization5')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization8')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization9')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization10')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video19} />
            <Header id="channelPrioritization11">
                {t('hlp:helpPages.channelPrioritization.channelPrioritization11')}
            </Header>
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization12')}</Paragraph>
            <PageVideo video={video20} />
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization13')}</Paragraph>
            <PageVideo video={video21} />
            <Paragraph>{t('hlp:helpPages.channelPrioritization.channelPrioritization14')}</Paragraph>
        </Body>
    );
};

ChannelPrioritization.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const EnterPointNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:enterPointNode');

    return (
        <Body>
            <Title>{t('hlp:enterPointNode')}</Title>
            <Header id="enterPointNode1">{t('hlp:helpPages.enterPointNode.enterPointNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.enterPointNode.enterPointNode2')}</ListItem>
                <ListItem>{t('hlp:helpPages.enterPointNode.enterPointNode3')}</ListItem>
                <ListItem>{t('hlp:helpPages.enterPointNode.enterPointNode4')}</ListItem>
                <ListItem>{t('hlp:helpPages.enterPointNode.enterPointNode5')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.enterPointNode.enterPointNode6">
                        0
                        <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
            </List>
            <Header id="enterPointNode7">{t('hlp:helpPages.enterPointNode.enterPointNode7')}</Header>
            <PageVideo video={video1} />
            <Header id="enterPointNode8">{t('hlp:helpPages.enterPointNode.enterPointNode8')}</Header>
            <Header2 id="enterPointNode9">{t('hlp:helpPages.enterPointNode.enterPointNode9')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.enterPointNode.enterPointNode10">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.enterPointNode.enterPointNode11')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.enterPointNode.enterPointNode12')}</Paragraph>
            <PageVideo video={video3} />
        </Body>
    );
};

EnterPointNode.propTypes = {};

const chatbotDraftDebugModeItems = [
    {
        id: 'chatbotDraftDebugMode1',
        title: 'helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode1'
    },
    {
        id: 'chatbotDraftDebugMode4',
        title: 'helpPages.chatbotDraftDebugMode.chatbotDraftDebugMode4'
    }
];

export default chatbotDraftDebugModeItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video17 from './assets/video17.mov';
import video18 from './assets/video18.mov';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MultibranchChatbot = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:multibranchChatbot');

    return (
        <Body>
            <Title>{t('hlp:multibranchChatbot')}</Title>
            <Header id="multibranchChatbot1">
                {t('hlp:helpPages.multibranchChatbot.multibranchChatbot1')}
            </Header>
            <List>
                <ListItem>{t('hlp:helpPages.multibranchChatbot.multibranchChatbot2')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.multibranchChatbot.multibranchChatbot3">
                        0<Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')} />
                        1<Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')} />
                    </Trans>
                </ListItem>
                <ListItem>{t('hlp:helpPages.multibranchChatbot.multibranchChatbot4')}</ListItem>
            </List>
            <Header id="multibranchChatbot5">
                {t('hlp:helpPages.multibranchChatbot.multibranchChatbot5')}
            </Header>
            <Paragraph id="multibranchChatbot6">
                <Trans i18nKey="hlp:helpPages.multibranchChatbot.multibranchChatbot6">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video17} />
            <Paragraph id="multibranchChatbot7">
                <Trans i18nKey="hlp:helpPages.multibranchChatbot.multibranchChatbot7">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video18} />
            <Paragraph>{t('hlp:helpPages.multibranchChatbot.multibranchChatbot8')}</Paragraph>
            <PageVideo video={video19} />
            <Header id="multibranchChatbot9">
                {t('hlp:helpPages.multibranchChatbot.multibranchChatbot9')}
            </Header>
            <Paragraph id="multibranchChatbot10">
                <Trans i18nKey="hlp:helpPages.multibranchChatbot.multibranchChatbot10">
                    0<span className={css('bold')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video20} />
        </Body>
    );
};

MultibranchChatbot.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToMakeStubsForNonWorkingHours = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToMakeStubsForNonWorkingHours');

    return (
        <Body>
            <Title>{t('hlp:howToMakeStubsForNonWorkingHours')}</Title>
            <Header id="howToMakeStubsForNonWorkingHours1">
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours1')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours2')}
            </Paragraph>
            <Header id="howToMakeStubsForNonWorkingHours3">
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours3')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours4')}
            </Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours5')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours6')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours7')}
                </ListItem>
            </List>
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours8')}
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours9')}
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours10')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours11')}
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours12')}
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours13')}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours14">
                    <span className={css(['italic'])}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours15">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours16">
                    <span className={css(['italic'])}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours17')}
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours18">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        3
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours19">
                    <span className={css(['italic'])}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours20">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
        </Body>
    );
};

HowToMakeStubsForNonWorkingHours.propTypes = {};

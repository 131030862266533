import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageImage } from '../../shared/image';
import { PageVideo } from '../../shared/video';
import { func } from 'prop-types';
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video14 from './assets/video14.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotBuilderOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotBuilderOverview');

    return (
        <Body>
            <Title>{t('hlp:chatbotBuilderOverview')}</Title>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview1')}</Paragraph>
            <Header id="chatbotBuilderOverview2">
                {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview2')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview3">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview7')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <List>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview9')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview10')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview11')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview12')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview13')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview14')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview15')}</ListItem>
            </List>
            <Header id="chatbotBuilderOverview16">
                {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview16')}
            </Header>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview17')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview19')}</Paragraph>
            <Header id="chatbotBuilderOverview20">
                {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview20')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview21">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --enter-point')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview22')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview23')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --exit-point')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview24')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview25')}
                    </div>
                </div>
            </div>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview26">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --message')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview27')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview28')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --chat-control')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview29')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview30')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --javascript')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview31')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview32')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --webhook')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview33')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview34')}
                    </div>
                </div>
            </div>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview35">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --condition')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview36')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview37')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --text-analyzer')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview38')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview39')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --randomizer')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview40')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview41')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --recognition')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview42')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview43')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --action-chain')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview44')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview45')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --timeout')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview46')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview47')}
                    </div>
                </div>
            </div>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview48">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --storage')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview49')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview50')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --note')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview51')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview52')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --tag')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview53')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview54')}
                    </div>
                </div>
            </div>
            <div className={css('node-main-cnt')}>
                <div className={css('node-icon-cnt')}>
                    <div className={css('node-icon --json')}></div>
                </div>
                <div className={css('node-description')}>
                    <div className={css('node-title')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview55')}
                    </div>
                    <div className={css('node-text')}>
                        {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview56')}
                    </div>
                </div>
            </div>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview57">
                    0 <span className={css('bold')}>1</span> 2 <span className={css('bold')}>3</span> 4
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Header id="chatbotBuilderOverview58">
                {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview58')}
            </Header>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview59')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview60')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview61')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview62')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview63')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview64')}</ListItem>
            </List>
            <PageImage image={image1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview65">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview66">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview67">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview68">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview69">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview70">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview71">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview72">
                        <span className={css('bold')}>0</span> 1
                    </Trans>
                </ListItem>
            </List>
            <PageImage image={image2} />
            <Header id="chatbotBuilderOverview73">
                {t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview73')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview74">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotBuilderOverview.chatbotBuilderOverview75')}</Paragraph>
            <PageVideo video={video14} />
        </Body>
    );
};

ChatbotBuilderOverview.propTypes = {
    openMessageForm: func
};

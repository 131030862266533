const systemVariablesItems = [
    {
        id: 'systemVariables1',
        title: 'helpPages.systemVariables.systemVariables1'
    },
    {
        id: 'systemVariables5',
        title: 'helpPages.systemVariables.systemVariables5'
    }
];

export default systemVariablesItems;

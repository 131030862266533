import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatAttachments = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:chatAttachments');

    return (
        <Body>
            <Title>{t('hlp:chatAttachments')}</Title>
            <Header id="chatAttachments1">{t('hlp:helpPages.chatAttachments.chatAttachments1')}</Header>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments3')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments4')}</Paragraph>
            <Header id="chatAttachments5">{t('hlp:helpPages.chatAttachments.chatAttachments5')}</Header>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments7')}</Paragraph>
            <PageVideo video={video2} />
            <Header id="chatAttachments8">{t('hlp:helpPages.chatAttachments.chatAttachments8')}</Header>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments9')}</Paragraph>
            <PageVideo video={video3} />
            <Header id="chatAttachments10">{t('hlp:helpPages.chatAttachments.chatAttachments10')}</Header>
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments11')}</Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.chatAttachments.chatAttachments12')}</Paragraph>
        </Body>
    );
};

ChatAttachments.propTypes = {};

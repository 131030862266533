import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { IconButton, SvgBtSearch, SvgBtClose } from '@flomni/components';
import { useTranslation } from 'react-i18next';
import {useCallback, useRef, useState} from 'react';
import { SearchPageResults } from '../../search-page-results';
import { func } from 'prop-types';
import axios from 'axios';
import { URL } from '../../editor/constants';

export const HeaderSearch = ({ onClose }) => {
    const css = useStyles(styles);
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const searchContainer = useRef();

    const getResult = useCallback(async () => {
        const result = await axios.get(`${URL.SEARCH}/?query=${search}&language=${i18n.language}`);
        return result.data.pages.map((item) => {
            const indexes = [];
            const lowerKey = search.toLowerCase();
            const matches = item.title.toLowerCase().matchAll(search.toLowerCase());
            for (const match of matches) {
                indexes.push({
                    start: match.index,
                    end: match.index + lowerKey.length
                });
            }
            return { ...item, indexes };
        });
    }, [search, i18n.language]);

    const onKeyUp = async () => {
        const items = search ? await getResult() : null;
        setSearchResult(search ? { items, key: search } : null);
    };

    return (
        <div className={css('container')}>
            <div className={css('search-line')} ref={searchContainer}>
                <SvgBtSearch />
                <input
                    onKeyUp={onKeyUp}
                    className={css('search-input')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t('hlp:searchPlaceholder')}
                />
                {onClose ? (
                    <IconButton classes={{ root: css('close-btn') }} onClick={onClose}>
                        <SvgBtClose />
                    </IconButton>
                ) : null}
            </div>
            {searchResult && (
                <div className={css('search-results')}>
                    <SearchPageResults
                        searchContainer={searchContainer}
                        result={searchResult}
                        clearResults={() => setSearchResult(null)}
                    />
                </div>
            )}
        </div>
    );
};

HeaderSearch.propTypes = {
    onClose: func
};

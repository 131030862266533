const howToFindWordOrPhraseInUsersMessageItems = [
    {
        id: 'howToFindWordOrPhraseInUsersMessage1',
        title: 'helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage1'
    },
    {
        id: 'howToFindWordOrPhraseInUsersMessage3',
        title: 'helpPages.howToFindWordOrPhraseInUsersMessage.howToFindWordOrPhraseInUsersMessage3'
    }
];

export default howToFindWordOrPhraseInUsersMessageItems;

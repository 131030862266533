import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video34 from './assets/video34.mov';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import video39 from './assets/video39.mov';
import video40 from './assets/video40.mov';
import video41 from './assets/video41.mov';
import video42 from './assets/video42.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const DistributionTags = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:distributionTags');

    return (
        <Body>
            <Title>{t('hlp:distributionTags')}</Title>
            <Header id="distributionTags1">{t('hlp:helpPages.distributionTags.distributionTags1')}</Header>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags2')}</Paragraph>
            <PageVideo video={video34} />
            <Header id="distributionTags3">{t('hlp:helpPages.distributionTags.distributionTags3')}</Header>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags4')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags5')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags6')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags7')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags8')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video35} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags9')}</Paragraph>
            <PageVideo video={video36} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags10')}</Paragraph>
            <PageVideo video={video37} />
            <Header id="distributionTags11">{t('hlp:helpPages.distributionTags.distributionTags11')}</Header>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags12')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags13')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags14')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags15')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags16')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags17')}</Paragraph>
                </ListItem>
            </List>
            <PageVideo video={video38} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags18')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags19')}</Paragraph>
            <PageVideo video={video39} />
            <Header id="distributionTags20">{t('hlp:helpPages.distributionTags.distributionTags20')}</Header>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags21')}</Paragraph>
            <PageVideo video={video40} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags22')}</Paragraph>
            <PageVideo video={video41} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags23')}</Paragraph>
            <PageVideo video={video42} />
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags24')}</Paragraph>
            <Header id="distributionTags25">{t('hlp:helpPages.distributionTags.distributionTags25')}</Header>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags26')}</Paragraph>
            <Paragraph id="distributionTags27">
                <Trans i18nKey="hlp:helpPages.distributionTags.distributionTags27">
                    0<Link to={routes[ROUTE.CHANNEL_PRIORITIZATION]} className={css('link')} />
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.distributionTags.distributionTags28')}</Paragraph>
        </Body>
    );
};

DistributionTags.propTypes = {};

const variousFiltersSearchItems = [
    {
        id: 'variousFiltersSearch1',
        title: 'helpPages.variousFiltersSearch.variousFiltersSearch1'
    },
    {
        id: 'variousFiltersSearch3',
        title: 'helpPages.variousFiltersSearch.variousFiltersSearch3'
    },
    {
        id: 'variousFiltersSearch12',
        title: 'helpPages.variousFiltersSearch.variousFiltersSearch12'
    },
    {
        id: 'variousFiltersSearch18',
        title: 'helpPages.variousFiltersSearch.variousFiltersSearch18'
    }
];

export default variousFiltersSearchItems;

const messageNodeItems = [
    {
        id: 'messageNode1',
        title: 'helpPages.messageNode.messageNode1'
    },
    {
        id: 'messageNode4',
        title: 'helpPages.messageNode.messageNode4'
    },
    {
        id: 'messageNode5',
        title: 'helpPages.messageNode.messageNode5'
    }
];

export default messageNodeItems;

const howToValidateUserTypedTextItems = [
    {
        id: 'howToValidateUserTypedText1',
        title: 'helpPages.howToValidateUserTypedText.howToValidateUserTypedText1'
    },
    {
        id: 'howToValidateUserTypedText3',
        title: 'helpPages.howToValidateUserTypedText.howToValidateUserTypedText3'
    }
];

export default howToValidateUserTypedTextItems;

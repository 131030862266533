import dotProp from 'dot-prop-immutable'
import { LOCAL_STORAGE, SESSION_STORAGE } from '../constants'

export const get = (provider, key, defaultValue) => {
  const providerInstance = getStorageProvider(provider)
  const storageState = getStorageState(providerInstance)
  const state = dotProp.get(storageState, key)

  if (state === undefined) return defaultValue

  return state
}

export const set = (provider, key, value) => {
  const providerInstance = getStorageProvider(provider)

  return setStorageState(providerInstance, key, value)
}

export const remove = (provider, key) => {
  const providerInstance = getStorageProvider(provider)

  return removeStorageState(providerInstance, key)
}

const getStorageState = (providerInstance) => {
  const state = providerInstance.getItem('flomni_help_center')

  if (state === null) return {}

  return JSON.parse(state)
}

const setStorageState = (providerInstance, key, value) => {
  const state = getStorageState(providerInstance)
  const nextState = dotProp.set(state, key, value)

  providerInstance.setItem('flomni_help_center', JSON.stringify(nextState))
}

const removeStorageState = (providerInstance, key) => {
  const state = getStorageState(providerInstance)

  delete state[key]
  providerInstance.setItem('flomni_help_center', JSON.stringify(state))
}

const getStorageProvider = (provider) => {
  switch (provider) {
    case LOCAL_STORAGE:
      return window.localStorage
    case SESSION_STORAGE:
      return window.sessionStorage
  }
}

const enterPointNodeItems = [
    {
        id: 'enterPointNode1',
        title: 'helpPages.enterPointNode.enterPointNode1'
    },
    {
        id: 'enterPointNode7',
        title: 'helpPages.enterPointNode.enterPointNode7'
    },
    {
        id: 'enterPointNode8',
        title: 'helpPages.enterPointNode.enterPointNode8'
    }
];

export default enterPointNodeItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageImage } from '../../shared/image';
import { PageVideo } from '../../shared/video';
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotReleases = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotReleases');

    return (
        <Body>
            <Title>{t('hlp:chatbotReleases')}</Title>
            <Header id="chatbotReleases1">{t('hlp:helpPages.chatbotReleases.chatbotReleases1')}</Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotReleases.chatbotReleases2">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.chatbotReleases.chatbotReleases3">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>{t('hlp:helpPages.chatbotReleases.chatbotReleases4')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotReleases.chatbotReleases5')}</ListItem>
            </List>
            <Header id="chatbotReleases6">{t('hlp:helpPages.chatbotReleases.chatbotReleases6')}</Header>
            <Paragraph>{t('hlp:helpPages.chatbotReleases.chatbotReleases7')}</Paragraph>
            <PageImage image={image1} />
            <Paragraph>{t('hlp:helpPages.chatbotReleases.chatbotReleases8')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.chatbotReleases.chatbotReleases9')}</Paragraph>
            <PageImage image={image2} />
            <Paragraph>{t('hlp:helpPages.chatbotReleases.chatbotReleases10')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotReleases.chatbotReleases11')}</Paragraph>
            <PageVideo video={video2} />
            <PageTip view="warning">
                <div>
                    <Trans i18nKey="hlp:helpPages.chatbotReleases.chatbotReleases12">
                        <span className={css('bold')}>0</span> 1
                        <Link to={routes[ROUTE.WHAT_IS_WIDGET]} className={css('link')}>
                            2
                        </Link>
                        3
                    </Trans>
                </div>
            </PageTip>
        </Body>
    );
};

ChatbotReleases.propTypes = {};

const megabrainAiOverviewItems = [
    {
        id: 'megabrainAiOverview1',
        title: 'helpPages.megabrainAiOverview.megabrainAiOverview1'
    },
    {
        id: 'megabrainAiOverview3',
        title: 'helpPages.megabrainAiOverview.megabrainAiOverview3'
    },
    {
        id: 'megabrainAiOverview4',
        title: 'helpPages.megabrainAiOverview.megabrainAiOverview4'
    }
];

export default megabrainAiOverviewItems;

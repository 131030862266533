import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatbotPresets = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatbotPresets');

    return (
        <Body>
            <Title>{t('hlp:chatbotPresets')}</Title>
            <Header id="chatbotThreads1">{t('hlp:helpPages.chatbotPresets.chatbotPresets1')}</Header>
            <List>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets2')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets3')}</Paragraph>
                </ListItem>
            </List>
            <Header id="chatbotPresets4">{t('hlp:helpPages.chatbotPresets.chatbotPresets4')}</Header>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets5')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets6')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets7')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets8')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets9')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets10')}</ListItem>
                <PageVideo video={video35} />
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets11')}</ListItem>
                <PageVideo video={video36} />
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets12')}</ListItem>
                <PageVideo video={video37} />
            </List>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets13')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets14')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets15')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets16')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets17')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets18')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatbotPresets.chatbotPresets19')}</ListItem>
            </List>
            <PageVideo video={video38} />
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets20')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatbotPresets.chatbotPresets21')}</Paragraph>
        </Body>
    );
};

ChatbotPresets.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToValidateUserTypedText = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToValidateUserTypedText');

    return (
        <Body>
            <Title>{t('hlp:howToValidateUserTypedText')}</Title>
            <Header id="howToValidateUserTypedText1">
                {t('hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText1')}
            </Header>
            <List>
                <ListItem>
                    {t('hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText2')}
                </ListItem>
            </List>
            <Header id="howToValidateUserTypedText3">
                {t('hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText4">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText5">
                    0
                    <Link to={routes[ROUTE.TEXT_ANALYSIS_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4<span className={css('bold')}>5</span>6
                    <span className={css('bold')}>7</span>8<span className={css('bold')}>9</span>10
                    <span className={css('bold')}>11</span>12
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText6">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText7">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText8')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText14">
                    0<span className={css('bold')}>1</span>2
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        3
                    </Link>
                    4<span className={css('bold')}>5</span>6<span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText9">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText10">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText11">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.RECOGNITION_NODE]} className={css('link')}>
                        3
                    </Link>
                    4
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText12">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                    <span className={css('bold')}>5</span>6
                </Trans>
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToValidateUserTypedText.howToValidateUserTypedText13')}
            </Paragraph>
        </Body>
    );
};

HowToValidateUserTypedText.propTypes = {};

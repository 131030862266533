import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video46 from './assets/video46.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TagChanges = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:tagChanges');

    return (
        <Body>
            <Title>{t('hlp:tagChanges')}</Title>
            <Header id="tagChanges1">{t('hlp:helpPages.tagChanges.tagChanges1')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.tagChanges.tagChanges2">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video46} />
            <Header id="tagChanges3">{t('hlp:helpPages.tagChanges.tagChanges3')}</Header>
            <Paragraph>{t('hlp:helpPages.tagChanges.tagChanges4')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.tagChanges.tagChanges5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
        </Body>
    );
};

TagChanges.propTypes = {};

const chatManagementNodeItems = [
    {
        id: 'chatManagementNode1',
        title: 'helpPages.chatManagementNode.chatManagementNode1'
    },
    {
        id: 'chatManagementNode3',
        title: 'helpPages.chatManagementNode.chatManagementNode3'
    },
    {
        id: 'chatManagementNode4',
        title: 'helpPages.chatManagementNode.chatManagementNode4'
    }
];

export default chatManagementNodeItems;

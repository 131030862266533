const sessionsTopicsItems = [
    {
        id: 'sessionsTopics1',
        title: 'helpPages.sessionsTopics.sessionsTopics1'
    },
    {
        id: 'sessionsTopics3',
        title: 'helpPages.sessionsTopics.sessionsTopics3'
    },
    {
        id: 'sessionsTopics12',
        title: 'helpPages.sessionsTopics.sessionsTopics12'
    }
];

export default sessionsTopicsItems;

const profileDataItems = [
    {
        id: 'profileData1',
        title: 'helpPages.profileData.profileData1'
    },
    {
        id: 'profileData11',
        title: 'helpPages.profileData.profileData11'
    }
];

export default profileDataItems;

const firstStepsWithNodesItems = [
    {
        id: 'firstStepsWithNodes1',
        title: 'helpPages.firstStepsWithNodes.firstStepsWithNodes1'
    },
    {
        id: 'firstStepsWithNodes4',
        title: 'helpPages.firstStepsWithNodes.firstStepsWithNodes4'
    },
    {
        id: 'firstStepsWithNodes11',
        title: 'helpPages.firstStepsWithNodes.firstStepsWithNodes11'
    },
    {
        id: 'firstStepsWithNodes15',
        title: 'helpPages.firstStepsWithNodes.firstStepsWithNodes15'
    },
    {
        id: 'firstStepsWithNodes16',
        title: 'helpPages.firstStepsWithNodes.firstStepsWithNodes16'
    }
];

export default firstStepsWithNodesItems;

import { Trans, useTranslation } from 'react-i18next';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import usePageAccess from '../../../../hooks/usePageAccess';

export const Definitions = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:definitions');

    return (
        <Body>
            <Title>{t('hlp:definitions')}</Title>
            <Header id="definitions1">{t('hlp:helpPages.definitions.definitions1')}</Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions2">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions3">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions4">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions5">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions6">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions7">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions8">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions9">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions10">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions11">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions12">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions13">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions14">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.definitions.definitions15">
                    <span className={css('bold')}>0</span>
                </Trans>
            </Paragraph>
        </Body>
    );
};

Definitions.propTypes = {};

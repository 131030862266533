import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video12 from './assets/video12.mov';
import video13 from './assets/video13.mov';
import video14 from './assets/video14.mov';
import video15 from './assets/video15.mov';
import video16 from './assets/video16.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const SearchByUserId = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:searchByUserId');

    return (
        <Body>
            <Title>{t('hlp:searchByUserId')}</Title>
            <Header id="searchByUserId1">{t('hlp:helpPages.searchByUserId.searchByUserId1')}</Header>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId2')}</Paragraph>
            <Header id="searchByUserId3">{t('hlp:helpPages.searchByUserId.searchByUserId3')}</Header>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId4')}</Paragraph>
            <PageVideo video={video12} />
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId5')}</Paragraph>
            <PageVideo video={video13} />
            <Header id="searchByUserId6">{t('hlp:helpPages.searchByUserId.searchByUserId6')}</Header>
            <Paragraph id="searchByUserId7">
                <Trans i18nKey="hlp:helpPages.searchByUserId.searchByUserId7">
                    0
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <Paragraph id="searchByUserId8">
                <Trans i18nKey="hlp:helpPages.searchByUserId.searchByUserId8">
                    0
                    <Link to={routes[ROUTE.METADATA_OVERVIEW]} className={css('link')}>
                        1
                    </Link>
                    2
                    <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                        3
                    </Link>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId9')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId10')}</Paragraph>
                </ListItem>
                <PageVideo video={video14} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId11')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId12')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId13')}</Paragraph>
                </ListItem>
                <PageVideo video={video15} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId14')}</Paragraph>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId15')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId16')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId17')}</Paragraph>
                </ListItem>
                <PageVideo video={video16} />
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId18')}</Paragraph>
                </ListItem>
            </List>
            <Header id="searchByUserId19">{t('hlp:helpPages.searchByUserId.searchByUserId19')}</Header>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId20')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId21')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId22')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId23')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId24')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId25')}</Paragraph>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId26')}</Paragraph>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.searchByUserId.searchByUserId27')}</Paragraph>
            <Paragraph id="searchByUserId28">
                <Trans i18nKey="hlp:helpPages.searchByUserId.searchByUserId28">
                    0
                    <Link to={routes[ROUTE.SEARCH_BY_PHONE_NUMBER]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
        </Body>
    );
};

SearchByUserId.propTypes = {};

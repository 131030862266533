import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { string } from 'prop-types';
import { useState } from 'react';
import { VideoPopup } from './videoPopup';
import { Spinner } from '@flomni/components';

export const PageVideo = ({ video }) => {
    const css = useStyles(styles);
    const [showStub, setShowStub] = useState(true);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [popupVideoLoaded, setPopupVideoLoaded] = useState(false);

    const handleVideoLoaded = () => {
        setShowStub(false);
    };

    const closePopup = () => {
        setShowFullScreen(false);
        setPopupVideoLoaded(false);
    };

    return (
        <>
            <div
                onClick={() => setShowFullScreen(true)}
                className={css(['video', showStub ? '--stub' : null])}
            >
                {video && (
                    <video autoPlay loop preload="auto" muted playsInline onLoadedData={handleVideoLoaded} key={video}>
                        <source src={video} type="video/mp4" />
                    </video>
                )}
            </div>
            <VideoPopup open={showFullScreen} closePopup={closePopup}>
                <div className={css('content')}>
                    {!popupVideoLoaded && (
                        <div className={css('spinnerContainer')}>
                            <div className={css('spinner')}>
                                <Spinner />
                            </div>
                        </div>
                    )}
                    <video
                        autoPlay
                        loop
                        preload="auto"
                        muted
                        playsInline
                        onLoadedData={() => setPopupVideoLoaded(true)}
                        className={css(`${popupVideoLoaded ? null : 'hidden'}`)}
                        key={video}
                    >
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </VideoPopup>
        </>
    );
};

PageVideo.propTypes = {
    video: string.isRequired
};

import { useTranslation } from 'react-i18next';
import { Body } from '../../shared/body';
import { Title } from '../../shared/title';
import usePageAccess from '../../../../hooks/usePageAccess';

export const WhatIsFlomni = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:whatIsFlomni');

    return (
        <Body>
            <Title>{t('hlp:whatIsFlomni')}</Title>
        </Body>
    );
};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageTip } from '../../shared/tip';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const FirstStepsWithNodes = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:firstStepsWithNodes');

    return (
        <Body>
            <Title>{t('hlp:firstStepsWithNodes')}</Title>
            <Header id="firstStepsWithNodes1">
                {t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes1')}
            </Header>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes2">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes3')}</ListItem>
            </List>
            <Header id="firstStepsWithNodes4">
                {t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes5')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes6">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes7">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes8">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes9">
                        0 <span className={css('bold')}>1</span> 2
                    </Trans>
                </ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes10')}</Paragraph>
            <PageVideo video={video1} />
            <Header id="firstStepsWithNodes11">
                {t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes11')}
            </Header>
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes12')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes13">
                    0 <span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <PageTip>
                <div>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes14')}</div>
            </PageTip>
            <Header id="firstStepsWithNodes15">
                {t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes15')}
            </Header>
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes22')}</Paragraph>
            <PageVideo video={video6} />
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes23')}</Paragraph>
            <PageVideo video={video7} />
            <Header id="firstStepsWithNodes16">
                {t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes16')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes17">
                    0 <a>1</a> 2
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes18')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes19">
                    0 <span className={css('bold')}>1</span> 2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <PageTip view="warning">
                <div>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes20')}</div>
            </PageTip>
            <Paragraph>{t('hlp:helpPages.firstStepsWithNodes.firstStepsWithNodes21')}</Paragraph>
        </Body>
    );
};

FirstStepsWithNodes.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Header2 } from '../../shared/header2';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ExitPointNode = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:exitPointNode');

    return (
        <Body>
            <Title>{t('hlp:exitPointNode')}</Title>
            <Header id="exitPointNode1">{t('hlp:helpPages.exitPointNode.exitPointNode1')}</Header>
            <List>
                <ListItem>{t('hlp:helpPages.exitPointNode.exitPointNode2')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.exitPointNode.exitPointNode3">
                        0
                        <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </ListItem>
                <ListItem>{t('hlp:helpPages.exitPointNode.exitPointNode4')}</ListItem>
            </List>
            <Header id="exitPointNode5">{t('hlp:helpPages.exitPointNode.exitPointNode5')}</Header>
            <PageVideo video={video1} />
            <Header id="exitPointNode6">{t('hlp:helpPages.exitPointNode.exitPointNode6')}</Header>
            <Header2 id="exitPointNode7">{t('hlp:helpPages.exitPointNode.exitPointNode7')}</Header2>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.exitPointNode.exitPointNode8">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.exitPointNode.exitPointNode9')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.exitPointNode.exitPointNode10')}</Paragraph>
            <PageVideo video={video3} />
        </Body>
    );
};

ExitPointNode.propTypes = {};

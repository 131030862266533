const chatDistributionItems = [
    {
        id: 'chatDistribution1',
        title: 'helpPages.chatDistribution.chatDistribution1'
    },
    {
        id: 'chatDistribution3',
        title: 'helpPages.chatDistribution.chatDistribution3'
    },
    {
        id: 'chatDistribution7',
        title: 'helpPages.chatDistribution.chatDistribution7'
    },
    {
        id: 'chatDistribution12',
        title: 'helpPages.chatDistribution.chatDistribution12'
    },
    {
        id: 'chatDistribution16',
        title: 'helpPages.chatDistribution.chatDistribution16'
    },
    {
        id: 'chatDistribution20',
        title: 'helpPages.chatDistribution.chatDistribution20'
    },
    {
        id: 'chatDistribution25',
        title: 'helpPages.chatDistribution.chatDistribution25'
    },
    {
        id: 'chatDistribution29',
        title: 'helpPages.chatDistribution.chatDistribution29'
    },
];

export default chatDistributionItems;

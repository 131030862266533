const operatorWorkItems = [
    {
        id: 'operatorWork1',
        title: 'helpPages.operatorWork.operatorWork1'
    },
    {
        id: 'operatorWork3',
        title: 'helpPages.operatorWork.operatorWork3'
    }
];

export default operatorWorkItems;

const recognitionNodeItems = [
    {
        id: 'recognitionNode1',
        title: 'helpPages.recognitionNode.recognitionNode1'
    },
    {
        id: 'recognitionNode5',
        title: 'helpPages.recognitionNode.recognitionNode5'
    },
    {
        id: 'recognitionNode6',
        title: 'helpPages.recognitionNode.recognitionNode6'
    }
];

export default recognitionNodeItems;

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video19 from './assets/video19.mov';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const TopicRecognition = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:topicRecognition');

    return (
        <Body>
            <Title>{t('hlp:topicRecognition')}</Title>
            <Header id="topicRecognition1">{t('hlp:helpPages.topicRecognition.topicRecognition1')}</Header>
            <Paragraph>{t('hlp:helpPages.topicRecognition.topicRecognition2')}</Paragraph>
            <PageVideo video={video19} />
            <Header id="topicRecognition3">{t('hlp:helpPages.topicRecognition.topicRecognition3')}</Header>
            <Paragraph>{t('hlp:helpPages.topicRecognition.topicRecognition4')}</Paragraph>
            <PageVideo video={video20} />
            <Paragraph>{t('hlp:helpPages.topicRecognition.topicRecognition5')}</Paragraph>
            <Header id="topicRecognition6">{t('hlp:helpPages.topicRecognition.topicRecognition6')}</Header>
            <Paragraph>{t('hlp:helpPages.topicRecognition.topicRecognition7')}</Paragraph>
            <PageVideo video={video21} />
            <Header id="topicRecognition8">{t('hlp:helpPages.topicRecognition.topicRecognition8')}</Header>
            <Paragraph>{t('hlp:helpPages.topicRecognition.topicRecognition9')}</Paragraph>
        </Body>
    );
};

TopicRecognition.propTypes = {};

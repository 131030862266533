const howToUseButtonTextsByButtonsArrayItems = [
    {
        id: 'howToUseButtonTextsByButtonsArray1',
        title: 'helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray1'
    },
    {
        id: 'howToUseButtonTextsByButtonsArray5',
        title: 'helpPages.howToUseButtonTextsByButtonsArray.howToUseButtonTextsByButtonsArray5'
    }
];

export default howToUseButtonTextsByButtonsArrayItems;

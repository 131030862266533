const howToCreateVariablesItems = [
    {
        id: 'howToCreateVariables1',
        title: 'helpPages.howToCreateVariables.howToCreateVariables1'
    },
    {
        id: 'howToCreateVariables4',
        title: 'helpPages.howToCreateVariables.howToCreateVariables4'
    },
    {
        id: 'howToCreateVariables11',
        title: 'helpPages.howToCreateVariables.howToCreateVariables11'
    }
];

export default howToCreateVariablesItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { PageTip } from '../../shared/tip';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToPreventTextEntry = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToPreventTextEntry');

    return (
        <Body>
            <Title>{t('hlp:howToPreventTextEntry')}</Title>
            <Header id="howToPreventTextEntry1">
                {t('hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry1')}
            </Header>
            <List>
                <ListItem>{t('hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry2')}</ListItem>
            </List>
            <PageTip view="info">
                <div>
                    <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry5">
                        0
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                        2
                    </Trans>
                </div>
            </PageTip>
            <Header id="howToPreventTextEntry3">
                {t('hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry3')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry6">
                    0
                    <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry7">
                    0
                    <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                        1
                    </Link>
                    2<span className={css('bold')}>3</span>4<span className={css('bold')}>5</span>6
                    <span className={css('bold')}>7</span>8
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry8">
                    0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry9">
                    0
                    <Link to={routes[ROUTE.EXIT_POINT_NODE]} className={css('link')}>
                        1
                    </Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToPreventTextEntry.howToPreventTextEntry10">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
        </Body>
    );
};

HowToPreventTextEntry.propTypes = {};

const randomizerNodeItems = [
    {
        id: 'randomizerNode1',
        title: 'helpPages.randomizerNode.randomizerNode1'
    },
    {
        id: 'randomizerNode6',
        title: 'helpPages.randomizerNode.randomizerNode6'
    },
    {
        id: 'randomizerNode7',
        title: 'helpPages.randomizerNode.randomizerNode7'
    },
    {
        id: 'randomizerNode12',
        title: 'helpPages.randomizerNode.randomizerNode12'
    }
];

export default randomizerNodeItems;

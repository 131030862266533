const jsNodeItems = [
    {
        id: 'jsNode1',
        title: 'helpPages.jsNode.jsNode1'
    },
    {
        id: 'jsNode3',
        title: 'helpPages.jsNode.jsNode3'
    },
    {
        id: 'jsNode4',
        title: 'helpPages.jsNode.jsNode4'
    }
];

export default jsNodeItems;

const howToMakeStubsForNonWorkingHoursItems = [
    {
        id: 'howToMakeStubsForNonWorkingHours1',
        title: 'helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours1'
    },
    {
        id: 'howToMakeStubsForNonWorkingHours3',
        title: 'helpPages.howToMakeStubsForNonWorkingHours.howToMakeStubsForNonWorkingHours3'
    }
];

export default howToMakeStubsForNonWorkingHoursItems;

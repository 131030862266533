import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToCreateVariables = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToCreateVariables');

    return (
        <Body>
            <Title>{t('hlp:howToCreateVariables')}</Title>
            <Header id="howToCreateVariables1">
                {t('hlp:helpPages.howToCreateVariables.howToCreateVariables1')}
            </Header>
            <List>
                <ListItem>{t('hlp:helpPages.howToCreateVariables.howToCreateVariables2')}</ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToCreateVariables.howToCreateVariables3">
                        0<span className={css('bold')}>1</span>2<span className={css('bold')}>3</span>4
                        <span className={css('bold')}>5</span>6
                    </Trans>
                </ListItem>
            </List>
            <Header id="howToCreateVariables4">
                {t('hlp:helpPages.howToCreateVariables.howToCreateVariables4')}
            </Header>
            <Paragraph>{t('hlp:helpPages.howToCreateVariables.howToCreateVariables5')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.howToCreateVariables.howToCreateVariables6')}</Paragraph>
            <PageVideo video={video2} />
            <Paragraph>{t('hlp:helpPages.howToCreateVariables.howToCreateVariables7')}</Paragraph>
            <List>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToCreateVariables.howToCreateVariables8">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToCreateVariables.howToCreateVariables9">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.howToCreateVariables.howToCreateVariables10">
                        <span className={css('bold')}>0</span>1
                    </Trans>
                </ListItem>
            </List>
            <Header id="howToCreateVariables11">
                {t('hlp:helpPages.howToCreateVariables.howToCreateVariables11')}
            </Header>
            <Paragraph>{t('hlp:helpPages.howToCreateVariables.howToCreateVariables12')}</Paragraph>
            <PageVideo video={video3} />
            <PageVideo video={video4} />
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.howToCreateVariables.howToCreateVariables13">
                    0
                    <Link to={routes[ROUTE.HOW_TO_USE_VARIABLES]} className={css('link')}>
                        1
                    </Link>
                    2
                </Trans>
            </Paragraph>
        </Body>
    );
};

HowToCreateVariables.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HowToUseMultiLanguagesInBot = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:howToUseMultiLanguagesInBot');

    return (
        <Body>
            <Title>{t('hlp:howToUseMultiLanguagesInBot')}</Title>
            <Header id="howToUseMultiLanguagesInBot1">
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot1')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot2')}
            </Paragraph>
            <Header id="howToUseMultiLanguagesInBot3">
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot3')}
            </Header>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot4')}
            </Paragraph>
            <List orderedList>
                <ListItem>
                    {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot5')}
                </ListItem>
                <ListItem>
                    {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot6')}
                </ListItem>
            </List>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot7')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot8')}
            </Paragraph>
            <List orderedList>
                <ListItem id="howToUseMultiLanguagesInBot9">
                    <Trans i18nKey="hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot9">
                        0
                        <Link to={routes[ROUTE.ENTER_POINT_NODE]} className={css('link')}>
                            1
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem id="howToUseMultiLanguagesInBot10">
                    <Trans i18nKey="hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot10">
                        0
                        <Link to={routes[ROUTE.MESSAGE_NODE]} className={css('link')}>
                            1
                        </Link>
                    </Trans>
                </ListItem>
                <PageVideo video={video1} />
                <ListItem id="howToUseMultiLanguagesInBot11">
                    <Trans i18nKey="hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot11">
                        0
                        <Link to={routes[ROUTE.CHAT_MANAGEMENT_NODE]} className={css('link')}>
                            1
                        </Link>
                    </Trans>
                </ListItem>
                <PageVideo video={video2} />
                <ListItem>
                    {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot12')}
                </ListItem>
                <PageVideo video={video3} />
                <ListItem>
                    {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot13')}
                </ListItem>
                <PageVideo video={video4} />
                <ListItem>
                    {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot14')}
                </ListItem>
                <PageVideo video={video5} />
            </List>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot15')}
            </Paragraph>
            <Paragraph>
                {t('hlp:helpPages.howToUseMultiLanguagesInBot.howToUseMultiLanguagesInBot16')}
            </Paragraph>
            <PageVideo video={video6} />
        </Body>
    );
};

HowToUseMultiLanguagesInBot.propTypes = {};

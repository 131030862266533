import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import video4 from './assets/video4.mov';
import video5 from './assets/video5.mov';
import video6 from './assets/video6.mov';
import video7 from './assets/video7.mov';
import video8 from './assets/video8.mov';
import video9 from './assets/video9.mov';
import video10 from './assets/video10.mov';
import video11 from './assets/video11.mov';
import video12 from './assets/video12.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AccountOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:accountOverview')

    return (
        <Body>
            <Title>{t('hlp:accountOverview')}</Title>
            <Header id="accountOverview1">{t('hlp:helpPages.accountOverview.accountOverview1')}</Header>
            <Paragraph id="accountOverview2">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview2">
                    0<a href="https://flomni.com/" target="_blank" rel="noreferrer" className={css('link')}>1</a>
                </Trans>
            </Paragraph>
            <Header id="accountOverview3">{t('hlp:helpPages.accountOverview.accountOverview3')}</Header>
            <Paragraph>{t('hlp:helpPages.accountOverview.accountOverview4')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.accountOverview.accountOverview5')}</Paragraph>
            <PageVideo video={video10} />
            <Paragraph>{t('hlp:helpPages.accountOverview.accountOverview6')}</Paragraph>
            <PageVideo video={video11} />
            <Paragraph>{t('hlp:helpPages.accountOverview.accountOverview7')}</Paragraph>
            <PageVideo video={video12} />
            <Header id="accountOverview8">{t('hlp:helpPages.accountOverview.accountOverview8')}</Header>
            <Paragraph>{t('hlp:helpPages.accountOverview.accountOverview9')}</Paragraph>
            <Paragraph id="accountOverview10">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview10">
                    0<Link to={routes[ROUTE.DASHBOARD]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video1} />
            <Paragraph id="accountOverview11">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview11">
                    0<Link to={routes[ROUTE.MESSAGE_TRANSPORT_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video2} />
            <Paragraph id="accountOverview12">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview12">
                    0<Link to={routes[ROUTE.CHATBOT_BUILDER_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video3} />
            <Paragraph id="accountOverview13">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview13">
                    0<Link to={routes[ROUTE.MEGABRAIN_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video4} />
            <Paragraph id="accountOverview14">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview14">
                    0<Link to={routes[ROUTE.DIALOGS_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video5} />
            <Paragraph id="accountOverview15">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview15">
                    0<Link to={routes[ROUTE.ANALYTICS_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video6} />
            <Paragraph id="accountOverview16">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview16">
                    0<Link to={routes[ROUTE.SEARCH_SERVICE_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video7} />
            <Paragraph id="accountOverview17">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview17">
                    0<Link to={routes[ROUTE.AVAILABLE_CHANNELS_LIST]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video8} />
            <Paragraph id="accountOverview18">
                <Trans i18nKey="hlp:helpPages.accountOverview.accountOverview18">
                    0<Link to={routes[ROUTE.HELP_OVERVIEW]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video9} />
        </Body>
    );
};

AccountOverview.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video32 from './assets/video32.mov';
import video33 from './assets/video33.mov';
import video34 from './assets/video34.mov';
import video35 from './assets/video35.mov';
import video36 from './assets/video36.mov';
import video37 from './assets/video37.mov';
import video38 from './assets/video38.mov';
import video39 from './assets/video39.mov';
import video40 from './assets/video40.mov';
import video41 from './assets/video41.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const ChatHints = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:chatHints');

    return (
        <Body>
            <Title>{t('hlp:chatHints')}</Title>
            <Header id="chatHints1">{t('hlp:helpPages.chatHints.chatHints1')}</Header>
            <Paragraph id="chatHints2">
                <Trans i18nKey="hlp:helpPages.chatHints.chatHints2">
                    0<Link to={routes[ROUTE.MEGABRAIN]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints3')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatHints.chatHints4')}</ListItem>
                <PageVideo video={video32} />
                <ListItem>{t('hlp:helpPages.chatHints.chatHints5')}</ListItem>
                <PageVideo video={video33} />
                <ListItem>{t('hlp:helpPages.chatHints.chatHints6')}</ListItem>
                <PageVideo video={video34} />
            </List>
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints7')}</Paragraph>
            <PageVideo video={video38} />
            <Header id="chatHints8">{t('hlp:helpPages.chatHints.chatHints8')}</Header>
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints9')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints10')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatHints.chatHints11')}</ListItem>

                <ListItem>{t('hlp:helpPages.chatHints.chatHints12')}</ListItem>

                <ListItem>{t('hlp:helpPages.chatHints.chatHints13')}</ListItem>
                <ListItem>{t('hlp:helpPages.chatHints.chatHints14')}</ListItem>
            </List>
            <PageVideo video={video35} />
            <Paragraph id="chatHints15">
                <Trans i18nKey="hlp:helpPages.chatHints.chatHints15">
                    0<Link to={routes[ROUTE.MESSAGES_DRAFTS]} className={css('link')}></Link>
                </Trans>
            </Paragraph>
            <PageVideo video={video36} />
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints16')}</Paragraph>
            <PageVideo video={video37} />
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints17')}</Paragraph>
            <PageVideo video={video39} />
            <Header id="chatHints18">{t('hlp:helpPages.chatHints.chatHints18')}</Header>
            <Paragraph>{t('hlp:helpPages.chatHints.chatHints19')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.chatHints.chatHints20')}</ListItem>
                <PageVideo video={video40} />
                <ListItem>{t('hlp:helpPages.chatHints.chatHints21')}</ListItem>
                <PageVideo video={video41} />
            </List>
        </Body>
    );
};

ChatHints.propTypes = {};

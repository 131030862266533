import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video1 from './assets/video1.mov';
import video2 from './assets/video2.mov';
import video3 from './assets/video3.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const HelpOverview = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:helpOverview');

    return (
        <Body>
            <Title>{t('hlp:helpOverview')}</Title>
            <Header id="helpOverview1">{t('hlp:helpPages.helpOverview.helpOverview1')}</Header>
            <Paragraph>{t('hlp:helpPages.helpOverview.helpOverview2')}</Paragraph>
            <Paragraph>{t('hlp:helpPages.helpOverview.helpOverview3')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview4">
                        <Link to={routes[ROUTE.CHATBOT_BUILDER]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview5">
                        <Link to={routes[ROUTE.MEGABRAIN]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview6">
                        <Link to={routes[ROUTE.MESSAGE_TRANSPORT]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview7">
                        <Link to={routes[ROUTE.DIALOGS]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview8">
                        <Link to={routes[ROUTE.ANALYTICS]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview9">
                        <Link to={routes[ROUTE.ACCOUNT]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.helpOverview.helpOverview10">
                        <Link to={routes[ROUTE.SEARCH_SERVICE]} className={css('link')}>
                            0
                        </Link>
                    </Trans>
                </ListItem>
            </List>
            <PageVideo video={video1} />
            <Header id="helpOverview11">{t('hlp:helpPages.helpOverview.helpOverview11')}</Header>
            <Paragraph>{t('hlp:helpPages.helpOverview.helpOverview12')}</Paragraph>
            <PageVideo video={video2} />
            <Header id="helpOverview13">{t('hlp:helpPages.helpOverview.helpOverview13')}</Header>
            <Paragraph>{t('hlp:helpPages.helpOverview.helpOverview14')}</Paragraph>
            <PageVideo video={video3} />
        </Body>
    );
};

HelpOverview.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { Link } from 'react-router-dom';
import { ROUTE, routes } from '../../../../configs/routes';
import video21 from './assets/video21.mov';
import video22 from './assets/video22.mov';
import video23 from './assets/video23.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const MyReports = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:myReports');

    return (
        <Body>
            <Title>{t('hlp:myReports')}</Title>
            <Header id="myReports1">{t('hlp:helpPages.myReports.myReports1')}</Header>
            <Paragraph id="myReports2">
                <Trans i18nKey="hlp:helpPages.myReports.myReports2">
                    0<span className={css('bold')}>1</span>2
                </Trans>
            </Paragraph>
            <PageVideo video={video21} />
            <Header id="myReports3">{t('hlp:helpPages.myReports.myReports3')}</Header>
            <Paragraph id="myReports4">
                <Trans i18nKey="hlp:helpPages.myReports.myReports4">
                    0<Link to={routes[ROUTE.MEGABRAIN]} className={css('link')} />
                </Trans>
            </Paragraph>
            <PageVideo video={video22} />
            <Paragraph id="myReports2">
                <Trans i18nKey="hlp:helpPages.myReports.myReports2">
                    0<span className={css('bold')}>1</span>
                </Trans>
            </Paragraph>
            <PageVideo video={video23} />
        </Body>
    );
};

MyReports.propTypes = {};

import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video20 from './assets/video20.mov';
import video21 from './assets/video21.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const CornerLinker = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:cornerLinker');

    return (
        <Body>
            <Title>{t('hlp:cornerLinker')}</Title>
            <Header id="cornerLinker1">{t('hlp:helpPages.cornerLinker.cornerLinker1')}</Header>
            <Paragraph>{t('hlp:helpPages.cornerLinker.cornerLinker2')}</Paragraph>
            <PageVideo video={video20} />
            <Header id="cornerLinker3">{t('hlp:helpPages.cornerLinker.cornerLinker3')}</Header>
            <Paragraph>{t('hlp:helpPages.cornerLinker.cornerLinker4')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.cornerLinker.cornerLinker5')}</ListItem>
                <ListItem>{t('hlp:helpPages.cornerLinker.cornerLinker6')}</ListItem>
                <ListItem>{t('hlp:helpPages.cornerLinker.cornerLinker7')}</ListItem>
                <ListItem>{t('hlp:helpPages.cornerLinker.cornerLinker8')}</ListItem>
                <ListItem>{t('hlp:helpPages.cornerLinker.cornerLinker9')}</ListItem>
            </List>
            <PageVideo video={video21} />
        </Body>
    );
};

CornerLinker.propTypes = {};

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import { PageVideo } from '../../shared/video';
import video1 from './assets/video1.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const AvailableChannelsList = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:availableChannelsList')

    return (
        <Body>
            <Title>{t('hlp:availableChannelsList')}</Title>
            <Header id="availableChannelsList1">
                {t('hlp:helpPages.availableChannelsList.availableChannelsList1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.availableChannelsList.availableChannelsList2')}</Paragraph>
            <Header id="availableChannelsList3">
                {t('hlp:helpPages.availableChannelsList.availableChannelsList3')}
            </Header>
            <Paragraph>{t('hlp:helpPages.availableChannelsList.availableChannelsList4')}</Paragraph>
            <PageVideo video={video1} />
            <Paragraph>{t('hlp:helpPages.availableChannelsList.availableChannelsList5')}</Paragraph>
            <List orderedList>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList6">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList7">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList8">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList9">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList10">
                        <span className={css('bold')}>0</span>1
                        <a
                            href="https://partners.viber.com/"
                            target="_blank"
                            rel="noreferrer"
                            className={css('link')}
                        >
                            2
                        </a>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList11">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList12">
                        <span className={css('bold')}>0</span>
                    </Trans>
                    <List orderedList>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList13')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList14')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList15')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList16')}
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList17">
                        <span className={css('bold')}>0</span>
                    </Trans>
                    <Paragraph>{t('hlp:helpPages.availableChannelsList.availableChannelsList18')}</Paragraph>
                    <List orderedList>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList19')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList20')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList21')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList22')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList23')}
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList24">
                        <span className={css('bold')}>0</span>
                    </Trans>
                    <List orderedList>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList25')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList26')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList27')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList28')}
                        </ListItem>
                        <ListItem>
                            {t('hlp:helpPages.availableChannelsList.availableChannelsList29')}
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList30">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
                <ListItem>
                    <Trans i18nKey="hlp:helpPages.availableChannelsList.availableChannelsList31">
                        <span className={css('bold')}>0</span>
                    </Trans>
                </ListItem>
            </List>
        </Body>
    );
};

AvailableChannelsList.propTypes = {};

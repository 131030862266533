const chatActionsItems = [
    {
        id: 'chatActions1',
        title: 'helpPages.chatActions.chatActions1'
    },
    {
        id: 'chatActions9',
        title: 'helpPages.chatActions.chatActions9'
    },
    {
        id: 'chatActions12',
        title: 'helpPages.chatActions.chatActions12'
    },
    {
        id: 'chatActions14',
        title: 'helpPages.chatActions.chatActions14'
    },
    {
        id: 'chatActions22',
        title: 'helpPages.chatActions.chatActions22'
    }
];

export default chatActionsItems;

const howToConnectAgentToChatAndDisableChatbotItems = [
    {
        id: 'howToConnectAgentToChatAndDisableChatbot1',
        title: 'helpPages.howToConnectAgentToChatAndDisableChatbot.howToConnectAgentToChatAndDisableChatbot1'
    },
    {
        id: 'howToConnectAgentToChatAndDisableChatbot3',
        title: 'helpPages.howToConnectAgentToChatAndDisableChatbot.howToConnectAgentToChatAndDisableChatbot3'
    }
];

export default howToConnectAgentToChatAndDisableChatbotItems;

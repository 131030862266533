import { useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import { ListItem } from '../../shared/list-item';
import { List } from '../../shared/list';
import video4 from './assets/video4.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const GeneralStatistics = () => {
    const { t } = useTranslation();
    usePageAccess('hlp:generalStatistics');

    return (
        <Body>
            <Title>{t('hlp:generalStatistics')}</Title>
            <Header id="generalStatistics1">{t('hlp:helpPages.generalStatistics.generalStatistics1')}</Header>
            <Paragraph>{t('hlp:helpPages.generalStatistics.generalStatistics2')}</Paragraph>
            <List orderedList>
                <ListItem>{t('hlp:helpPages.generalStatistics.generalStatistics3')}</ListItem>
                <ListItem>{t('hlp:helpPages.generalStatistics.generalStatistics4')}</ListItem>
                <ListItem>{t('hlp:helpPages.generalStatistics.generalStatistics5')}</ListItem>
            </List>
            <Paragraph>{t('hlp:helpPages.generalStatistics.generalStatistics6')}</Paragraph>
            <PageVideo video={video4} />
        </Body>
    );
};

GeneralStatistics.propTypes = {};

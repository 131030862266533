import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../shared/title';
import { Paragraph } from '../../shared/paragraph';
import { Header } from '../../shared/header';
import { Body } from '../../shared/body';
import { PageVideo } from '../../shared/video';
import video39 from './assets/video39.mov';
import video40 from './assets/video40.mov';
import usePageAccess from '../../../../hooks/usePageAccess';

export const OtherReportsOptions = () => {
    const css = useStyles(styles);
    const { t } = useTranslation();
    usePageAccess('hlp:otherReportsOptions');

    return (
        <Body>
            <Title>{t('hlp:otherReportsOptions')}</Title>
            <Header id="otherReportsOptions1">
                {t('hlp:helpPages.otherReportsOptions.otherReportsOptions1')}
            </Header>
            <Paragraph>{t('hlp:helpPages.otherReportsOptions.otherReportsOptions2')}</Paragraph>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.otherReportsOptions.otherReportsOptions3">
                    0<div className={css(['image', 'load'])}>1</div>
                </Trans>
            </Paragraph>
            <PageVideo video={video39} />
            <Header id="otherReportsOptions4">
                {t('hlp:helpPages.otherReportsOptions.otherReportsOptions4')}
            </Header>
            <Paragraph>
                <Trans i18nKey="hlp:helpPages.otherReportsOptions.otherReportsOptions5">
                    0<div className={css(['image', 'printer'])}>1</div>
                </Trans>
            </Paragraph>
            <PageVideo video={video40} />
        </Body>
    );
};

OtherReportsOptions.propTypes = {};

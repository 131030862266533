const chatEditClientInfoItems = [
    {
        id: 'chatEditClientInfo1',
        title: 'helpPages.chatEditClientInfo.chatEditClientInfo1'
    },
    {
        id: 'chatEditClientInfo4',
        title: 'helpPages.chatEditClientInfo.chatEditClientInfo4'
    },
    {
        id: 'chatEditClientInfo6',
        title: 'helpPages.chatEditClientInfo.chatEditClientInfo6'
    },
    {
        id: 'chatEditClientInfo17',
        title: 'helpPages.chatEditClientInfo.chatEditClientInfo17'
    },
];

export default chatEditClientInfoItems;

import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HeaderSearch } from '../search';
import { ROUTE, routes } from '../../../configs/routes';
import { useHistory } from 'react-router';
import { Menu } from '../../menu';
import { IconButton, SvgBtClose } from '@flomni/components';

export const MobileHeader = () => {
    const css = useStyles(styles);
    const history = useHistory();
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isTopicsPage, setIsTopicsPage] = useState(false);

    useEffect(() => {
        setIsTopicsPage(history.location.pathname.indexOf(routes[ROUTE.TOPICS]) > -1);
        const listener = history.listen((route) => {
            setIsTopicsPage(route.pathname.indexOf(routes[ROUTE.TOPICS]) > -1);
            setShowMobileSearch(false);
            setShowMobileMenu(false);
        });
        return () => {
            listener();
        };
    }, [history]);

    return (
        <div className={css('header')}>
            {!showMobileSearch && (
                <>
                    <Link to="/">
                        <div className={css('logo')} />
                    </Link>
                    {isTopicsPage && (
                        <div className={css('buttons')}>
                            <div className={css('search-btn')} onClick={() => setShowMobileSearch(true)} />
                            <div className={css('menu-btn')} onClick={() => setShowMobileMenu(true)} />
                        </div>
                    )}
                </>
            )}
            {showMobileSearch && <HeaderSearch onClose={() => setShowMobileSearch(false)} />}
            {showMobileMenu && (
                <div className={css('menu')}>
                    <div className={css('menu-header')}>
                        <IconButton onClick={() => setShowMobileMenu(false)}>
                            <SvgBtClose />
                        </IconButton>
                    </div>
                    <Menu mainClass={css('menu-container')} />
                </div>
            )}
        </div>
    );
};

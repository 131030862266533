const chatbotBuilderOverviewItems = [
    {
        id: 'chatbotBuilderOverview2',
        title: 'helpPages.chatbotBuilderOverview.chatbotBuilderOverview2'
    },
    {
        id: 'chatbotBuilderOverview16',
        title: 'helpPages.chatbotBuilderOverview.chatbotBuilderOverview16'
    },
    {
        id: 'chatbotBuilderOverview20',
        title: 'helpPages.chatbotBuilderOverview.chatbotBuilderOverview20'
    },
    {
        id: 'chatbotBuilderOverview58',
        title: 'helpPages.chatbotBuilderOverview.chatbotBuilderOverview58'
    },
    {
        id: 'chatbotBuilderOverview73',
        title: 'helpPages.chatbotBuilderOverview.chatbotBuilderOverview73'
    }
];

export default chatbotBuilderOverviewItems;

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { SvgBtClose } from '@flomni/components';
import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './videoPopup.module.scss';

export const VideoPopup = ({ open, closePopup, children }) => {
    const element = useRef(null);
    const css = useStyles(styles);

    const onOutsideClickHandler = (event) => {
        if (element.current !== event.target) return;
        closePopup();
    };

    if (!open) return null;

    return ReactDOM.createPortal(
        <div className={css('root')} ref={element} onClick={onOutsideClickHandler}>
            <div className={css('container')}>
                {children}
                <div className={css('closeButton')} onClick={closePopup}>
                    <div className={css('iconContainer')}>
                        <SvgBtClose color="black" />
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

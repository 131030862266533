import useStyles from '@flomni/modules/dist/helpers/useStyles';
import styles from './index.module.scss';
import { node, string } from 'prop-types';

export const Header2 = ({ id, children }) => {
    const css = useStyles(styles);

    return (
        <div id={id} className={css('header')}>
            {children}
        </div>
    );
};

Header2.propTypes = {
    children: node.isRequired,
    id: string.isRequired
};

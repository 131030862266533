const notificationsItems = [
    {
        id: 'notifications1',
        title: 'helpPages.notifications.notifications1'
    },
    {
        id: 'notifications10',
        title: 'helpPages.notifications.notifications10'
    }
];

export default notificationsItems;
